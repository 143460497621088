import { Get, ValueOf } from 'type-fest';

import type { LocationObserverModal } from 'features/global';
import * as TS from 'types';
import * as M from 'types/serverModels';
import {
  makeEnumeratedNodesDescription,
  makeRouteTree,
  makeInfiniteNodesDescription,
  makeSearchParamsDescription,
  substitutes,
} from 'utils/RouteTree';
import {
  makeHashParamDescription,
  makeSuperiorNodeDescription,
  makeLeafNodeDescription,
} from 'utils/RouteTree/nodes';

export type SharedParams = { profile?: TS.OAuth2Profile };

export type SharedHash = ValueOf<typeof LocationObserverModal.hashes>;

export type LandingPageHash = `#${M.LandingPageSection['uuid']}`;

export type CourseConstructorParams = {
  object?: M.Unit['uuid'];
};

export type ProjectListParams = {
  search?: string;
  languages?: string[];
  subjects?: string[];
  ages?: string[];
  rubrics?: string[];
  formats?: string[];
  sort?: string;
};

export type CourseListParams = {
  search?: string;
  subjects?: string[];
  groups?: string[];
};

export type UserPersonalAccountHash = '#all-notifications';

export type CommunityMembersParams = {
  tab?: 'list'; // FIXME: hiding for MVP: | 'map'
  name?: string;
  subject?: string;
  interest?: string;
  lang?: string;
  role?: 'all' | 'educator' | 'member';
  sort?: string;
};

export type CommunityGroupsParams = {
  tab?: 'list'; // FIXME: hiding for MVP: | 'map'
  name?: string;
  type?: M.SchoolGroupsType;
  sort?: string;
};

export type QuizListParams = {
  sort?: 'name' | 'date';
  sortDirection?: 'asc' | 'desc';
};

export type GroupMembersParams = {
  tab?: 'list' | 'map';
};

export type ProjectInvestigationParams = {
  unit?: M.Unit['uuid'];
};

export type ProjectBlogHash = `#${M.BlogMessage['uuid']}`;

export type CourseTrackParams = {
  object?: M.Unit['uuid'];
};

export type CourseProgressParams = {
  sort?: 'asc-progress' | 'desc-progress' | 'asc-login' | 'desc-login';
};

export type CourseBlogHash = `#${M.BlogMessage['uuid']}`;

export type ProjectBookPassportHash =
  | `#field-${
      | 'kind'
      | 'description'
      | M.ProjectBookField['code']
      | M.ProjectBookField['uuid']}`
  | `#material-${M.ProjectBookUnit['uuid']}`;

export type ProjectBookStagesHash =
  | `#stage-${M.ProjectBookStage['code'] | M.ProjectBookStage['uuid']}`
  | `#unit-${M.ProjectBookUnit['uuid']}`
  | `#material-${M.ProjectBookUnit['uuid']}`;

export type ProjectBookHistoryParams = {
  dates?: { from?: M.Date | null; to?: M.Date | null };
  authors?: Exclude<M.ProjectBookChange['user'], undefined>[];
  sections?: (
    | Exclude<Get<M.ProjectBookChange, 'change.section'>, 'stages' | undefined>
    | `stage-${M.ProjectBookStage['uuid']}`
  )[];
  events?: Exclude<Get<M.ProjectBookChange, 'change.event'>, undefined>[];
};

export type TestimonialsHash = `#${M.Testimonial['uuid']}`;

export type CourseConstructorRouteTree = {
  kind: 'course-constructor-page';
  tree: typeof routeTree.LANG.course.constructor;
};

export type CoursePageRouteTree = {
  kind: 'course';
  tree: typeof routeTree.LANG.course.list.COURSE;
};

export type ProjectPageRouteTree =
  | {
      kind: 'project-list';
      tree: typeof routeTree.LANG.project.list;
    }
  | {
      kind: 'project-book-stage-projects';
      tree: typeof routeTree.LANG['project-book']['list']['PROJECT_BOOK']['stages']['STAGE']['info']['projects'];
    }
  | {
      kind: 'project-book-user-stage-projects';
      tree: typeof routeTree.LANG['project-book']['list']['PROJECT_BOOK']['users']['USER']['stages']['STAGE']['info']['projects'];
    }
  | {
      kind: 'project-book-results-projects';
      tree: typeof routeTree.LANG['project-book']['list']['PROJECT_BOOK']['results']['projects'];
    }
  | {
      kind: 'project-book-user-results-projects';
      tree: typeof routeTree.LANG['project-book']['list']['PROJECT_BOOK']['users']['USER']['results']['projects'];
    }
  | {
      kind: 'project-book-recycle-bin-projects';
      tree: typeof routeTree.LANG['project-book']['list']['PROJECT_BOOK']['recycle-bin']['projects'];
    }
  | {
      kind: 'project-book-user-recycle-bin-projects';
      tree: typeof routeTree.LANG['project-book']['list']['PROJECT_BOOK']['users']['USER']['recycle-bin']['projects'];
    }
  | {
      kind: 'course-track-projects';
      tree: CoursePageRouteTree['tree']['track']['projects'];
    }
  | {
      kind: 'course-progress-projects';
      tree: CoursePageRouteTree['tree']['progress']['projects'];
    }
  | {
      kind: CourseConstructorRouteTree['kind'];
      tree: CourseConstructorRouteTree['tree']['COURSE_UUID']['projects'];
    };

export type ConclusionsRouteTree =
  | {
      kind: ProjectPageRouteTree['kind'];
      tree: ProjectPageRouteTree['tree']['PROJECT']['conclusions'];
    }
  | {
      kind: CoursePageRouteTree['kind'];
      tree: typeof routeTree.LANG.course.list.COURSE.conclusions;
    }
  | {
      kind: CourseConstructorRouteTree['kind'];
      tree: CourseConstructorRouteTree['tree']['COURSE_UUID']['quiz'];
    };

export type QuestionnaireRouteTree = {
  kind: ProjectPageRouteTree['kind'];
  tree:
    | ProjectPageRouteTree['tree']['PROJECT']
    | ProjectPageRouteTree['tree']['PROJECT']['general']
    | ProjectPageRouteTree['tree']['PROJECT']['findings'];
};

export function getProjectPageRootParams(
  projectPageRouteTree:
    | ProjectPageRouteTree
    | ConclusionsRouteTree
    | QuestionnaireRouteTree,
) {
  return {
    PROJECT_BOOK:
      projectPageRouteTree.kind === 'project-book-stage-projects' ||
      projectPageRouteTree.kind === 'project-book-user-stage-projects' ||
      projectPageRouteTree.kind === 'project-book-results-projects' ||
      projectPageRouteTree.kind === 'project-book-user-results-projects' ||
      projectPageRouteTree.kind === 'project-book-recycle-bin-projects' ||
      projectPageRouteTree.kind === 'project-book-user-recycle-bin-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
    USER:
      projectPageRouteTree.kind === 'project-book-user-stage-projects' ||
      projectPageRouteTree.kind === 'project-book-user-results-projects' ||
      projectPageRouteTree.kind === 'project-book-user-recycle-bin-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
    STAGE:
      projectPageRouteTree.kind === 'project-book-stage-projects' ||
      projectPageRouteTree.kind === 'project-book-user-stage-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
    COURSE:
      projectPageRouteTree.kind === 'course-track-projects' ||
      projectPageRouteTree.kind === 'course-progress-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
    COURSE_UUID:
      projectPageRouteTree.kind === 'course-constructor-page'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
  };
}

export function getConclusionsRootParams(
  conclusionsRouteTree: ConclusionsRouteTree,
) {
  switch (conclusionsRouteTree.kind) {
    case 'project-list':
    case 'project-book-stage-projects':
    case 'project-book-user-stage-projects':
    case 'project-book-results-projects':
    case 'project-book-user-results-projects':
    case 'project-book-recycle-bin-projects':
    case 'project-book-user-recycle-bin-projects':
    case 'course-track-projects':
    case 'course-progress-projects': {
      return {
        ...getProjectPageRootParams(conclusionsRouteTree),
        PROJECT: substitutes.fromLocation,
        COURSE_UUID: substitutes.fromPattern,
      };
    }
    case 'course': {
      return {
        COURSE: substitutes.fromLocation,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        PROJECT: substitutes.fromPattern,
        COURSE_UUID: substitutes.fromPattern,
      };
    }
    case 'course-constructor-page': {
      return {
        COURSE_UUID: substitutes.fromLocation,
        COURSE: substitutes.fromPattern,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        PROJECT: substitutes.fromPattern,
      };
    }
  }
}

export function getQuestionnaireRootParams(
  questionnaireTree: QuestionnaireRouteTree,
) {
  switch (questionnaireTree.kind) {
    case 'project-list':
    case 'project-book-stage-projects':
    case 'project-book-user-stage-projects':
    case 'project-book-results-projects':
    case 'project-book-user-results-projects':
    case 'project-book-recycle-bin-projects':
    case 'project-book-user-recycle-bin-projects':
    case 'course-track-projects':
    case 'course-progress-projects':
    case 'course-constructor-page': {
      return {
        ...getProjectPageRootParams(questionnaireTree),
        PROJECT: substitutes.fromLocation,
      };
    }
  }
}

const questionnaireTree = {
  form: makeInfiniteNodesDescription('QUESTIONNAIRE_UUID', {}),
};

const quizTree = makeSuperiorNodeDescription(
  {
    view: makeInfiniteNodesDescription('QUIZ_UUID', {}),
    form: makeInfiniteNodesDescription('QUIZ_UUID', {}),
    constructor: makeInfiniteNodesDescription('QUIZ_UUID', {}),
  },
  makeSearchParamsDescription<QuizListParams>(),
);

const projectPageTree = {
  general: { ...questionnaireTree },
  investigation: makeLeafNodeDescription(
    makeSearchParamsDescription<ProjectInvestigationParams>(),
  ),
  findings: { ...questionnaireTree },
  conclusions: quizTree,
  quiz: quizTree,
  discussion: null,
  blog: makeInfiniteNodesDescription(
    'BLOG_MESSAGE',
    {},
    undefined,
    makeHashParamDescription<ProjectBlogHash>(),
  ),
  participants: null,
  ...questionnaireTree,
};

const projectBookPageTree = {
  passport: makeLeafNodeDescription(
    undefined,
    makeHashParamDescription<ProjectBookPassportHash>(),
  ),
  members: null,
  stages: makeInfiniteNodesDescription('STAGE', {
    info: makeSuperiorNodeDescription(
      {
        projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
      },
      undefined,
      makeHashParamDescription<ProjectBookStagesHash>(),
    ),
  }),
  results: {
    projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
  },
  reflexion: null,
  history: makeLeafNodeDescription(
    makeSearchParamsDescription<ProjectBookHistoryParams>(),
  ),
  'recycle-bin': {
    projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
  },
};

const rawTree = makeEnumeratedNodesDescription(['ru', 'en', 'es'], 'LANG', {
  'landing-page': makeInfiniteNodesDescription(
    'LANDING_PAGE_CODE',
    {},
    undefined,
    undefined,
    undefined,
    makeHashParamDescription<LandingPageHash>(),
  ),
  project: {
    constructor: makeInfiniteNodesDescription('PROJECT_UUID', {}),
    list: makeInfiniteNodesDescription(
      'PROJECT',
      projectPageTree,
      makeSearchParamsDescription<ProjectListParams>(),
    ),
  },
  'project-book': {
    intro: null,
    list: makeInfiniteNodesDescription('PROJECT_BOOK', {
      ...projectBookPageTree,
      users: makeInfiniteNodesDescription('USER', projectBookPageTree),
    }),
  },
  course: {
    constructor: makeInfiniteNodesDescription(
      'COURSE_UUID',
      {
        projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
        quiz: quizTree,
      },
      undefined,
      undefined,
      makeSearchParamsDescription<CourseConstructorParams>(),
    ),
    list: makeInfiniteNodesDescription(
      'COURSE',
      {
        cover: null,
        track: makeSuperiorNodeDescription(
          {
            projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
            quiz: quizTree,
          },
          makeSearchParamsDescription<CourseTrackParams>(),
        ),
        progress: makeSuperiorNodeDescription(
          {
            projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
            quiz: quizTree,
          },
          makeSearchParamsDescription<CourseProgressParams>(),
        ),
        results: null,
        projects: null,
        blog: makeInfiniteNodesDescription(
          'BLOG_MESSAGE',
          {},
          undefined,
          makeHashParamDescription<ProjectBlogHash>(),
        ),
        conclusions: quizTree,
        members: null,
        quiz: quizTree,
      },
      makeSearchParamsDescription<CourseListParams>(),
    ),
  },
  webinar: {
    list: {
      schedular: null,
      history: null,
    },
    page: makeInfiniteNodesDescription('WEBINAR_UUID', {}),
  },
  article: makeInfiniteNodesDescription('CODE', {}),
  partners: null,
  shop: {
    catalog: null,
    tariff: makeInfiniteNodesDescription('UUID', {}),
  },
  group: makeInfiniteNodesDescription('GROUP', {
    about: null,
    discussion: null,
    rating: null,
    monitoring: null,
    settings: null,
  }),
  users: makeInfiniteNodesDescription('USER', {
    profile: null,
    'personal-information': null,
    perks: null,
    projects: null,
    // TODO: return it when the layout and services are ready(after MVP)
    // tests: null,
    groups: null,
    courses: null,
  }),
  'personal-account': {
    'personal-account': makeLeafNodeDescription(
      undefined,
      makeHashParamDescription<UserPersonalAccountHash>(),
    ),
    perks: makeInfiniteNodesDescription('PERK_GROUP', { all: null }),
    // TODO: return it when the layout and services are ready(after MVP)
    // 'my-assignments': null,
    'my-project-books': null,
    'my-projects': null,
    // TODO: return it when the layout and services are ready(after MVP)
    // 'my-tests': null,
    'my-courses': null,
    'my-groups': null,
    package: {
      features: null,
      courses: null,
      history: null,
    },
    settings: null,
  },
  community: {
    search: {
      members: makeLeafNodeDescription(
        makeSearchParamsDescription<CommunityMembersParams>(),
      ),
      groups: makeLeafNodeDescription(
        makeSearchParamsDescription<CommunityGroupsParams>(),
      ),
    },
  },
  info: {
    news: null,
    blog: null,
    support: null,
    feedback: null,
    'about-us': null,
    'user-agreement': null,
  },
  ideas: null,
  testimonials: makeLeafNodeDescription(
    undefined,
    makeHashParamDescription<TestimonialsHash>(),
  ),
  'ui-kit': null,
  'sign-in': null,
  'sign-up': null,
  'password-reset': makeInfiniteNodesDescription('UUID', {}),
  'confirm-email': makeInfiniteNodesDescription('UUID', {}),
  handbook: makeInfiniteNodesDescription('CODE', {
    batches: makeInfiniteNodesDescription('BATCH', {}),
    topic: makeInfiniteNodesDescription('TOPIC', {
      batches: makeInfiniteNodesDescription('BATCH', {}),
    }),
  }),
  quiz: quizTree,
  mesch: {
    'material-launcher': null,
  },
});

export const routeTree = makeRouteTree<
  typeof rawTree,
  SharedParams,
  SharedHash
>(rawTree);

type UserProfilePath = Extract<
  keyof typeof routeTree.LANG.users.USER,
  | 'profile'
  | 'personal-information'
  | 'perks'
  | 'projects'
  | 'groups'
  | 'courses'
>;

export const getUserProfileLink = (
  lang: TS.Language,
  login?: string,
  path: UserProfilePath = 'profile',
) => {
  if (typeof login !== 'string') {
    return;
  }
  switch (process.env.RAZZLE_PROFILE) {
    case 'MESCH': {
      return;
    }
    default: {
      return routeTree.LANG.users.USER[path].getPath({
        routeParams: { LANG: lang, USER: login },
      });
    }
  }
};
