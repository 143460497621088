import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    uuid: M.UUID;
    body: string;
  };
  return_full?: boolean;
};

export const commentSave = makeService<InputData, M.CommentData>(
  'comment_save',
  'post',
);
