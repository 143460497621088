import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type APIOutputData = {
  series: SeriesItem[];
  xAxis: Axis;
  yAxis: Axis;
};

type SeriesItem = {
  data: SeriesItemData[];
  name: string;
  showInLegend: boolean;
  title: string;
  turboThreshold: number;
};

type SeriesItemData = {
  name: string;
  source: string;
  x: number;
  xcat: string;
  y: number;
  ycat: number;
};

type Axis = {
  categories: string[];
};

function convertChartData(
  data: APIOutputData,
): Pick<TS.CommonChartData, 'series' | 'xAxis' | 'yAxis'> {
  const series = data.series.map(series => {
    return {
      ...series,
      data: series.data.map(item => {
        return { name: item.xcat, x: item.x, y: item.y };
      }),
    };
  });

  return {
    series,
    xAxis: data.xAxis,
    yAxis: data.yAxis,
  };
}

type InputData = M.EmulateParamsHolder & {
  project: string;
  chartType: TS.ChartType;
  xVar: M.XAxisData;
  yVar: M.YAxisData;
  lang: string;
  seriesVar?: M.CuttingQuestionAxisData;
  filter: string; // JSON string of Filter type;
};

export const dataChart = makeService<
  InputData,
  APIOutputData,
  Pick<TS.CommonChartData, 'series' | 'xAxis'>
>('data_chart', 'post', true, convertChartData);
