import * as M from 'types/serverModels';

import { makeService } from './utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type QueryInputData = { user?: M.User['login'] };

type InputData = PathInputData & QueryInputData;

type OutputData = {
  recycle_bin?: M.ProjectBookRecycleBinItem[];
};

export const projectBookRecycleBin = makeService<InputData, OutputData>(
  ({ ticket, uuid, user }, convertToSearchParams) =>
    `project_book/${uuid}/recycle_bin/?${convertToSearchParams({
      ticket,
      user,
    })}`,
  'api-v2-get',
);
