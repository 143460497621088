import * as API from 'services/API';
import { makePrimaryUnit } from 'utils/State/PrimaryUnit';

import { State } from './types';

const initialState: State = {
  featureCode: null,
  blockType: 'action',
  blockedText: '',
};

export const payWallStateUnit = makePrimaryUnit(initialState);

export const callStateUnit = API.services.storeFeatureList.makeCallStateUnit();
