import React, { useMemo, useCallback } from 'react';

import { FormElementState, makeFormElementState } from 'utils/FormState';
import { block } from 'utils/classname';

import './style.scss';

export { makeLabel } from './Label';

const b = block('switch');

type Props = {
  className?: string;
  formElementState: FormElementState<boolean>;
  disabled?: boolean;
  Label?: React.FC;
  onChangePredicate?: () => void;
};

function Switch({
  formElementState,
  Label,
  className,
  disabled,
  onChangePredicate,
}: Props) {
  const value = formElementState.units.value.useState();
  const isDisabled = formElementState.units.disabled.useState();

  const handleSwitchClick = useCallback(() => {
    onChangePredicate
      ? onChangePredicate()
      : formElementState.units.value.setState(prev => !prev);
  }, [formElementState, onChangePredicate]);

  const handleKeyDown: React.KeyboardEventHandler = useCallback(event => {
    const element = event.target;

    const shouldClick = !event.repeat && event.code === 'Enter';

    if (element instanceof HTMLElement && shouldClick) {
      event.preventDefault();
      element.click();
    }
  }, []);

  const state = value ? 'on' : 'off';

  return (
    <div
      className={b({ state, disabled: disabled ?? isDisabled }, [className])}
      onClick={disabled ?? isDisabled ? undefined : handleSwitchClick}
      onKeyDown={handleKeyDown}
    >
      <div className={b('control')} tabIndex={0}>
        <div className={b('circle')} />
      </div>
      {Label && <Label />}
    </div>
  );
}

export function makeFieldState(initialState: boolean = false) {
  return makeFormElementState(initialState);
}

export function useFormElementState(initialState: boolean = false) {
  return useMemo(() => makeFieldState(initialState), [initialState]);
}

export const Component = React.memo(Switch) as typeof Switch;
