import { I18n } from 'services';
import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';

import { Fieldset, LangItem, SubjectItem, InterestItem } from '../types';

type Options = {
  user: M.User;
  t: ReturnType<typeof I18n.useGetMultilingTranslation>;
};

export function makeFieldsetState({ user, t }: Options): Fieldset {
  const {
    role,
    avatar,
    login,
    email,
    name,
    badge,
    native_langs_stack,
    langs_stack,
    location,
    subjects_stack,
    interests,
    education,
    about,
  } = user;

  const loginFieldsetElementState = makeFormElementState(login);
  const emailFieldsetElementState = makeFormElementState(email);
  const locationFieldsetElementState = makeFormElementState([
    location.longitude,
    location.latitude,
  ] as M.Location);

  loginFieldsetElementState.units.disabled.setState(true);
  emailFieldsetElementState.units.disabled.setState(true);
  locationFieldsetElementState.units.disabled.setState(true);

  return {
    avatar: makeFormElementState<M.ImageInfo | null>(
      avatar !== undefined
        ? {
            uuid: '',
            original: avatar,
            thumb: [{ code: 'thumb', url: avatar }],
          }
        : null,
    ),
    login: loginFieldsetElementState,
    email: emailFieldsetElementState,
    name: makeFormElementState(name || ''),
    badge: makeFormElementState(badge || ''),
    nativeLangs: makeFormElementState(
      native_langs_stack?.reduce<LangItem[]>((acc, nativeLang) => {
        if (nativeLang === null) {
          return acc;
        }

        return [
          ...acc,
          {
            label: t(nativeLang.val),
            value: nativeLang.code,
          },
        ];
      }, []) || [],
    ),
    langs: makeFormElementState(
      langs_stack?.reduce<LangItem[]>((acc, lang) => {
        if (lang === null) {
          return acc;
        }

        return [
          ...acc,
          {
            label: t(lang.val),
            value: lang.code,
          },
        ];
      }, []) || [],
    ),
    location: locationFieldsetElementState,
    subjects: makeFormElementState<SubjectItem[]>(
      subjects_stack?.map(subject => ({
        label: t(subject.val),
        value: subject.uuid,
      })) || [],
    ),
    interests: makeFormElementState<InterestItem[]>(
      interests?.map(interest => ({ label: interest, value: interest })) || [],
    ),
    education:
      role === USER_ROLE.teacher ? makeFormElementState(education) : undefined,
    about: makeFormElementState(about),
  };
}
