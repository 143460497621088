import React, { useEffect, useState } from 'react';

import { I18n } from 'services';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import './style.scss';

const b = block('field-error');

export type AsyncValidationData = {
  successMessage: I18n.EntryReference;
  pendingMessage: I18n.EntryReference;
  pendingUnit: PrimaryStateUnit<boolean>;
};

type Props = {
  className?: string;
  rows: 1 | 2;
  messageReference: I18n.EntryReference | null;
  asyncValidationData?: AsyncValidationData;
};

type AsyncValidationMessage =
  | SucessValidationMessage
  | PendingValidationMessage;

type SucessValidationMessage = {
  kind: 'success';
  reference: I18n.EntryReference;
};

type PendingValidationMessage = {
  kind: 'pending';
  reference: I18n.EntryReference;
};

function ErrorMessage({
  messageReference,
  className,
  rows,
  asyncValidationData,
}: Props) {
  const message = I18n.useOptionalReference(messageReference);

  const [asyncValidationMessageData, setAsyncValidationMessageData] =
    useState<null | AsyncValidationMessage>(null);

  const asyncValidationMessage = I18n.useOptionalReference(
    asyncValidationMessageData?.reference || null,
  );

  useEffect(() => {
    if (asyncValidationData) {
      return asyncValidationData.pendingUnit.subscribe({
        name: 'async-message-handler',
        callback: pending => {
          if (pending) {
            setAsyncValidationMessageData({
              kind: 'pending',
              reference: asyncValidationData.pendingMessage,
            });
          } else {
            setAsyncValidationMessageData({
              kind: 'success',
              reference: asyncValidationData.successMessage,
            });
          }
        },
      });
    }
  }, [asyncValidationData]);

  const displayedMessage = (() => {
    if (asyncValidationMessageData?.kind === 'pending') {
      return asyncValidationMessage;
    }

    return message || asyncValidationMessage;
  })();

  const messageKind = (() => {
    if (asyncValidationMessageData?.kind === 'pending') {
      return 'pending';
    }

    if (message) {
      return 'error';
    }

    if (asyncValidationMessage) {
      return 'success';
    }
  })();

  return (
    <div className={b({ rows, kind: messageKind }, [className])}>
      {displayedMessage}
    </div>
  );
}

export const Component = React.memo(ErrorMessage) as typeof ErrorMessage;
