import * as TS from 'types';
import * as M from 'types/serverModels';

import { AnswerVariant, Context } from './types';

export function makeServerVariant(
  x: AnswerVariant,
  language: TS.Language,
  context: Context,
): M.Variant {
  const image = x.image.units.value.getState();

  const shared: Pick<M.Variant, 'uuid' | 'title' | 'image'> = {
    uuid: x.id,
    title: x.text.getMergedMultilingString(language),
    image: image
      ? {
          icon: image.thumb[0].url,
          ...(image.uuid ? { uuid: image.uuid } : {}),
        }
      : undefined,
  };

  switch (context) {
    case 'conclusions':
      return { ...shared, score: Number(x.score.units.value.getState()) };
    case 'inputDataForm':
      return {
        ...shared,
        custom: x.isOwn.units.value.getState(),
        description: x.explanation.getMergedMultilingString(language),
      };
  }
}
