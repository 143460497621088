import React from 'react';

import { block } from 'utils/classname';

const b = block('select');

type Props<T> = {
  option: T;
  nowrap?: boolean;
};

// TODO investigate removing
function Content<T>({ option, nowrap }: Props<T>) {
  return (
    <div className={b()}>
      <div className={b('active-option', { nowrap })}>{option}</div>
    </div>
  );
}

export const DefaultComponent = React.memo(Content) as typeof Content;
