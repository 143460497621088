import { makeFeature } from 'services/Feature';

import * as i18nSharedReferences from './i18nSharedReferences';

export const feature = makeFeature({
  name: 'WebinarStatusHint',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "webinar-status-hint" */
      './Component'
    ),
  i18nSharedReferences: Object.values(i18nSharedReferences),
  subfeatures: [],
  prefetched: true,
});

export const Component = feature.Component;
