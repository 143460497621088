import * as M from 'types/serverModels';

export function answerHasImages(
  questionAnswer: M.QuestionnaireData['answers'][string],
): questionAnswer is M.Image | M.Image[] {
  return (
    (questionAnswer as M.Image)?.thumb !== undefined ||
    (Array.isArray(questionAnswer) &&
      (questionAnswer[0] as M.Image)?.thumb !== undefined)
  );
}

export function answerHasText(
  questionAnswer: M.QuestionnaireData['answers'][string],
): questionAnswer is string {
  return typeof questionAnswer === 'string';
}
