import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  ticket?: M.UUID;
  uuid: M.Webinar['uuid'];
};

export const webinarRead = makeService<InputData, M.Webinar>(
  'webinar_read',
  'get',
);
