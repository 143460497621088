import { color } from 'd3-color';
import { interpolateNumber, interpolateRgb } from 'd3-interpolate';
import * as R from 'ramda';

import { Filter } from 'features/widgets/shared';
import { API } from 'services';
import * as M from 'types/serverModels';
import { MappedState } from 'utils/State';
import { Layer } from 'utils/business';

export async function getMapData(
  filter: Filter.FilterStateForRequest,
  layers: MappedState<MappedState<Layer.ForRequest.Layer[]>>,
  emulationParams?: string,
) {
  const result: M.MapData[] = await Promise.all(
    layers.map(async layer => {
      const output = await API.services.dataMap.callPromised({
        question: layer.question.id,
        filter: JSON.stringify(Filter.makeServerFilter(filter)),
        emulate: emulationParams,
      });

      const getCommonData = ({
        date,
        location,
        user,
        uuid,
        group,
      }: M.ServerMapData): Pick<
        M.MapLayerData,
        'uuid' | 'location' | 'date' | 'group' | 'user'
      > => ({ date, location, user, uuid, group });

      const getMapLayerData = ((): ((
        item: M.ServerMapData,
      ) => M.MapLayerData) => {
        switch (layer.kind) {
          case 'scale': {
            const {
              colorRange,
              pointsNumber,
              scaleRange: { from, to },
            } = layer;

            if (from === null || to === null) {
              console.error('unexpected layer data', layer);
              return item => ({
                ...getCommonData(item),
                type: 'quantitative',
                exp: (item as M.ServerMapQuantitativeData).exp,
                shape: layer.markerShape,
                divisions: [],
                value: 0,
                color: '',
              });
            }

            const iColor = interpolateRgb(colorRange.from, colorRange.to);
            const iNumber = interpolateNumber(from, to);

            const divisions = R.range(0, pointsNumber).map(x => {
              const colorPoint = x / (pointsNumber - 1);
              const valuePoint = (x + 1) / pointsNumber;
              return {
                color: color(iColor(colorPoint))?.formatHex() || '#fff',
                maxValue: iNumber(valuePoint),
              };
            });

            return item => {
              const value = (item as M.ServerMapQuantitativeData).value;

              return {
                ...getCommonData(item),
                type: 'quantitative',
                shape: layer.markerShape,
                exp: (item as M.ServerMapQuantitativeData).exp,
                value,
                divisions,
                color:
                  divisions.find(x => value <= x.maxValue)?.color ||
                  divisions[divisions.length - 1].color,
              };
            };
          }

          case 'colored-markers/icons': {
            const variantIDToColor = layer.settings.color.variantsColors.reduce<
              Record<string, string>
            >(
              (acc, { color, variantID }) => ({
                ...acc,
                [variantID]: color,
              }),
              {} as Record<string, string>,
            );

            const variantIDToIcon = layer.settings.icons.variantsIcons.reduce<
              Record<string, string>
            >(
              (acc, { icon, variantID }) => ({
                ...acc,
                [variantID]: icon?.thumb[0].url || '',
              }),
              {} as Record<string, string>,
            );

            const variantIDToName = layer.question.variants.reduce<
              Record<string, string>
            >(
              (acc, { id, text }) => ({
                ...acc,
                [id]: text.formElementState.units.value,
              }),
              {},
            );

            if (layer.settings.mode === 'colored-markers') {
              return item => ({
                ...getCommonData(item),
                type: 'qualitative',
                mode: 'color',
                variantID: (item as M.ServerMapIconData).value,
                name: variantIDToName[(item as M.ServerMapIconData).value],
                shape: layer.settings.color.markerShape,
                value: variantIDToColor[(item as M.ServerMapColorData).value],
              });
            }

            return item => ({
              ...getCommonData(item),
              type: 'qualitative',
              mode: 'icons',
              variantID: (item as M.ServerMapIconData).value,
              name: variantIDToName[(item as M.ServerMapIconData).value],
              value: variantIDToIcon[(item as M.ServerMapIconData).value],
            });
          }
          case 'without-settings':
            const type =
              Layer.questionKindToServerLayerWithoutSettingsType[
                layer.question.kind
              ];
            switch (type) {
              case 'date':
                return item => ({
                  ...getCommonData(item),
                  type,
                  value: (item as M.ServerMapDateData).value,
                });

              case 'draw':
                return item => ({
                  ...getCommonData(item),
                  type,
                  value: (item as M.ServerMapDrawData).value,
                });

              case 'map':
                return item => ({
                  ...getCommonData(item),
                  type,
                  value: (item as M.ServerMapMapData).value,
                });

              case 'photo':
                return item => ({
                  ...getCommonData(item),
                  type,
                  img: (item as M.ServerMapPhotoData).img,
                  value: (item as M.ServerMapPhotoData).thumb,
                });

              case 'video':
                return item => ({
                  ...getCommonData(item),
                  type,
                  value: (item as M.ServerMapVideoData).value,
                });

              case 'sound':
                return item => ({
                  ...getCommonData(item),
                  type,
                  value: (item as M.ServerMapSoundData).value,
                });

              case 'text':
                return item => ({
                  ...getCommonData(item),
                  type,
                  value: (item as M.ServerMapTextData).value,
                });
            }
        }
      })();

      const mapData: M.MapData = {
        questionID: layer.question.id,
        data: output.map(getMapLayerData),
      };

      return mapData;
    }),
  );

  return result;
}
