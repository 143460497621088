import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = M.EmulateParamsHolder & {
  question: string;
  filter: string; // M.Filter;
};

export const dataMap = makeService<InputData, M.ServerMapData[]>(
  'data_map',
  'get',
);
