import { Filter } from 'types/serverModels';

import { InitialState } from './types';

const getDateValue = (sec: number | undefined) =>
  sec === undefined ? undefined : new Date(sec * 1000);

export function makeInitialStateFromServerFilter(filter: Filter): InitialState {
  return {
    submitDateFrom: getDateValue(filter?.date?.from) || null,
    submitDateTo: getDateValue(filter?.date?.to) || null,
    location: filter?.location || null,
  };
}
