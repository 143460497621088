import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'ErrorDetails',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "error-details" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
});

export const Component = feature.Component;
