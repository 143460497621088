import { i18nStageStatusSharedReferences } from '../../../../i18nStageStatusSharedReferences';
import { i18nStatusSharedReferences } from '../../../../i18nStatusSharedReferences';
import { i18nNavigationLinkSharedReferences } from '../../Navigation';
import { i18nPassportFieldsSharedReferences } from '../Passport';
import { i18nStagesStageSectionTitleSharedReferences } from '../Stages';

export const stageStatusReferences = i18nStageStatusSharedReferences;
export const statusReferences = i18nStatusSharedReferences;
export const navigationReferences = i18nNavigationLinkSharedReferences;
export const passportFieldsReferences = i18nPassportFieldsSharedReferences;
export const stageSectionTitleReferences =
  i18nStagesStageSectionTitleSharedReferences;
