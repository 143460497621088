import { Device } from 'services';

import { Desktop, Mobile } from './components';

export { Button, Item, Divider } from './components';

export const Component = (props: React.PropsWithChildren<Mobile.Props>) => {
  switch (Device.unit.useState()) {
    case 'mobile':
      return <Mobile.Component {...props} />;
    default:
      return <Desktop.Component {...props} />;
  }
};

export const makeIsOpenUnit = Mobile.makeIsOpenUnit;
export const useIsOpenUnit = Mobile.useIsOpenUnit;
