import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

function LandingPage() {
  return <features.Page.Component />;
}

export const Component = Page.makePage({
  path: routeTree.LANG['landing-page'].LANDING_PAGE_CODE.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(LandingPage),
  features: Object.values(features).map(x => x.feature),
});
