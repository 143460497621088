import React, { useCallback, useEffect } from 'react';

import { Modal, Link, TextInput, Button } from 'components';
import { routeTree } from 'pages/routeTree';
import { I18n, API, Routing } from 'services';
import { ReactComponent as AngleArrowDownIcon } from 'shared/images/angleArrowDown.svg';
import { useFormElementState } from 'utils/FormState';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';
import { nonEmptyString } from 'utils/validators';

import i18nData from '../i18n.json';
import { requiredFieldReference } from '../i18nSharedReferences';
import './style.scss';

const b = block('shop-license-sharing-form-modal');

export type Props = {
  Header: React.VFC;
  isOpenUnit: PrimaryStateUnit<boolean>;
  onSuccess?(): void;
  onGoBack?(): void;
};

function FormModal({ Header, isOpenUnit, onSuccess, onGoBack }: Props) {
  const text = I18n.useText(i18nData).formModal;

  const lang = I18n.activeLangStateUnit.useState();

  const formElementState = useFormElementState<string>('', [
    nonEmptyString(requiredFieldReference),
  ]);

  const callStateUnit = API.services.storeLicenseAppropriate.useCallStateUnit();

  const callState = callStateUnit.useState();

  const call = API.services.storeLicenseAppropriate.useCall(callStateUnit);

  const handleSubmit: React.FormEventHandler = useCallback(
    event => {
      event.preventDefault();

      const code = formElementState.units.value.getState();

      if (!formElementState.formNode.validate()) {
        return;
      }

      call({ license: code });
    },
    [formElementState, call],
  );

  const handleGoToShopButtonClick = useCallback(() => {
    const history = Routing.getHistory();

    const shopPath = routeTree.LANG.shop.catalog.getPath({
      routeParams: { LANG: lang },
    });

    history?.push(shopPath);
  }, [lang]);

  useEffect(
    () =>
      callStateUnit.subscribe({
        name: 'form-disabler',
        callback: callState => {
          formElementState.formNode.setDisabled(callState.kind === 'pending');
        },
      }),
    [callStateUnit, formElementState],
  );

  useEffect(
    () =>
      callStateUnit.subscribe({
        name: 'emitter',
        callback: callState => {
          switch (callState.kind) {
            case 'successful': {
              isOpenUnit.setState(false);

              onSuccess?.();

              break;
            }
          }
        },
      }),
    [isOpenUnit, callStateUnit, onSuccess],
  );

  useEffect(
    () =>
      isOpenUnit.subscribe({
        name: 'resetter',
        callback: isOpen => {
          if (!isOpen) {
            return;
          }

          formElementState.formNode.reset();

          callStateUnit.resetState();
        },
      }),
    [isOpenUnit, callStateUnit, formElementState],
  );

  const manualError =
    callState.kind === 'error'
      ? I18n.makeEntryReference(i18nData, data => data.formModal.errorText)
      : null;

  return (
    <Modal.Component
      className={b()}
      Header={Header}
      isOpenUnit={isOpenUnit}
      size="m"
    >
      <div className={b('body')}>
        {onGoBack && (
          <Link.Component
            className={b('go-back-link')}
            size="l"
            color="accent-2"
            onClick={onGoBack}
          >
            <AngleArrowDownIcon className={b('go-back-link-icon')} />
            {text.goBackLink}
          </Link.Component>
        )}
        <p className={b('caption')}>{text.caption}</p>
        <form className={b('form')} onSubmit={handleSubmit}>
          <span className={b('input-label')}>{text.inputLabel}</span>
          <div className={b('input-layout')}>
            <TextInput.Component
              formElementState={formElementState}
              manualError={manualError}
            />
            <Button.Component
              type="submit"
              size="m"
              variant="contained"
              color="accent-2"
              callStateUnit={callStateUnit}
            >
              {text.submitButtonValue}
            </Button.Component>
          </div>
        </form>
        {/* <div className={b('go-to-shop')}>
          <p className={b('go-to-shop-caption')}>{text.goToShop.caption}</p>
          <Button.Component
            type="button"
            size="s"
            variant="outlined"
            color="accent-2"
            onClick={handleGoToShopButtonClick}
          >
            {text.goToShop.button.value}
          </Button.Component>
        </div> */}
      </div>
    </Modal.Component>
  );
}

export { useIsOpenUnit } from 'components/Modal';

export const Component = React.memo(FormModal);
