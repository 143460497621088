import React, { useEffect, useMemo } from 'react';

import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';
import { nonEmptyString, videoURLSyntax } from 'utils/validators';

import {
  invalidVideoLink,
  questionIsRequired,
} from '../../i18nSharedReferences';
import { VideoQuestionForm } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import './style.scss';

type Props = {
  data: M.VideoQuestion;
  num: number;
  kind: Kind;
  type: Type;
  initialValue?: M.VideoFromHosting | string;
  score: { max: number; result: number } | null;
};

const b = block('project-view-question-video');

export const stateUnit = makePrimaryUnit<
  Record<string, FormElementState<string>>
>({});

const getVideoSrc = (data: M.VideoFromHosting) => {
  switch (data.host) {
    case 'youtube':
      return `https://www.youtube.com/embed/${data.code}?wmode=opaque`;
    case 'vimeo':
      return `https://player.vimeo.com/video/${data.code}?byline=0&portrait=0&badge=0&color=c2c2c2`;
  }
};

function VideoQuestion({ data, num, type, initialValue = '', kind }: Props) {
  const validators = useMemo(
    () =>
      !data.optional
        ? [nonEmptyString(questionIsRequired), videoURLSyntax(invalidVideoLink)]
        : [videoURLSyntax(invalidVideoLink)],
    [data.optional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    initialValue:
      typeof initialValue === 'string' ? initialValue : initialValue.url,
    validators,
  });

  const value = formElementState.units.value.useState();

  const t = I18n.useGetMultilingProjectTranslation();

  const title = t(data.title);
  const description = t(data.description);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  return (
    <QuestionLayout.Component
      title={title}
      description={description}
      image={data.image?.large}
      type={type}
      num={num}
      variant={value ? 'contained' : 'outlined'}
      isOptional={data.optional}
      isEmpty={kind === 'view' && value.length === 0}
      isAutocomplete={data.visibility === QUESTION_VISIBILITY.disabled}
    >
      {kind === 'view' && typeof initialValue !== 'string' && (
        <div className={b()}>
          <iframe
            className={b('iframe')}
            src={getVideoSrc(initialValue)}
            title="Video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      )}
      {kind === 'form' && (
        <VideoQuestionForm.Component formElementState={formElementState} />
      )}
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(VideoQuestion) as typeof VideoQuestion;
