import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid?: M.UUID;
  login?: M.User['login'];
  cdate?: string;
  project: M.UUID;
  resolveUser?: boolean;
  resolveGroup?: boolean;
};

export const dataRead = makeService<InputData, M.QuestionnaireData>(
  'data_read',
  'get',
);
