import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  ticket: M.UUID;
  language: TS.Language;
};

type OutputData = TS.Language | undefined;

export const languageSwitch = makeService<InputData, OutputData>(
  'language_switch',
  'post',
);
