import { makeFeature } from 'services/Feature';

import type { ComponentType } from './Component';
import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'StreamSelect',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "stream-select" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
});

export type { Props } from './Component';

export * as OptionExtension from './OptionExtension';

export const Component = feature.Component as ComponentType;
