import React, { useEffect, useMemo } from 'react';

import { ErrorMessage } from 'components';
import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { nonFalse } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { VariantSelectionQuestionForm } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import * as View from './View';
import { State, StateItem } from './types';
import { useModel } from './useModel';

type Props = {
  data: M.MultiChoiceQuestion | M.MultiChoiceQuizQuestion;
  num: number;
  kind: Kind;
  type: Type;
  initialValue?: string[];
  initialText?: Record<string, string>;
  score: { max: number; result: number } | null;
};

export const stateUnit = makePrimaryUnit<State>({});

function MultipleChoiceQuestion({
  data,
  num,
  initialValue,
  initialText,
  kind,
  type,
  score,
}: Props) {
  const isOptional = 'optional' in data && data.optional;
  const visibility = 'visibility' in data && data.visibility;

  const initValue = useMemo(
    (): StateItem =>
      (data.variants as M.Variant[]).reduce(
        (acc, x) => ({
          ...acc,
          [x.uuid]: {
            valueState: makeFormElementState(
              !!initialValue?.includes(x.uuid),
              !isOptional ? [nonFalse(questionIsRequired)] : [],
            ),
            textState: makeFormElementState(initialText?.[x.uuid] || ''),
          },
        }),
        {},
      ),
    [data.variants, initialText, initialValue, isOptional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    initialValue: initValue,
  });

  const state = formElementState.units.value.useState();
  const valuesUnit = useMemo(
    () =>
      makeMappingUnitFromUnit(
        makeDerivedUnit(formElementState.units.value).getUnit(x =>
          Object.values(x).map(x => x.valueState.units.value),
        ),
      ),
    [formElementState.units.value],
  );
  const errorsUnit = useMemo(
    () =>
      makeMappingUnitFromUnit(
        makeDerivedUnit(formElementState.units.value).getUnit(x =>
          Object.values(x).map(x => x.valueState.units.error),
        ),
      ),
    [formElementState.units.value],
  );
  const values = valuesUnit.useState();
  const erorrs = errorsUnit.useState();

  const isSelected = values.some(x => x);

  const error = useMemo(() => {
    const isErrors = erorrs.every(x => x);
    if (isErrors) {
      return erorrs.find(x => x !== null) || null;
    }
    return null;
  }, [erorrs]);

  const t = I18n.useGetMultilingProjectTranslation();

  const title = t(data.title);
  const description = t(data.description);
  const answer = t(data.answer);

  const model = useModel(data, state);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  return (
    <QuestionLayout.Component
      title={title}
      description={description}
      image={data.image?.large}
      type={type}
      num={num}
      variant={
        (type === 'quiz' && kind === 'form') || !isSelected
          ? 'outlined'
          : 'contained'
      }
      isOptional={isOptional}
      isEmpty={kind === 'view' && !isSelected}
      isAutocomplete={visibility === QUESTION_VISIBILITY.disabled}
      score={score}
      answer={kind === 'view' ? answer : undefined}
    >
      {kind === 'view' && data.variants && (
        <View.Component
          variant={data.variants.filter(x => initialValue?.includes(x.uuid))}
          text={initialText}
        />
      )}
      {kind === 'form' && (
        <>
          <VariantSelectionQuestionForm.Component model={model} />
          <ErrorMessage.Component rows={1} messageReference={error} />
        </>
      )}
    </QuestionLayout.Component>
  );
}

export const Component = React.memo(
  MultipleChoiceQuestion,
) as typeof MultipleChoiceQuestion;
