import { useEffect, useState } from 'react';

import * as LocalStorageService from '../LocalStorage';

let ticketSubsribers: Array<(ticket: string | null) => void> = [];

export function setTicket(ticket: string) {
  LocalStorageService.setItem('ticket', ticket);
  ticketSubsribers.forEach(f => f(ticket));
}

export function getTicket() {
  return LocalStorageService.getItem('ticket');
}

export function deleteTicket() {
  LocalStorageService.removeItem('ticket');
  ticketSubsribers.forEach(f => f(null));
}

function subscribeToTicketChange(subscriber: (value: string | null) => void) {
  ticketSubsribers.push(subscriber);

  return () => {
    ticketSubsribers = ticketSubsribers.filter(x => x !== subscriber);
  };
}

export function useTicket() {
  const [ticket, setTicket] = useState<string | null>(getTicket());

  useEffect(() => subscribeToTicketChange(setTicket), []);

  return ticket;
}
