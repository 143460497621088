import { Locale } from 'date-fns';
import { ru, enGB, es } from 'date-fns/locale';

import * as TS from 'types';

import { makeEntryReference } from './makeEntryReference';

export const languages = process.env.RAZZLE_LANGS.split(',') as TS.Language[];

export const dateFormatLocales: Record<TS.Language, Locale> = {
  ru,
  en: enGB,
  es,
};

export const datePlaceholders: Record<TS.Language, string> = {
  ru: 'дд.мм.гггг',
  en: 'mm/dd/yyyy',
  es: 'mm/dd/yyyy',
};

export const datetimeFormats: Record<TS.Language, string> = {
  ru: 'dd.MM.yyyy, HH:mm',
  en: 'MM/dd/yyyy, HH:mm',
  es: 'MM/dd/yyyy, HH:mm',
};

export const datetimePlaceholders: Record<TS.Language, string> = {
  ru: 'дд.мм.гггг, чч:мм',
  en: 'mm/dd/yyyy, hh:mm',
  es: 'mm/dd/yyyy, hh:mm',
};

export const memorySizes: Record<
  TS.Language,
  Record<'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB', string>
> = {
  ru: {
    B: 'Б',
    KB: 'Кб',
    MB: 'Мб',
    GB: 'Гб',
    TB: 'Тб',
    PB: 'Пб',
    EB: 'ЭB',
    ZB: 'ЗB',
    YB: 'ЙB',
  },
  en: {
    B: 'B',
    KB: 'KB',
    MB: 'MB',
    GB: 'GB',
    TB: 'TB',
    PB: 'PB',
    EB: 'EB',
    ZB: 'ZB',
    YB: 'YB',
  },
  es: {
    B: 'B',
    KB: 'KB',
    MB: 'MB',
    GB: 'GB',
    TB: 'TB',
    PB: 'PB',
    EB: 'EB',
    ZB: 'ZB',
    YB: 'YB',
  },
};

export const emptyReference = makeEntryReference(
  { en: '', ru: '', es: '' },
  data => data as any,
);
