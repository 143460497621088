import * as M from 'types/serverModels';

import { makeFilterStateUnitForRequest } from './makeFilterStateUnitForRequest';
import { makeServerFilter } from './makeServerFilter';
import { State } from './types';

export function makeServerFilterFromState(state: State): M.Filter | null {
  const stateForRequest = makeFilterStateUnitForRequest(state).getState();
  return makeServerFilter(stateForRequest);
}
