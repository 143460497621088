import { Preloader } from 'components';
import { LoadError } from 'features/global';

import { CallState, SuccessfulState, ErrorState } from './types';

type Renderer<Result> = (result: Result) => React.ReactElement | null;
type EmptyRenderer = () => React.ReactElement | null;

type Renderers<T> = {
  successful: Renderer<SuccessfulState<T>>;
  initial?: EmptyRenderer;
  pending?: EmptyRenderer;
  error?: Renderer<ErrorState>;
};

type Options = {
  className?: string;
};

const defaults = {
  Initial: () => null,
  Pending: ({ className }: { className?: string }) => (
    <Preloader.Component className={className} />
  ),
  Error: ({
    callState,
    className,
  }: {
    callState: ErrorState;
    className?: string;
  }) => (
    <LoadError.Component
      className={className}
      message={[callState.code, callState.message].filter(x => !!x).join(': ')}
    />
  ),
};

export function renderCallState<T>(
  callState: CallState<T>,
  renderers: Renderers<T>,
  { className }: Options = {},
) {
  switch (callState.kind) {
    case 'initial':
      return renderers.initial !== undefined ? (
        renderers.initial()
      ) : (
        <defaults.Initial />
      );
    case 'pending':
      return renderers.pending !== undefined ? (
        renderers.pending()
      ) : (
        <defaults.Pending className={className} />
      );
    case 'successful':
      return renderers.successful(callState);

    case 'error':
      return renderers.error !== undefined ? (
        renderers.error(callState)
      ) : (
        <defaults.Error className={className} callState={callState} />
      );
  }
}
