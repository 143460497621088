import { useMemo } from 'react';

import { makeFeature } from 'services/Feature';
import { makePrimaryUnit } from 'utils/State';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'WidgetLayout',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "widget-layout" */
      './Component'
    ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;

export type { ControlsBarProps } from './types';

export { FullScreenContext } from './FullscreenContext';

export function useSettingsAreVisibleUnit(initialState: boolean) {
  return useMemo(() => makePrimaryUnit<boolean>(initialState), [initialState]);
}
