import React from 'react';

import { Page } from 'services';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as features from './features';
import './style.scss';

const b = block('home-page');

const contentHorizontalPadding = 20;

function Home() {
  return (
    <div className={b()}>
      <div className={b('content')}>
        <features.DigitalEnvironment.Component />
        <features.ResearchProjectsConduction.Component
          contentStretchSizeOnMobile={contentHorizontalPadding}
        />
        {/* NOTE: MVP hide HowToGetStarted: https://dev.globallab.org/redmine/issues/7539 */}
        {/* <features.HowToGetStarted.Component /> */}
        {/* FIXME: hiding for MVP: https://dev.globallab.org/redmine/issues/7505 . Should use 'default' variant*/}
        <features.ProjectsCommunity.Component
          variant="stripped-down"
          footerStretchSizeOnMobile={contentHorizontalPadding}
        />
        <features.Webinars.Component
          listStretchSizeOnMobile={contentHorizontalPadding}
        />
        <features.Testimonials.Component
          carouselStretchSizeOnMobile={contentHorizontalPadding}
        />
      </div>
      <features.JoinUs.Component className={b('footer')} />
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(Home),
  features: Object.values(features).map(x => x.feature),
});
