import React, { useCallback } from 'react';

import { FormElementState } from 'utils/FormState';
import { block, classnames } from 'utils/classname';

import { ControlConfig } from '../types';
import './style.scss';

export * as TextLabel from './TextLabel';

const b = block('radio-button');

type Props<Key extends string, State extends Key | null> = {
  buttonKey: Key;
  controlConfig: ControlConfig;
  className?: string;
  formElementState: FormElementState<State>;
  isDisabled?: boolean;
  onChangePredicate?: (key: Key) => void;
};

function RadioButton<Key extends string, State extends Key | null>({
  controlConfig,
  buttonKey,
  className,
  formElementState,
  isDisabled,
  onChangePredicate,
}: Props<Key, State>) {
  const handleRadioButtonClick = useCallback(() => {
    if (typeof onChangePredicate === 'function') {
      onChangePredicate(buttonKey);
    } else {
      formElementState.units.value.setState(buttonKey as State);
    }
  }, [buttonKey, formElementState.units.value, onChangePredicate]);

  const active = formElementState.units.value.useState() === buttonKey;
  const disabled = formElementState.units.disabled.useState() || isDisabled;

  return (
    <div
      className={classnames(b({ active, disabled }), className)}
      onClick={disabled ? undefined : handleRadioButtonClick}
    >
      <div className={b('circle-and-label')}>
        <div className={b('circle')}>
          <div className={b('inner-circle')} />
        </div>
        {controlConfig.Label && <controlConfig.Label />}
      </div>
      {controlConfig.Extension && (
        <div className={b('extension')}>
          <controlConfig.Extension />
        </div>
      )}
    </div>
  );
}

export const Component = React.memo(RadioButton) as typeof RadioButton;
