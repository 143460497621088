import React from 'react';

import { block } from 'utils/classname';

export type Props = {
  useLabel(): string;
};

const b = block('switch');

function Label({ useLabel }: Props) {
  return <div className={b('label')}>{useLabel()}</div>;
}

const Component = React.memo(Label);

export function makeLabel(props: Props) {
  return () => <Component {...props} />;
}
