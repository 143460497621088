import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { routeTree } from 'pages/routeTree';
import { I18n } from 'services';
import logoSrc from 'shared/images/logoLong.svg';
import { block } from 'utils/classname';

const b = block('footer-logo-link');

type Props = {
  className?: string;
};

function LogoLink({ className }: Props) {
  const lang = I18n.activeLangStateUnit.useState();

  return (
    <RouterLink
      className={b({}, [className])}
      to={routeTree.LANG.getPath({ routeParams: { LANG: lang } })}
    >
      <img className={b('logo')} src={logoSrc} alt="logo" />
    </RouterLink>
  );
}

export const Component = React.memo(LogoLink) as typeof LogoLink;
