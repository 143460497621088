import { useMemo } from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';

import { VariantSelectionQuestionForm } from '../../subfeatures';
import { makeElementModels } from './makeElementModels';

export function useModel(
  data: M.SingleChoiceQuestion | M.SingleChoiceQuizQuestion,
  state: {
    valueState: FormElementState<VariantSelectionQuestionForm.SingleChoiceElementModel | null>;
    textState: FormElementState<string>;
  },
  initialText?: Record<string, string>,
): VariantSelectionQuestionForm.Model {
  const lang = I18n.activeLangStateUnit.useState();
  const isDropdown = 'control' in data && !!data.control?.dropdown;

  return useMemo(
    () => ({
      kind: 'single',
      display: isDropdown ? 'select' : 'radio',
      valueState: state.valueState,
      elementModels: data.variants.map(x =>
        makeElementModels(x, lang, initialText?.[x.uuid]),
      ),
    }),
    [data.variants, initialText, isDropdown, lang, state.valueState],
  );
}
