import React from 'react';
import { Route } from 'react-router';

import * as History from './History';
import * as Members from './Members';
import * as Passport from './Passport';
import * as RecycleBin from './RecycleBin';
import * as Reflexion from './Reflexion';
import * as Results from './Results';
import * as Stages from './Stages';
import * as features from './features';

type Props = Omit<features.Page.Props, 'Navigation' | 'Content'>;

const Content = React.memo(
  ({
    user,
    userRole,
    projectBook,
    projectBookUser,
    projectBookShadowUserRole,
    users,
  }: features.Page.ContentProvidedData) => {
    return (
      <>
        <Route path={Passport.path} exact>
          <Passport.Component
            projectBook={projectBook}
            projectBookShadowUserRole={projectBookShadowUserRole}
          />
        </Route>
        <Route path={Members.path} exact>
          <Members.Component
            projectBookShadowUserRole={projectBookShadowUserRole}
          />
        </Route>
        <Route path={Stages.path}>
          <Stages.Component
            user={user}
            userRole={userRole}
            projectBook={projectBook}
            projectBookUser={projectBookUser}
            projectBookShadowUserRole={projectBookShadowUserRole}
            users={users}
          />
        </Route>
        <Route path={Results.path}>
          <Results.Component
            projectBook={projectBook}
            projectBookUser={projectBookUser}
            projectBookShadowUserRole={projectBookShadowUserRole}
          />
        </Route>
        <Route path={RecycleBin.path}>
          <RecycleBin.Component
            projectBook={projectBook}
            projectBookUser={projectBookUser}
            projectBookShadowUserRole={projectBookShadowUserRole}
          />
        </Route>
        <Route path={Reflexion.path} exact>
          <Reflexion.Component
            projectBook={projectBook}
            projectBookUser={projectBookUser}
            projectBookShadowUserRole={projectBookShadowUserRole}
          />
        </Route>
        <Route path={History.path} exact>
          <History.Component
            user={user}
            projectBook={projectBook}
            projectBookUser={projectBookUser}
            users={users}
          />
        </Route>
      </>
    );
  },
);

function Page(props: Props) {
  return (
    <features.Page.Component
      {...props}
      Navigation={features.Navigation.Component}
      Content={Content}
    />
  );
}

export const Component = React.memo(Page) as typeof Page;
