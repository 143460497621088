import React, { useMemo } from 'react';

import { I18n } from 'services';
import { ReactComponent as CrossIcon } from 'shared/images/cross.svg';
import { ReactComponent as PlusIcon } from 'shared/images/plus.svg';
import { block } from 'utils/classname';

import i18nData from '../../i18n.json';
import { CommonProps } from '../types';
import './style.scss';
import { ProvidedContentProps } from './types';

const b = block('sortable-list-chip-item');

type Props = CommonProps & {
  Content: React.FC<ProvidedContentProps>;
};

function ChipItem({ Content, ...commonProps }: Props) {
  const text = I18n.useText(i18nData).item.chip.button;

  const { variant, provided, snapshot, isStatic, onDelete, onAppend } =
    commonProps as CommonProps & { [key: string]: undefined };

  const { ButtonIcon, title, handleButtonClick } = useMemo(() => {
    switch (variant) {
      case 'sortable': {
        return {
          ButtonIcon: CrossIcon,
          title: text.delete.title,
          handleButtonClick: onDelete,
        };
      }
      case 'appending': {
        return {
          ButtonIcon: PlusIcon,
          title: text.append.title,
          handleButtonClick: onAppend,
        };
      }
    }
  }, [variant, text.delete.title, text.append.title, onAppend, onDelete]);

  const mode = useMemo(() => {
    if (snapshot?.isDragging) {
      return 'dragging';
    }

    if (isStatic) {
      return 'disabled';
    }

    return 'regular';
  }, [isStatic, snapshot?.isDragging]);

  return (
    <div
      className={b({ variant, mode })}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
    >
      {handleButtonClick && (
        <button
          className={b('button')}
          type="button"
          title={title}
          onClick={handleButtonClick}
        >
          <ButtonIcon className={b('button-icon')} />
        </button>
      )}
      <Content mode={mode} />
    </div>
  );
}

export { makeContent } from './Content';

export const Component = React.memo(ChipItem) as typeof ChipItem;
