export const QUESTION_ANSWER_VISIBILITY = {
  everyone: -1,
  nobody: 0,
  ROLE_STAFF: 1,
  ROLE_TUTOR: 2,
  AUTHOR: 4,
  PROJECT_AUTHOR: 8,
  PROJECT_SUPERVISOR: 16,
  GROUP_LEADER: 32,
  GROUP_MEMBER: 64,
} as const;
