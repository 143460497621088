import { GeoJsonObject } from 'geojson';

import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    uuid?: M.UUID;
    group?: string;
    project: M.UUID;
    current_stage?: number;
    answers: Record<
      M.UUID,
      number | Date | string[] | M.Image | M.Location | GeoJsonObject
    >;
    custom: Record<string, Record<M.UUID, string>>;
  };
};

export const dataDraftWrite = makeService<InputData, M.QuestionnaireData>(
  'data_draft_write',
  'post-json',
);
