import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'I18nEdit',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "i18n-edit" */
      './Component'
    ),
  subfeatures: [],
});

export const Component = feature.Component;
