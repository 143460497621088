import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    quiz: M.UUID;
    answers: Record<M.UUID, number | string[] | M.Image | M.Location>;
  };
};

export const quizAnswerWrite = makeService<InputData, M.QuizAnswer>(
  'quiz_answer_write',
  'post-json',
);
