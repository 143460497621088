import * as M from 'types/serverModels';

import { makeService } from './utils';

type PathInputData = {
  code: M.LandingPage['code'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  landing_page?: M.LandingPage;
  products?: M.Product[];
  projects?: M.Project[];
};

type OutputData = {
  landingPage?: M.LandingPage;
  products: { [key: M.Product['uuid']]: M.Product };
  projects: { [key: M.Project['uuid']]: M.Project };
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    landingPage: data.landing_page,
    products:
      data.products?.reduce<{ [key: M.Product['uuid']]: M.Product }>(
        (acc, x) => ({ ...acc, [x.uuid]: x }),
        {},
      ) || {},
    projects:
      data.projects?.reduce<{ [key: M.Project['uuid']]: M.Project }>(
        (acc, x) => ({ ...acc, [x.uuid]: x }),
        {},
      ) || {},
  };
}

export const landingPageGet = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, code }, convertToSearchParams) =>
    `landing_page/${code}/?${convertToSearchParams({ ticket })}`,
  'api-v2-get',
  false,
  extractResponse,
);
