import React, { useMemo } from 'react';

import { makePrimaryUnit, PrimaryStateUnit } from 'utils/State';

import * as WithAnimation from './WithAnimation';
import * as WithoutAnimation from './WithoutAnimation';
import { HeaderProps } from './types';

export * as SimpleLabeledHeader from './SimpleLabeledHeader';
export type { HeaderProps, ExpandTriggerAreaProps } from './types';

type Props = {
  Header: React.FC<HeaderProps>;
  Content: React.FC;
  visibleUnit: PrimaryStateUnit<boolean>;
  withAnimation?: boolean;
  className?: string;
};

export const makeVisibleUnit = (initialValue: boolean = false) =>
  makePrimaryUnit<boolean>(initialValue);

export const useVisibleUnit = (initialValue: boolean = false) =>
  useMemo(() => makeVisibleUnit(initialValue), []);

function ExpansionPanel({ withAnimation = false, ...props }: Props) {
  return withAnimation ? (
    <WithAnimation.Component {...props} />
  ) : (
    <WithoutAnimation.Component {...props} />
  );
}

export const Component = React.memo(ExpansionPanel) as typeof ExpansionPanel;
