import React, { useState, useCallback, useEffect } from 'react';

import { Preloader } from 'components';
import { API, Page, Routing } from 'services';
import * as M from 'types/serverModels';

import { routeTree } from '../routeTree';
import * as About from './About';
import * as Discussion from './Discussion';
import * as Monitoring from './Monitoring';
import * as Rating from './Rating';
import * as Settings from './Settings';
import * as features from './features';

function Group() {
  const [group, setGroup] = useState<M.ServerGroup>();
  const [groupUUID, setGroupUUID] = useState<string>('');

  const isSettingsCurr = Routing.useCurrentLocationIsMatchingTo(
    routeTree.LANG.group.GROUP.settings.getPath(),
  );

  const getGroup = useCallback(() => {
    if (!groupUUID) {
      return;
    }

    API.services.serverGroupRead
      .callPromised({ uuid: groupUUID, licenses: 1 })
      .then(group => {
        setGroup(group);
      });
  }, [groupUUID]);

  useEffect(() => {
    getGroup();
  }, [getGroup]);

  useEffect(() => {
    const params = routeTree.LANG.group.GROUP.getRouteParams();
    const uuid = params && params.group;

    if (!groupUUID && uuid) setGroupUUID(uuid);

    return Routing.subscribeLocationChange(() => {
      const params = routeTree.LANG.group.GROUP.getRouteParams();
      params && params.group && setGroupUUID(params.group);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {group ? (
        <>
          {!isSettingsCurr && <features.GroupHeader.Component group={group} />}
          {!isSettingsCurr && <features.Tabs.Component group={group} />}
          <About.Component group={group} />
          <Discussion.Component group={group} />
          <Rating.Component group={group} />
          <Monitoring.Component group={group} />

          <Settings.Component group={group} getGroup={getGroup} />
        </>
      ) : (
        <Preloader.Component size="m" />
      )}
    </>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.group.GROUP.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(Group),
  features: Object.values(features).map(x => x.feature),
});
