import { I18n } from 'services';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { AnswerVariant } from 'utils/business';

import { FilteringQuestion, VariantSelectionAnswer } from './types';

// NOTE variantSelectionQuestionIDToAnswerVariants is passed in constuctor context to share same state with question instance
export function makeFilteringQuestionsUnit(
  project: M.ProjectReadData,
  widget: M.Widget,
  answerIDToCheckboxStateCache: Record<string, FormElementState<boolean>>,
  variantSelectionQuestionIDToAnswerVariants?: Record<
    string,
    PrimaryStateUnit<AnswerVariant.AnswerVariant[]>
  >,
): AbstractStateUnit<FilteringQuestion[]> {
  const questions = project.questions.flatMap<FilteringQuestion>(question => {
    const filterByQuestion =
      widget.descriptor?.filter?.answers?.[question.uuid];

    switch (question.type) {
      case 'multi_choice':
      case 'single_choice': {
        const filterByVariantSelectionQuestion = filterByQuestion as
          | M.FilterVariantSelectionAnswers
          | undefined;

        const answers =
          variantSelectionQuestionIDToAnswerVariants !== undefined
            ? makeDerivedUnit(
                variantSelectionQuestionIDToAnswerVariants[question.uuid],
              ).getUnit(variants => {
                return variants.map((x): VariantSelectionAnswer => {
                  if (answerIDToCheckboxStateCache[x.id] === undefined) {
                    answerIDToCheckboxStateCache[x.id] = makeFormElementState(
                      filterByVariantSelectionQuestion === undefined
                        ? false
                        : filterByVariantSelectionQuestion.includes(x.id),
                    );
                  }

                  return {
                    id: x.id,
                    text: x.text.formElementState.units.value,
                    checkboxState: answerIDToCheckboxStateCache[x.id],
                  };
                });
              })
            : makePrimaryUnit(
                question.variants.map((x): VariantSelectionAnswer => {
                  const initialState =
                    filterByVariantSelectionQuestion === undefined
                      ? false
                      : filterByVariantSelectionQuestion.includes(x.uuid);

                  return {
                    checkboxState: makeFormElementState<boolean>(initialState),
                    id: x.uuid,
                    text: I18n.makeTextUnitFromOptionalMultiling(x.title),
                  };
                }),
              );

        return {
          kind: 'variant-selection',
          id: question.uuid,
          answers,
          titleUnit: I18n.makeTextUnitFromOptionalMultiling(question.title),
        };
      }
      case 'number':
      case 'probe': {
        const filterByNumericQuestion = filterByQuestion as
          | M.FilterNumericAnswers
          | undefined;

        return {
          kind: 'numeric',
          id: question.uuid,
          fromState: makeFormElementState<number | null>(
            filterByNumericQuestion?.from ?? null,
          ),
          toState: makeFormElementState<number | null>(
            filterByNumericQuestion?.to ?? null,
          ),
          titleUnit: I18n.makeTextUnitFromOptionalMultiling(question.title),
        };
      }
      case 'date': {
        const filterByDateQuestion = filterByQuestion as
          | M.FilterDateAnswers
          | undefined;

        return {
          kind: 'date',
          id: question.uuid,
          fromState: makeFormElementState<Date | null>(
            filterByDateQuestion?.from === undefined
              ? null
              : new Date(filterByDateQuestion?.from),
          ),
          toState: makeFormElementState<Date | null>(
            filterByDateQuestion?.to === undefined
              ? null
              : new Date(filterByDateQuestion?.to),
          ),
          titleUnit: I18n.makeTextUnitFromOptionalMultiling(question.title),
        };
      }

      default:
        return [];
    }
  });

  return makePrimaryUnit(questions);
}
