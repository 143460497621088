import { useEffect } from 'react';

import { Language } from 'types';
import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const languageRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.LanguageReferenceBook[]>
  >;

const languagesCallStateUnit = makeDerivedUnit(
  languageRefsCallStateUnit,
).getUnit<
  CallState<Record<M.LanguageReferenceBook['code'], M.LanguageReferenceBook>>
>(languageRefsCallState => {
  if (languageRefsCallState.kind === 'successful') {
    const data = languageRefsCallState.data.reduce<
      Record<M.LanguageReferenceBook['code'], M.LanguageReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.code]: x,
      };
    }, {} as Record<M.LanguageReferenceBook['code'], M.LanguageReferenceBook>);

    return {
      kind: 'successful',
      data,
    };
  }

  return languageRefsCallState;
});

let wasCalled = false;

function useLanguageRefsCallState() {
  const callState = languageRefsCallStateUnit.useState();

  const { call } = services.refLoad.useUnitService(
    languageRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'language' });
    }
  }, [callState.kind, call]);
}

export function useCallState() {
  const callState = languagesCallStateUnit.useState();

  useLanguageRefsCallState();

  return callState;
}
