import { breakpoints } from './constants';
import { Device } from './types';

export function getBreakpointDevice(): Device {
  const [breakpointDevice] = Object.entries(breakpoints).find(
    ([_, resolution]) => {
      return window.innerWidth <= resolution;
    },
  )! as [Device, number];

  return breakpointDevice;
}
