import { makeFeature } from 'services/Feature';

import type { Component as TComponent } from './Component';
import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'SortableList',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "sortable-list" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
});

export * as Container from './container';
export * as Item from './item';

export const Component = feature.Component as typeof TComponent;
