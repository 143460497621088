import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'MaterialModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "material-modal" */
      './Component'
    ),
  subfeatures: [],
});

export { isOpenUnit } from './units';

export const Component = feature.Component;
