import React from 'react';
import { useRouteMatch } from 'react-router';

import { Page } from 'services';

import { routeTree } from '../../routeTree';
import * as Groups from './Groups';
import * as Users from './Users';
import * as features from './features';

function Search() {
  const match = useRouteMatch({ path: Groups.path });

  return (
    <features.Layout.Component
      header={
        <features.Header.Component
          Field={match && features.SearchField.Component}
        />
      }
    >
      <Groups.Component />
      <Users.Component />
    </features.Layout.Component>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.community.search.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(Search),
  features: Object.values(features).map(x => x.feature),
});
