import { I18n } from 'services';
import * as TS from 'types';
import { UnitDebugData } from 'utils/State';

import { makeSingleUnitValidator } from './makeValidator';

export const nonEmptyString = (
  messageReference: I18n.EntryReference,
  debugData?: UnitDebugData,
) =>
  makeSingleUnitValidator(
    (value: string): TS.ValidationResult =>
      value === '' ? { kind: 'invalid', messageReference } : { kind: 'valid' },
    undefined,
    debugData,
  );
