import { I18n } from 'services';
import * as TS from 'types';
import { UnitDebugData } from 'utils/State';

import { makeSingleUnitValidator } from './makeValidator';

const isURL = (value: string) => {
  try {
    const url = new URL(value);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
};

export const urlSyntax = (
  messageReference: I18n.EntryReference,
  debugData?: UnitDebugData,
) =>
  makeSingleUnitValidator(
    (value: string): TS.ValidationResult =>
      isURL(value) ? { kind: 'valid' } : { kind: 'invalid', messageReference },
    undefined,
    debugData,
  );
