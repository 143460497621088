import React, { useMemo, useCallback, useEffect } from 'react';

import { ReactComponent as CheckIcon } from 'shared/images/check.svg';
import * as TS from 'types';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { block } from 'utils/classname';

import * as ErrorMessage from '../ErrorMessage';
import './style.scss';

export { makeLabel } from './Label';
const b = block('checkbox');

type Props = {
  /**
   * Label is component which is rendered to the right of radio control with center aligning
   */
  Label?: React.FC;
  /**
   * Extension is component which is rendered to the right of label without aligning
   */
  Extension?: React.FC;
  formElementState: FormElementState<boolean>;
  className?: string;
  disabled?: boolean;
  isClearValueOnUnmount?: boolean;
  errorRows?: 0 | 1 | 2;
  boxToTop?: boolean;
};

function Checkbox({
  formElementState,
  Label,
  Extension,
  className,
  disabled,
  errorRows = 0,
  isClearValueOnUnmount = false,
  boxToTop = false,
}: Props) {
  const checked = formElementState.units.value.useState();
  const error = formElementState.units.error.useState();
  const isDisabled = formElementState.units.disabled.useState();

  const handleCheckboxClick = useCallback(() => {
    formElementState.units.value.setState(prev => !prev);
  }, [formElementState]);

  useEffect(() => {
    return () => {
      isClearValueOnUnmount && formElementState.units.value.setState(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={b({ checked, disabled: disabled ?? isDisabled }, [className])}
      onClick={disabled ?? isDisabled ? undefined : handleCheckboxClick}
    >
      <div className={b('control')}>
        <div className={b('box-and-label', { boxToTop })}>
          <div className={b('box')}>
            <div className={b('check-icon')}>
              <CheckIcon />
            </div>
          </div>
          {Label && <Label />}
        </div>
        {Extension && (
          <div className={b('extension')}>
            <Extension />
          </div>
        )}
      </div>
      {errorRows !== 0 && (
        <ErrorMessage.Component messageReference={error} rows={errorRows} />
      )}
    </div>
  );
}

export function makeFieldState(
  initialState: boolean = false,
  validators?: TS.Validator[],
) {
  return makeFormElementState(initialState, validators);
}

export function useFormElementState(initialState: boolean = false) {
  return useMemo(() => makeFieldState(initialState), [initialState]);
}

export const Component = React.memo(Checkbox) as typeof Checkbox;
