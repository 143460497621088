import { I18n } from 'services';
import * as TS from 'types';
import { UnitDebugData } from 'utils/State';

import { makeSingleUnitValidator } from './makeValidator';

const youtubeRegex =
  /^(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;

const youtubeIFrameRegex =
  /<iframe(?:\b|_).*?(?:\b|_)src="https:\/\/www.youtube.com\/(?:\b|_).*?(?:\b|_)iframe>/g;

const vimeoRegex =
  /^(?:https?:\/\/)?(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/g;

export const videoURLSyntax = (
  messageReference: I18n.EntryReference,
  debugData?: UnitDebugData,
) =>
  makeSingleUnitValidator(
    (value: string): TS.ValidationResult => {
      if (value.length === 0) {
        return { kind: 'valid' };
      }

      const isValid = [youtubeRegex, youtubeIFrameRegex, vimeoRegex].some(x =>
        x.test(value.trim()),
      );

      if (isValid) {
        return { kind: 'valid' };
      }
      return { kind: 'invalid', messageReference };
    },
    undefined,
    debugData,
  );
