import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'ConstructorFormSection',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "constructor-form-section" */
      './Component'
    ),
  subfeatures: [],
});

export const Component = feature.Component;
