import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../routeTree';
import * as features from './features';
import './style.scss';

type Props = {};

const mode: features.ProjectConstructor.ConstructorFullMode = {
  kind: 'full',
};

function ProjectConstructor({}: Props) {
  return <features.ProjectConstructor.Component mode={mode} />;
}

export const Component = Page.makePage({
  path: routeTree.LANG.project.constructor.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(ProjectConstructor) as typeof ProjectConstructor,
  features: Object.values(features).map(x => x.feature),
});
