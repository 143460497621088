import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = M.EmulateParamsHolder & {
  map?: {
    answers?: string;
    approved?: string;
    cdate?: string;
    location?: string;
    project?: string;
    status?: string;
    user?: string;
    uuid?: string;
  };
  likes?: 1;
  resolveUser?: 1;
  resolveGroup?: 1;
  banned?: 1;
  filter: string;
  // NOTE JSON string of this:
  // M.Filter & {
  //   project: M.UUID;
  //   uuid?: M.UUID;
  //   user?: string;
  // };
};

export const dataSearch = makeService<InputData, M.QuestionnaireData[]>(
  'data_search',
  'post',
);
