import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = { ticket?: M.UUID } & (
  | { uuid: M.Course['uuid'] }
  | { code: M.Course['code'] }
);

type APIOutputData = {
  clean: M.Course;
  response: M.CourseReadCourse;
};

type OutputData = {
  course: M.Course;
  courseReadCourse: M.CourseReadCourse;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    course: data.clean,
    courseReadCourse: data.response,
  };
}

export const courseRead = makeService<InputData, APIOutputData, OutputData>(
  'course_read',
  'get',
  false,
  extractResponse,
);
