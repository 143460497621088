import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  question: string;
  filter: M.Filter;
};

export const getMapData = makeService<InputData, M.ServerMapData[]>(
  'get_map_data',
  'get',
);
