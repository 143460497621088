import * as M from 'types/serverModels';

export function getScore(data: M.Quiz) {
  return data.questions.reduce(
    (acc, x) => {
      if (!data.answers) {
        return acc;
      }
      const score = calcScore(x, data.answers[x.uuid]);

      if (score === null) {
        return acc;
      }

      return {
        max: acc.max + score.max,
        result: acc.result + score.result,
      };
    },
    { result: 0, max: 0 },
  );
}

//Refactoring needed
export function calcScore(question: M.Question | M.QuizQuestion, answer: any) {
  var max = 0; //максимальное количество баллов за вопрос
  var result = 0; //набранное число баллов за вопрос

  if (!answer) {
    return null;
  }

  switch (question.type) {
    case 'number':
      if (!('score' in question)) {
        return null;
      }
      max = Number(question.score || 0);

      let ds, sm, delta;

      if (question.matchings) {
        question.matchings.forEach(x => {
          //Ниже идет вычисление погрешности, которая допустима в числовом ответе
          sm = (x + '').indexOf('.');
          ds = (x + '').length;

          if (sm < 0) {
            delta = 0;
          } else {
            delta = ds - sm - 1;
          }
          if (
            Math.floor(parseFloat(x) * Math.pow(10, delta)) ===
            Math.floor(parseFloat(answer) * Math.pow(10, delta))
          ) {
            result = max;
          }
        });
      }

      break;
    case 'string':
      if (!('score' in question)) {
        return null;
      }
      max = Number(question.score || 0);

      if (question.matchings) {
        question.matchings.forEach(x => {
          if (/^\/.+\/[gmiu]*$/.test(x)) {
            if (new RegExp(x).test(answer)) {
              result = max;
            }
          } else {
            // иначе сравниваем по подстроке
            var pattern = new RegExp(
              '^' + x.replace(/\*/g, '.*').trim() + '$',
              'im',
            );
            if (pattern.test(answer.trim())) {
              result = max;
            }
          }
        });
      }
      break;
    case 'multi_choice':
    case 'single_choice':
      if (!('score' in question.variants[0])) {
        return null;
      }
      question.variants.forEach(x => {
        const score = Number(x.score || 0);

        if (score > 0) {
          if (question.type === 'multi_choice') {
            max += score;
          } else if (score > max) {
            max = score;
          }
        }

        if (answer.includes(x.uuid)) {
          result += score;
        }
      });

      break;
    case 'map':
      if (!('score' in question)) {
        return null;
      }
      max = Number(question.score || 0);

      var m = question.matching;
      if (
        answer[0] >= m[0] &&
        answer[0] <= m[2] &&
        answer[1] >= m[1] &&
        answer[1] <= m[3]
      ) {
        result = max;
      }

      break;
    case 'text':
    case 'image':
      if (!('score' in question)) {
        return null;
      }
      max = Number(question.score || 0);
      result = max;
      break;
    default:
      break;
  }

  return { max, result };
}
