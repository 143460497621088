import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: M.Quiz;
};

export const quizWrite = makeService<InputData, M.Quiz>(
  'quiz_write',
  'post-json',
);
