import { pathToRegexp } from 'path-to-regexp';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { routeTree } from 'pages/routeTree';
import * as API from 'services/API';
import * as Routing from 'services/Routing';
import { userStateUnit as userUnit } from 'shared/stateUnits';

import { profileNotFoundCode } from './constants';
import { userProfileCallStateUnit } from './units';

type Props = {};

function UserFetcher({}: Props) {
  const ticket = API.useTicket();
  const location = useLocation();

  const userProfilePath = routeTree.LANG.users.getPath();
  const userPersonalAccountPath = routeTree.LANG['personal-account'].getPath();

  const callState = userProfileCallStateUnit.useState();

  const call = API.services.userProfile.useCall(userProfileCallStateUnit);

  useEffect(() => {
    if (ticket === null) {
      userUnit.setState({ kind: 'null' });
    } else {
      call({ ticket });
    }
  }, [call, ticket]);

  useEffect(() => {
    const previousLocation = Routing.getPreviousLocation();

    const haveGoneToUserProfileFromNotUserProfile =
      location.pathname.match(
        pathToRegexp(userProfilePath, [], { end: false }),
      ) !== null &&
      previousLocation?.pathname.match(
        pathToRegexp(userProfilePath, [], { end: false }),
      ) === null;
    const haveGoneToUserPersonalAccountFromNotUserPersonalAccount =
      location.pathname.match(
        pathToRegexp(userPersonalAccountPath, [], { end: false }),
      ) !== null &&
      previousLocation?.pathname.match(
        pathToRegexp(userPersonalAccountPath, [], { end: false }),
      ) === null;

    const shouldRefetchUser =
      haveGoneToUserProfileFromNotUserProfile ||
      haveGoneToUserPersonalAccountFromNotUserPersonalAccount;

    if (ticket !== null && shouldRefetchUser) {
      call({ ticket });
    }
  }, [
    ticket,
    userProfilePath,
    userPersonalAccountPath,
    location.pathname,
    call,
  ]);

  useEffect(() => {
    if (callState.kind === 'successful') {
      userUnit.setState({ kind: 'loaded', user: callState.data });
    } else if (callState.kind === 'pending') {
      userUnit.setState({ kind: 'pending' });
    } else if (
      callState.kind === 'error' &&
      callState.code === profileNotFoundCode
    ) {
      API.deleteTicket();
    }
  }, [callState]);

  return null;
}

export const Component = React.memo(UserFetcher) as typeof UserFetcher;
