import { useEffect } from 'react';

import { usePrimaryUnit } from 'utils/State';

import { isClientScriptLoaded } from './units';

if (process.env.BUILD_TARGET === 'client' && (module as any).hot) {
  (module as any).hot.decline();
}

type Options = {
  module: NodeModule;
};

export function useSocket({ module }: Options) {
  if (process.env.BUILD_TARGET === 'client' && (module as any).hot) {
    (module as any).hot.decline();
  }

  const socketUnit = usePrimaryUnit<SocketIOClient.Socket | null>(null);
  const isSocketConnectedUnit = usePrimaryUnit<boolean>(false);

  const socket = socketUnit.useState();

  useEffect(() => {
    const handleClientScriptLoad = () => {
      socketUnit.setState(
        window.io(process.env.RAZZLE_SOCKET_BACKEND_URL, {
          autoConnect: false,
        }),
      );
    };

    if (isClientScriptLoaded.getState()) {
      handleClientScriptLoad();
    }

    return isClientScriptLoaded.subscribe({
      name: 'socket-initializer',
      callback: isLoaded => {
        if (!isLoaded) {
          socketUnit.resetState();

          return;
        }

        handleClientScriptLoad();
      },
    });
  }, [socketUnit]);

  useEffect(() => {
    if (socket === null) {
      return;
    }

    const handleConnect = () => {
      isSocketConnectedUnit.setState(true);
    };

    const handleDisconnect = () => {
      isSocketConnectedUnit.setState(false);
    };

    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);

      isSocketConnectedUnit.resetState();
    };
  }, [socket, isSocketConnectedUnit]);

  return { socket, isSocketConnectedUnit };
}
