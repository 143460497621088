import * as TS from 'types';
import { MappedState } from 'utils/State';

import { Set } from './types';

export function makeSetData(
  set: MappedState<MappedState<Set>>,
): TS.VennChartSetsDataItem | null {
  switch (set.kind) {
    case 'for-number-question':
      const from = set.range.from.getValue();
      const to = set.range.to.getValue();

      if (!from || !to) {
        return {
          questionText: set.question.name,
          answerText: String(from || to || ''),
        };
      }
      return {
        questionText: set.question.name,
        answerText: `${set.range.from.getValue()} - ${set.range.to.getValue()}`,
      };
    case 'for-single-variant':
      return {
        questionText: set.question.name,
        answerText:
          set.variants.find(x => x.id === set.selectedVariantID.units.value)
            ?.name || '',
      };

    case 'for-multiple-variants':
      return {
        questionText: set.question.name,
        answerText: set.variants
          .filter(x => x.value.units.value)
          .map(x => x.name)
          .join(', '),
      };
    default: {
      return null;
    }
  }
}
