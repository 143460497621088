import React, { useCallback } from 'react';
import { useLocation } from 'react-router';

import { Modal } from 'components';
import * as SignInForm from 'features/auth/signIn/Form';
import * as SignUpForm from 'features/auth/signUp/Form';
import { I18n } from 'services';
import { block } from 'utils/classname';

import { hashes } from '../../constants';
import { isOpenUnit } from '../../units';
import {
  signInTitleReference,
  signUpTitleReference,
} from './i18nSharedReferences';
import './style.scss';
import { infoTextUnit } from './units';

const b = block('auth-modal');

type Props = {};

const SignInHeader = Modal.Header.makeFromTitle(() =>
  I18n.useReference(signInTitleReference),
);

const SignUpHeader = Modal.Header.makeFromTitle(() =>
  I18n.useReference(signUpTitleReference),
);

function reset() {
  infoTextUnit.setState('');
}

function close() {
  isOpenUnit.setState(false);

  reset();
}

function Auth({}: Props) {
  const location = useLocation();

  const infoText = infoTextUnit.useState();

  const handleClose = useCallback(() => {
    reset();
  }, []);

  const handleSubmitComplete = useCallback(() => {
    close();
  }, []);

  return (
    <Modal.Component
      className={b()}
      isOpenUnit={isOpenUnit}
      Header={
        location.hash === hashes.signInHashTag ? SignInHeader : SignUpHeader
      }
      infoText={infoText}
      size="s"
      onClose={handleClose}
    >
      {location.hash === hashes.signInHashTag && (
        <SignInForm.Component onSubmitComplete={handleSubmitComplete} />
      )}
      {location.hash === hashes.signUpHashTag && (
        <SignUpForm.Component onSubmitComplete={handleSubmitComplete} />
      )}
    </Modal.Component>
  );
}

export { infoTextUnit };

export const Component = React.memo(Auth) as typeof Auth;
