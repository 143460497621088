import React from 'react';

import { ReactComponent as ArrowDown } from 'shared/images/angleArrowDown.svg';
import { block } from 'utils/classname';

import * as UnderlinedLabel from '../../UnderlinedLabel';
import { LabelProps } from '../LabelProps';
import './style.scss';

const b = block('main-menu-root-parent-entry-label-with-arrow');

type PublicProps = React.PropsWithChildren<{}>;

type Props = LabelProps & PublicProps;

function LabelWithArrow({
  children,
  isActive,
  menuIsOpenUnit,
}: React.PropsWithChildren<Props>) {
  const isMenuOpen = menuIsOpenUnit.useState();

  return (
    <div className={b()}>
      <div className={b('content')}>
        <UnderlinedLabel.Component underlined={isActive}>
          {children}
        </UnderlinedLabel.Component>
      </div>
      <ArrowDown
        className={b('expansion-icon', { 'for-open-menu': isMenuOpen })}
      />
    </div>
  );
}

export const Component = React.memo(LabelWithArrow) as typeof LabelWithArrow;

export const makeTextComponent = (text: string) => (props: LabelProps) =>
  <LabelWithArrow {...props}>{text}</LabelWithArrow>;
