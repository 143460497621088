import * as M from 'types/serverModels';

export function getCertificate(
  certificateList: M.Certificate[],
  project: Pick<M.ProjectReadData, 'uuid'>,
) {
  return certificateList.find(certificate => {
    if (
      certificate.details.reason === undefined ||
      !('project' in certificate.details.reason)
    ) {
      return false;
    }

    return certificate.details.reason.project === project.uuid;
  });
}
