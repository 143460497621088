import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnit,
} from 'utils/State';

import { State } from './types';

function makeFilterState<T>(
  valueStateUnit: AbstractStateUnit<T>,
  switchState: AbstractStateUnit<boolean>,
) {
  return makeDerivedUnit(switchState, valueStateUnit).getUnit<T | null>(
    (switchValue, valueState) => {
      return switchValue ? valueState : null;
    },
  );
}

export function makeFilterStateUnitForRequest({
  switchesState,
  submitDate,
  locationState,
  groupsAndUsers,
  filteringQuestionsMappingUnit,
}: State) {
  return makeMappingUnit({
    filterByQuestions: makeFilterState(
      filteringQuestionsMappingUnit,
      switchesState.filtertingQuestions.units.value,
    ),
    submitDateFromState: makeFilterState(
      submitDate.from.units.value,
      switchesState.sumbitDate.units.value,
    ),
    submitDateToState: makeFilterState(
      submitDate.to.units.value,
      switchesState.sumbitDate.units.value,
    ),
    locationState: makeFilterState(
      locationState.units.value,
      switchesState.location.units.value,
    ),
    groupState: makeFilterState(
      makeDerivedUnit(groupsAndUsers.group.units.value).getUnit(state =>
        state !== null && state.uuid !== undefined ? state.uuid : null,
      ),
      makeDerivedUnit(
        switchesState.groupsAndUsers.switch.units.value,
        switchesState.groupsAndUsers.activeTab,
      ).getUnit(
        (switchState, tabState) => switchState === true && tabState === 'group',
      ),
    ),
    usersState: makeFilterState(
      makeDerivedUnit(groupsAndUsers.users.units.value).getUnit(state =>
        state.map(x => x.login),
      ),
      makeDerivedUnit(
        switchesState.groupsAndUsers.switch.units.value,
        switchesState.groupsAndUsers.activeTab,
      ).getUnit(
        (switchState, tabState) => switchState === true && tabState === 'users',
      ),
    ),
  });
}

export type FilterForRequest = ReturnType<typeof makeFilterStateUnitForRequest>;
