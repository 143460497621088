import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.UUID;
};

export const quizDataList = makeService<InputData, M.Quiz>(
  'quiz_data_list',
  'get',
);
