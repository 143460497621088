import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  limit?: number;
  skip?: number;
  sort?: {};
  filter?: {
    phrase?: string;
    types?: M.SchoolGroupsType[];
  };
};

export const groupSchoolSearch = makeService<InputData, M.SchoolGroups>(
  'group_school_search',
  'get',
);
