import { makeFeature } from 'services/Feature';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'LandingPage',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "landing-page" */
      './Component'
    ),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
