import React, { useEffect } from 'react';

import * as TS from 'types';
import { makePrimaryUnit } from 'utils/State';

import { setBodyModifer } from './setBodyModifier';

type Props = {};

export const themeStateUnit = makePrimaryUnit<TS.Theme>('light');

function ThemeProvider({ children }: React.PropsWithChildren<Props>) {
  const theme = themeStateUnit.useState();

  useEffect(() => {
    setBodyModifer(theme);
  }, [theme]);

  return <div>{children}</div>;
}

export const Component = React.memo(ThemeProvider);
