import { useMemo, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { validate } from 'uuid';

import { Preloader, Text } from 'components';
import { API, DataPrefetch } from 'services';
import { Page } from 'services';

import { ProjectPageRouteTree, routeTree } from '../../routeTree';
import * as Project from './Project';
import * as features from './features';

type Props = {};

const projectPrefetcher = DataPrefetch.makeDataPrefetcher(
  'project',
  routeTree.LANG.project.list.PROJECT.getPath() + '/:rest*',
  ({ project }, ticket) => {
    if (validate(project)) {
      return API.services.projectRead.callPromised({ ticket, uuid: project });
    }

    return API.services.projectRead.callPromised({ ticket, code: project });
  },
);

const callStateUnit = API.services.projectRead.makeCallStateUnit();

function Content({}: Props) {
  const match = useRouteMatch<{ project: string }>({ path });

  const uuidOrCode = match!.params.project;

  const ticket = API.useTicket();

  const callState = callStateUnit.useState();

  const call = API.services.projectRead.useCall(callStateUnit);

  const projectPageRouteTree: ProjectPageRouteTree = useMemo(() => {
    return {
      kind: 'project-list',
      tree: routeTree.LANG.project.list,
    };
  }, []);

  useEffect(() => {
    if (projectPrefetcher.getData() !== DataPrefetch.notFetched) {
      return;
    }

    if (validate(uuidOrCode)) {
      call({ uuid: uuidOrCode });
    } else {
      call({ code: uuidOrCode });
    }
  }, [uuidOrCode, ticket, call]);

  useEffect(
    () => () => {
      projectPrefetcher.deleteData();
    },
    [ticket],
  );

  const prefetchedProject = projectPrefetcher.getData();
  if (prefetchedProject !== DataPrefetch.notFetched) {
    return (
      <Project.Component
        project={prefetchedProject}
        projectPageRouteTree={projectPageRouteTree}
      />
    );
  }

  switch (callState.kind) {
    case 'initial': {
      return null;
    }
    case 'pending': {
      return <Preloader.Component />;
    }
    case 'successful': {
      return (
        <Project.Component
          project={callState.data}
          projectPageRouteTree={projectPageRouteTree}
        />
      );
    }
    case 'error': {
      return (
        <Text.Component color="error">Error {callState.message}</Text.Component>
      );
    }
  }
}

const path = routeTree.LANG.project.list.PROJECT.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'top-on-mount',
  Component: Content,
  features: Object.values(features).map(x => x.feature),
});
