import * as M from 'types/serverModels';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';

import {
  FilteringQuestion,
  FilteringQuestionForRequest,
  VariantSelectionAnswerForRequest,
} from './types';

export function makeFilterQuestionsMappingUnit(
  filteringQuestionsUnit: AbstractStateUnit<FilteringQuestion[]>,
) {
  const derived = makeDerivedUnit(filteringQuestionsUnit).getUnit(state => {
    // eslint-disable-next-line array-callback-return
    return state.map((question): FilteringQuestionForRequest => {
      switch (question.kind) {
        case 'date': {
          return {
            kind: 'date',
            id: question.id,
            fromState: question.fromState.units.value,
            toState: question.toState.units.value,
          };
        }

        case 'numeric': {
          return {
            kind: 'numeric',
            id: question.id,
            fromState: question.fromState.units.value,
            toState: question.toState.units.value,
          };
        }

        case 'variant-selection': {
          return {
            kind: 'variant-selection',
            id: question.id,
            answers: makeDerivedUnit(question.answers).getUnit(answers => {
              return answers.map((x): VariantSelectionAnswerForRequest => {
                return {
                  id: x.id,
                  checkboxState: x.checkboxState.units.value,
                };
              });
            }),
          };
        }
      }
    });
  });

  const mapped = makeMappingUnitFromUnit(makeMappingUnitFromUnit(derived));

  return makeDerivedUnit(mapped).getUnit<M.FilterAnswersMapping>(
    filteringQuestions => {
      const hasValue = (
        filteringQuestion: typeof filteringQuestions[number],
      ): boolean => {
        switch (filteringQuestion.kind) {
          case 'date':
          case 'numeric':
            return (
              filteringQuestion.fromState !== null ||
              filteringQuestion.toState !== null
            );
          case 'variant-selection':
            return (
              filteringQuestion.answers.filter(x => x.checkboxState).length > 0
            );
        }
      };

      const makeFilterByQuestion = (
        filteringQuestion: typeof filteringQuestions[number],
      ): M.FilterAnswers => {
        switch (filteringQuestion.kind) {
          case 'date':
            return {
              from: filteringQuestion.fromState?.toISOString(),
              to: filteringQuestion.toState?.toISOString(),
            };

          case 'numeric':
            return {
              from: filteringQuestion.fromState ?? undefined,
              to: filteringQuestion.toState ?? undefined,
            };
          case 'variant-selection':
            return filteringQuestion.answers
              .filter(x => x.checkboxState)
              .map(x => x.id);
        }
      };

      return filteringQuestions.reduce<M.FilterAnswersMapping>(
        (acc, x) =>
          hasValue(x)
            ? {
                [x.id]: makeFilterByQuestion(x),
                ...acc,
              }
            : acc,
        {},
      );
    },
  );
}
