import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.Investigation.Props;

function Investigation(props: Props) {
  return <features.Investigation.Component {...props} />;
}

export const path = routeTree.LANG.project.list.PROJECT.investigation.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(Investigation) as typeof Investigation,
  features: Object.values(features).map(x => x.feature),
}) as typeof Investigation;
