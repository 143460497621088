import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export * from './types';

export const feature = makeFeature({
  name: 'VariantSelectionQuestionForm',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "variant-selection-question-form" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
});

export const Component = feature.Component;
