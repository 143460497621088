import { routeTree } from 'pages/routeTree';
import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';

import {
  statusesOfDisplayableCourses as profileStatusesOfDisplayableCourses,
  statusesOfDisplayableCoursesForStaffUser as profileStatusesOfDisplayableCoursesForStaffUser,
  statusesOfDisplayableProjects as profileStatusesOfDisplayableProjects,
  statusesOfDisplayableProjectsForStaffUser as profileStatusesOfDisplayableProjectsForStaffUser,
} from '../profile/constants';

function countPersonalDataRows(
  personalData: (unknown[] | string | undefined)[],
) {
  return personalData.reduce((acc, data) => {
    const hasItems = Array.isArray(data) && data.length > 0;
    const hasContent = typeof data === 'string' && data !== '';

    if (hasItems || hasContent) {
      return acc + 1;
    }

    return acc;
  }, 0);
}

function getProjectUUIDs(
  idx: M.User['projectIdx'],
  data?: M.Project['uuid'][],
  statusesOfDisplayableItems?: Set<M.Project['status']>,
  isHideByProjectPrivate?: boolean,
): M.Project['uuid'][] {
  if (!Array.isArray(data)) {
    return [];
  }

  return (
    data.reduce<M.UUID[]>((acc, uuid) => {
      const item = idx[uuid];

      if (typeof item === 'undefined') {
        return acc;
      }

      const isShowPrivateProject = isHideByProjectPrivate
        ? !item.private
        : true;

      const isItemToDisplay =
        typeof item !== 'undefined' &&
        (typeof statusesOfDisplayableItems === 'undefined' ||
          statusesOfDisplayableItems.has(item.status as any)) &&
        isShowPrivateProject;

      if (!isItemToDisplay) {
        return acc;
      }

      return [...acc, uuid];
    }, []) || []
  );
}

function getCourseUUIDs(
  idx: M.User['courseIdx'],
  data?: M.Course['uuid'][],
  statusesOfDisplayableItems?: Set<M.Course['status']>,
): M.Course['uuid'][] {
  if (!Array.isArray(data)) {
    return [];
  }

  return (
    data.reduce<M.UUID[]>((acc, uuid) => {
      const item = idx[uuid];

      const isItemToDisplay =
        typeof item !== 'undefined' &&
        (typeof statusesOfDisplayableItems === 'undefined' ||
          statusesOfDisplayableItems.has(item.status as any));

      if (!isItemToDisplay) {
        return acc;
      }

      return [...acc, uuid];
    }, []) || []
  );
}

type Options = {
  withPrivateDataDisplay?: boolean;
  statusesOfDisplayableCourses?: Set<M.Course['status']>;
  statusesOfDisplayableProjects?: Set<M.Project['status']>;
};

export function getCounters(
  user: M.User,
  {
    withPrivateDataDisplay = false,
    statusesOfDisplayableProjects = withPrivateDataDisplay
      ? profileStatusesOfDisplayableProjectsForStaffUser
      : profileStatusesOfDisplayableProjects,
    statusesOfDisplayableCourses = withPrivateDataDisplay
      ? profileStatusesOfDisplayableCoursesForStaffUser
      : profileStatusesOfDisplayableCourses,
  }: Options = {},
  currUser?: M.User,
): Record<
  Extract<
    keyof typeof routeTree.LANG.users.USER,
    | 'profile'
    | 'personal-information'
    | 'perks'
    | 'projects'
    | 'groups'
    | 'courses'
  >,
  number
> {
  const {
    login,
    badge,
    native_langs_stack,
    langs_stack,
    subjects_stack,
    interests,
    education,
    about,
    perks_stack,
    author_project_stack,
    supervisor_project_stack,
    projectIdx,
    projects,
    groups_leader_stack,
    groups_member_stack,
    courses,
    courseIdx,
  } = user;

  const personalDataRows = countPersonalDataRows([
    badge,
    native_langs_stack,
    langs_stack,
    subjects_stack,
    interests,
    education,
    about,
  ]);

  const perks =
    perks_stack?.reduce<NonNullable<M.User['perks_stack']>['0']['perks']>(
      (acc, perk) => [...acc, ...perk.perks],
      [],
    ) || [];

  const isHideByProjectPrivate =
    currUser &&
    (currUser?.role === USER_ROLE.moderator || currUser?.login === login)
      ? false
      : true;

  const authorProjects = getProjectUUIDs(
    projectIdx,
    author_project_stack,
    statusesOfDisplayableProjects,
    isHideByProjectPrivate,
  );

  const supervisorProjects = getProjectUUIDs(
    projectIdx,
    supervisor_project_stack,
    statusesOfDisplayableProjects,
    isHideByProjectPrivate,
  );
  const participantProjects = getProjectUUIDs(
    projectIdx,
    projects,
    statusesOfDisplayableProjects,
    isHideByProjectPrivate,
  );

  const groups = [
    ...(groups_leader_stack || []),
    ...(groups_member_stack || []),
  ];

  const participantCourses = getCourseUUIDs(
    courseIdx,
    courses,
    statusesOfDisplayableCourses,
  );

  return {
    profile: perks.length + authorProjects.length + groups.length,
    'personal-information': personalDataRows,
    perks: perks.length,
    projects: [...authorProjects, ...supervisorProjects, ...participantProjects]
      .length,
    groups: groups.length,
    courses: participantCourses.length,
  };
}
