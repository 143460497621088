import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from './utils';

type PathInputData = {};

type QueryInputData = {
  filter?: {
    field: keyof M.ProjectBook;
    data:
      | { type: 'list'; value: string[] }
      | { type: 'boolean'; value: boolean }
      | { type: 'string'; value: string }
      | { type: 'value'; value: string; comparison: 'gt' | 'lt' | 'eq' };
  }[];
  sort?: { property: keyof M.ProjectBook; direction: 'ASC' | 'DESC' };
  start?: number;
  limit?: number;
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_books?: SetRequired<
    M.ShortProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >[];
  count?: number;
};

type OutputData = {
  projectBooks: SetRequired<
    M.ShortProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >[];
  count: number;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBooks: data.project_books || [],
    count: data.count || 0,
  };
}

export const userProjectBookGet = makeService<
  InputData,
  APIOutputData,
  OutputData
>(
  (inputData, convertToSearchParams) => {
    const { filter, sort, ...rest } = inputData;

    const filterParam = filter ? `&filter=${JSON.stringify(filter)}` : '';
    const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : '';
    const restParams = convertToSearchParams(rest);

    const params =
      restParams || filterParam || filterParam
        ? `?${restParams}${filterParam}${sortParam}`
        : '';

    return `project_book/${params}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
