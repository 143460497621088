import React, { useCallback } from 'react';

import { FormElementState } from 'utils/FormState';
import { classnames } from 'utils/classname';

import * as ErrorMessage from '../ErrorMessage';
import { b } from './b';
import './style.scss';

type Props = {
  formElementState: FormElementState<string>;
  id?: string;
  Header?: React.FC;
  placeholder?: string;
  className?: string;
  rows?: number;
  resize?: Extract<
    NonNullable<React.CSSProperties['resize']>,
    'vertical' | 'none'
  >;
  errorRows?: 0 | 1 | 2;
};

function TextArea({
  formElementState,
  id,
  Header,
  placeholder,
  rows = 5,
  className,
  errorRows = 1,
  resize = 'vertical',
}: Props) {
  const { units } = formElementState;
  const value = units.value.useState();

  const disabled = units.disabled.useState();
  const isValid = units.isValid.useState();

  const handleTextAreaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      units.value.setState(e.target.value);
    },
    [units.value],
  );

  const handleBlur = useCallback(() => {
    units.visited.setState(true);
  }, [units.visited]);

  const error = units.error.useState();

  return (
    <div className={classnames(b(), className)}>
      {Header && <Header />}
      <textarea
        id={id}
        rows={rows}
        className={b('textarea', {
          resize,
          invalid: !isValid,
        })}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={handleTextAreaChange}
        onBlur={handleBlur}
      />
      {errorRows !== 0 && (
        <ErrorMessage.Component rows={errorRows} messageReference={error} />
      )}
    </div>
  );
}

export const Component = React.memo(TextArea) as typeof TextArea;
