import { useMemo } from 'react';

import { I18n } from 'services';

type References = Record<
  keyof I18n.SharedI18nData['user']['tabs'],
  I18n.EntryReference
>;

export function useTabText(references: References) {
  const myProfileText = I18n.useReference(references.myProfile);
  const personalAccountText = I18n.useReference(references.personalAccount);
  const perksText = I18n.useReference(references.perks);
  const myAssignmentsText = I18n.useReference(references.myAssignments);
  const myProjectBooksText = I18n.useReference(references.myProjectBooks);
  const myProjectsText = I18n.useReference(references.myProjects);
  const myTestsText = I18n.useReference(references.myTests);
  const myCoursesText = I18n.useReference(references.myCourses);
  const myGroupsText = I18n.useReference(references.myGroups);
  const packageText = I18n.useReference(references.package);
  const settingsText = I18n.useReference(references.settings);
  const signOutText = I18n.useReference(references.signOut);

  const text = useMemo(() => {
    return {
      myProfile: myProfileText,
      personalAccount: personalAccountText,
      perks: perksText,
      myAssignments: myAssignmentsText,
      myProjectBooks: myProjectBooksText,
      myProjects: myProjectsText,
      myTests: myTestsText,
      myCourses: myCoursesText,
      myGroups: myGroupsText,
      package: packageText,
      settings: settingsText,
      signOut: signOutText,
    };
  }, [
    myProfileText,
    personalAccountText,
    perksText,
    myAssignmentsText,
    myProjectBooksText,
    myProjectsText,
    myTestsText,
    myCoursesText,
    myGroupsText,
    packageText,
    settingsText,
    signOutText,
  ]);

  return text;
}
