import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const questionnaireLabelReference = makeSharedEntryReference(
  data => data.questionnaireLabel,
);

export const backProjectButtonLabelReference = makeSharedEntryReference(
  data => data.backProjectButtonLabel,
);

export const likeButtonLabelReference = makeSharedEntryReference(
  data => data.likeButtonLabel,
);

export const reportButtonLabelReference = makeSharedEntryReference(
  data => data.reportButtonLabel,
);
