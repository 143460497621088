import { useMemo } from 'react';

import { Device } from 'services';
import { makePrimaryUnit } from 'utils/State';

import * as Desktop from './Desktop';
import * as Mobile from './Mobile';

export * as Desktop from './Desktop';
export * as Header from './Header';

export type { Size } from './types';

export type Props = Desktop.Props;

export function makeIsOpenUnit(initialState: boolean = false) {
  return makePrimaryUnit<boolean>(initialState);
}

export function useIsOpenUnit(initialState: boolean = false) {
  return useMemo(() => makeIsOpenUnit(initialState), [initialState]);
}

function Modal(props: React.PropsWithChildren<Props>) {
  switch (Device.unit.useState()) {
    case 'mobile':
      return (
        <Mobile.Component
          isOpenUnit={props.isOpenUnit}
          Header={props.Header}
          onClose={props.onClose}
          size={props.size}
          className={props.className}
          infoText={props.infoText}
        >
          {props.children}
        </Mobile.Component>
      );
    default:
      return <Desktop.Component {...props} />;
  }
}

export const Component = Modal;
