import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const urlSyntaxReference = makeSharedEntryReference(
  data => data.placeholders.urlSyntax,
);

export const requiredFieldReference = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const invalidURLSyntax = makeSharedEntryReference(
  data => data.validationErrors.invalidURLSyntax,
);

export const someErrorReference = makeSharedEntryReference(
  data => data.someError,
);

export const addButtonLabelReference = makeSharedEntryReference(
  data => data.addButtonLabel,
);

export const editButtonLabelReference = makeSharedEntryReference(
  data => data.editButtonLabel,
);

export const deleteButtonLabelReference = makeSharedEntryReference(
  data => data.deleteButtonLabel,
);

export const saveButtonLabelReference = makeSharedEntryReference(
  data => data.saveButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const inviteButtonLabel = makeSharedEntryReference(
  data => data.inviteButtonLabel,
);

export const materialUnitTypeReference = {
  link: makeSharedEntryReference(data => data.unit.type.material.link),
  text: makeSharedEntryReference(data => data.unit.type.material.text),
  file: makeSharedEntryReference(data => data.unit.type.material.file),
  video: makeSharedEntryReference(data => data.unit.type.material.video),
};

export const projectStatusReference = {
  0: makeSharedEntryReference(data => data.project.statusText[0]),
  1: makeSharedEntryReference(data => data.project.statusText[1]),
  2: makeSharedEntryReference(data => data.project.statusText[2]),
  4: makeSharedEntryReference(data => data.project.statusText[4]),
  8: makeSharedEntryReference(data => data.project.statusText[8]),
  24: makeSharedEntryReference(data => data.project.statusText[24]),
  32: makeSharedEntryReference(data => data.project.statusText[32]),
};

export const coursePageTabReferences = {
  cover: makeSharedEntryReference(data => data.course.tabs.cover),
  track: makeSharedEntryReference(data => data.course.tabs.track),
  progress: makeSharedEntryReference(data => data.course.tabs.progress),
  projects: makeSharedEntryReference(data => data.course.tabs.projects),
  results: makeSharedEntryReference(data => data.course.tabs.results),
  conclusions: makeSharedEntryReference(data => data.course.tabs.conclusions),
  blog: makeSharedEntryReference(data => data.course.tabs.blog),
  members: makeSharedEntryReference(data => data.course.tabs.members),
};
