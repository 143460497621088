import React, { useCallback } from 'react';

import { block } from 'utils/classname';

import * as Chip from '../../../Chip';
import { Props as TextInputProps } from '../../Component';
import './style.scss';

const b = block('text-input-clear-button');

type Props = Pick<TextInputProps, 'formElementState'> & {
  useIsVisible?(): void;
  onClick?(): void;
};

const Label = Chip.makeLabel({
  useLabel: () => '',
});

function ClearButton({ formElementState, useIsVisible, onClick }: Props) {
  const textInputValue = formElementState.units.value.useState();

  const visible = useIsVisible?.() ?? textInputValue !== '';

  const handleClick = useCallback(() => {
    formElementState.units.value.setState('');

    onClick?.();
  }, [formElementState, onClick]);

  return (
    <div className={b({ visible })}>
      <Chip.Component
        Label={Label}
        variant="text"
        color="primary"
        size="s"
        onDelete={handleClick}
      />
    </div>
  );
}

export const Component = React.memo(ClearButton) as typeof ClearButton;

export const makeComponent = (props: Props) => () => <Component {...props} />;
