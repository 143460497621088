import React from 'react';

import { Chip } from 'components';
import { block } from 'utils/classname';

import { Item } from '../../types';
import { getItemLabel } from '../../utils';
import { ProvidedProps } from '../types';

const b = block('autocomplete-chip-value-item');

type Props<T extends Item> = ProvidedProps<T>;

function ChipValueItem<T extends Item>({ item, onDelete }: Props<T>) {
  return (
    <Chip.Component
      className={b()}
      Label={Chip.makeLabel({
        useLabel: () => getItemLabel(item),
      })}
      variant="filled"
      color="primary"
      onDelete={onDelete}
    />
  );
}

export const Component = React.memo(ChipValueItem) as typeof ChipValueItem;
