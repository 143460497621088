import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'ProjectBookHistoryPage',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-book-history-page" */
      './Component'
    ),
  i18nSharedReferences: [
    ...Object.values(i18nSharedReferences.statusReferences),
    ...Object.values(i18nSharedReferences.stageStatusReferences),
    ...Object.values(i18nSharedReferences.navigationReferences),
    ...Object.values(i18nSharedReferences.passportFieldsReferences),
    ...Object.values(i18nSharedReferences.stageSectionTitleReferences),
  ],
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export const Component = feature.Component;
