import parse, { attributesToProps, domToReact } from 'html-react-parser';

import * as Link from 'components/Link';

type Options = {
  linkSize: Link.Size;
  useOnlyTargetProps?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

const defaultOptions: Options = { linkSize: 'l' };

export function parseHTML(
  html: string,
  { linkSize, useOnlyTargetProps, onClick }: Options = defaultOptions,
) {
  return parse(html, {
    replace: domNode => {
      if ('attribs' in domNode && domNode.tagName === 'a') {
        const props = attributesToProps(domNode.attribs);

        return (
          <Link.Component
            href={props.href}
            target={props.target}
            rel={props.rel}
            size={linkSize}
            color="accent-2"
            weight="normal"
            useOnlyTargetProps={useOnlyTargetProps}
            onClick={onClick}
          >
            {domToReact(domNode.children, domNode.attribs)}
          </Link.Component>
        );
      }
    },
  });
}
