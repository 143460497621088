import React, { useEffect, useMemo } from 'react';

import { ErrorMessage } from 'components';
import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { AudioQuestionForm } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';

type Props = {
  data: M.SoundQuestion;
  num: number;
  kind: Kind;
  type: Type;
  initialValue?: string | null;
  score: { max: number; result: number } | null;
};

export const stateUnit = makePrimaryUnit<
  Record<string, FormElementState<string | null>>
>({});

function AudioQuestion({ data, num, type, initialValue = null, kind }: Props) {
  const validators = useMemo(
    () => (!data.optional ? [nonNull(questionIsRequired)] : []),
    [data.optional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    initialValue,
    validators,
  });

  const value = formElementState.units.value.useState();
  const error = formElementState.units.error.useState();

  const t = I18n.useGetMultilingProjectTranslation();

  const title = t(data.title);
  const description = t(data.description);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  return (
    <QuestionLayout.Component
      title={title}
      description={description}
      image={data.image?.large}
      type={type}
      num={num}
      variant={value ? 'contained' : 'outlined'}
      isOptional={data.optional}
      isEmpty={kind === 'view' && value === null}
      isAutocomplete={data.visibility === QUESTION_VISIBILITY.disabled}
    >
      <AudioQuestionForm.Component
        formElementState={formElementState}
        questionUUID={data.uuid}
        kind={kind}
      />
      <ErrorMessage.Component rows={1} messageReference={error} />
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(AudioQuestion) as typeof AudioQuestion;
