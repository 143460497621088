import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const isRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const invalidURLSyntax = makeSharedEntryReference(
  data => data.validationErrors.invalidURLSyntax,
);

export const axisDataLatitudeReference = makeSharedEntryReference(
  data => data.axisData.kinds.latitude.text,
);

export const axisDataLongtitudeReference = makeSharedEntryReference(
  data => data.axisData.kinds.longtitude.text,
);

export const axisDataQuantityReference = makeSharedEntryReference(
  data => data.axisData.kinds.quantity.text,
);

export const axisDataQuestionnaireSubmitDateReference =
  makeSharedEntryReference(data => data.joinButtonLabel);

export const someError = makeSharedEntryReference(data => data.someError);

export const inviteButtonLabel = makeSharedEntryReference(
  data => data.inviteButtonLabel,
);

export const payWallPrivateProjectTextReference = makeSharedEntryReference(
  data => data.payWallTexts.createPrivateProject,
);

export const payWallPublicProjectTextReference = makeSharedEntryReference(
  data => data.payWallTexts.limitPublicationProject,
);

export const materialUnitTypeReference = {
  link: makeSharedEntryReference(data => data.unit.type.material.link),
  text: makeSharedEntryReference(data => data.unit.type.material.text),
  file: makeSharedEntryReference(data => data.unit.type.material.file),
  video: makeSharedEntryReference(data => data.unit.type.material.video),
};
