import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  ticket?: M.UUID;
  login?: M.UserProfile['login'];
};

export const userProfile = makeService<InputData, M.UserProfile>(
  'user_profile',
  'post',
);
