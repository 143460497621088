import * as R from 'ramda';

import * as M from 'types/serverModels';

import { FilterStateForRequest } from './types';

const getTime = (date: Date | null) => {
  const ms = date?.getTime();
  if (ms !== undefined) {
    return Math.floor(ms / 1000);
  }
};

export function makeServerFilter({
  filterByQuestions,
  submitDateFromState,
  submitDateToState,
  locationState,
  groupState,
  usersState,
}: FilterStateForRequest): M.Filter | null {
  const filter = R.pickBy(x => x !== null, {
    answers:
      filterByQuestions && Object.keys(filterByQuestions).length > 0
        ? filterByQuestions
        : null,
    date:
      submitDateFromState !== null || submitDateToState !== null
        ? {
            from: getTime(submitDateFromState),
            to: getTime(submitDateToState),
          }
        : null,
    location: locationState,
    group: groupState,
    user: usersState,
  }) as M.Filter;

  if (Object.keys(filter).length === 0) {
    return null;
  }

  return filter;
}
