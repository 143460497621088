import { makeService } from './utils';

type InputData = {
  ticket: string;
  password: string;
};

export const userPasswordChange = makeService<InputData, unknown>(
  'user_password_change',
  'post',
);
