import React, { useMemo, useEffect } from 'react';
import { Route } from 'react-router';
import { validate } from 'uuid';

import { ConclusionsRouteTree } from 'pages/routeTree';
import { projectLangsUnit } from 'shared/stateUnits';

import * as Conclusion from './Conclusion';
import * as Conclusions from './Conclusions';
import * as Discussion from './Discussion';
import * as General from './General';
import * as Investigation from './Investigation';
import * as Members from './Members';
import * as Questionnaire from './Questionnaire';
import * as QuestionnaireView from './QuestionnaireView';
import * as Quiz from './Quiz';
import * as Results from './Results';
import * as features from './features';

type Props = Omit<features.View.Props, 'Content'>;

//TIP: The same in features/project/view/Navigation and features/project/view/Modal
function Content({ project, projectPageRouteTree }: Props) {
  const conclusionsRouteTree: ConclusionsRouteTree = useMemo(() => {
    return {
      ...projectPageRouteTree,
      tree: projectPageRouteTree.tree.PROJECT.conclusions,
    };
  }, [projectPageRouteTree]);

  return (
    <>
      <Route path={General.path}>
        <General.Component
          project={project}
          kind="full"
          projectPageRouteTree={projectPageRouteTree}
        />
      </Route>
      <Route path={Investigation.path} exact>
        <Investigation.Component
          project={project}
          projectPageRouteTree={projectPageRouteTree}
        />
      </Route>
      <Route path={Results.path}>
        <Results.Component
          project={project}
          projectPageRouteTree={projectPageRouteTree}
        />
      </Route>
      {typeof project.conclusions === 'string' &&
        validate(project.conclusions) && (
          <Route path={Conclusions.path} exact>
            <Conclusions.Component
              conclusionsUUID={project.conclusions}
              conclusionsRouteTree={conclusionsRouteTree}
              project={project}
            />
          </Route>
        )}
      {/* <Route path={Blog.path} exact>
        <Blog.Component
          project={project['uuid']}
          projectPageRouteTree={projectPageRouteTree}
        />
      </Route>
      <Route path={BlogMessage.path} exact>
        <BlogMessage.Component
          project={project['uuid']}
          projectPageRouteTree={projectPageRouteTree}
        />
      </Route> */}
      <Route path={Discussion.path} exact>
        <Discussion.Component project={project} />
      </Route>
      <Route path={Members.path} exact>
        <Members.Component project={project['uuid']} />
      </Route>
    </>
  );
}

function Page({ project, projectPageRouteTree }: Props) {
  const conclusionsRouteTree: ConclusionsRouteTree = useMemo(() => {
    return {
      ...projectPageRouteTree,
      tree: projectPageRouteTree.tree.PROJECT.conclusions,
    };
  }, [projectPageRouteTree]);

  const quizRouteTree: ConclusionsRouteTree = useMemo(() => {
    return {
      ...projectPageRouteTree,
      tree: projectPageRouteTree.tree.PROJECT.quiz,
    };
  }, [projectPageRouteTree]);

  const kind = features.View.useKind(projectPageRouteTree);

  const groupAccess = useMemo(() => {
    if (project.private) {
      return project.group_access || [];
    }
    return;
  }, [project]);

  useEffect(() => {
    projectLangsUnit.setState(project.langs || []);
  }, [project.langs]);

  useEffect(() => {
    return () => {
      features.View.statisticCallStateUnit.resetState();
    };
  }, []);

  if (kind === 'form' || kind === 'form-view') {
    return (
      <>
        <Route path={QuestionnaireView.path} exact>
          <QuestionnaireView.Component
            project={project}
            projectPageRouteTree={projectPageRouteTree}
          />
        </Route>
        <Route path={Questionnaire.path} exact>
          <Questionnaire.Component
            project={project}
            projectPageRouteTree={projectPageRouteTree}
          />
        </Route>
      </>
    );
  }

  if (kind === 'conclusion-form') {
    const isIncludesConclusions =
      typeof project.conclusions === 'string' && validate(project.conclusions);

    if (!isIncludesConclusions) {
      return null;
    }
    return (
      <Route path={Conclusion.path} exact>
        <Conclusion.Component
          conclusionsRouteTree={conclusionsRouteTree}
          title={project.title}
          uuid={project.uuid}
          groupAccess={groupAccess}
          objectUUID={project.conclusions!}
          kind="conclusions"
          status={project.status}
          authors={project.author}
          supervisors={project.supervisor}
          projectCharge={project.charge}
        />
      </Route>
    );
  }

  if (kind === 'quiz-form') {
    return (
      <Route path={Quiz.path} exact>
        <Quiz.Component
          conclusionsRouteTree={quizRouteTree}
          uuid={project.uuid}
          kind="quiz"
        />
      </Route>
    );
  }

  return (
    <features.Page.Component
      Content={Content}
      project={project}
      projectPageRouteTree={projectPageRouteTree}
    />
  );
}

export const Component = React.memo(Page) as typeof Page;
