import { makeSharedEntryReference } from 'services/I18n';

export const requiredFieldReference = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const invalidURLSyntaxReference = makeSharedEntryReference(
  data => data.validationErrors.invalidURLSyntax,
);

export const addButtonLabelReference = makeSharedEntryReference(
  data => data.addButtonLabel,
);

export const editButtonLabelReference = makeSharedEntryReference(
  data => data.editButtonLabel,
);

export const deleteButtonLabelReference = makeSharedEntryReference(
  data => data.deleteButtonLabel,
);

export const doneButtonLabelReference = makeSharedEntryReference(
  data => data.doneButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);
