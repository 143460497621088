import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'PresentationRichStatistics',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "presentation-rich-statistics" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
  prefetched: true,
});

export type { Props } from './Component';

export const Component = feature.Component;
