import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.UUID;
};

export const commentDelete = makeService<InputData, unknown>(
  'comment_delete',
  'post',
);
