import { pathToRegexp } from 'path-to-regexp';

import { routeTree } from 'pages/routeTree';
import * as Routing from 'services/Routing';
import * as TS from 'types';
import { makePrimaryUnit } from 'utils/State';

import { languages } from './constants';

function getURLLang() {
  if (process.env.BUILD_TARGET === 'server') {
    return;
  }

  const re = pathToRegexp(
    `${process.env.RAZZLE_PATH_BASENAME}${routeTree.LANG.getPath()}/:rest*`,
  );
  const match = re.exec(window.location.pathname);

  if (match === null) {
    return;
  }

  const [, URLLang] = match;

  const lang = languages.find(l => l === URLLang);

  return lang;
}

export const activeLangStateUnit = makePrimaryUnit<TS.Language>(
  getURLLang() || languages[0],
);

if (process.env.BUILD_TARGET === 'client') {
  activeLangStateUnit.subscribe({
    name: 'path-changer',
    callback: lang => {
      const history = Routing.getHistory();
      const location = Routing.getCurrentLocation();

      if (!history) {
        console.error('history not initialized');

        return;
      }

      if (!location) {
        console.error('location not initialized');

        return;
      }

      const re = pathToRegexp(routeTree.LANG.getPath() + '/:rest*');
      const match = re.exec(location.pathname);

      if (match === null) {
        console.warn('unexpected path on language set');
      } else {
        const [, , rest] = match;

        const restPath = rest !== undefined ? `/${rest}` : '';

        history.replace(
          `/${lang}${restPath}${location.search}${location.hash}`,
        );
      }
    },
  });

  Routing.subscribeLocationChange(() => {
    const URLLang = getURLLang();

    if (URLLang !== undefined && URLLang !== activeLangStateUnit.getState()) {
      activeLangStateUnit.setState(URLLang);
    }
  });
}
