import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import {
  AbstractStateUnit,
  PrimaryStateUnit,
  makeDerivedUnit,
} from 'utils/State';

import * as AnswerVariant from '../AnswerVariant';
import { Question } from './types';

type Args = {
  question: M.Question;
  variantSelectionQuestionIDToAnswerVariants?: Record<
    string,
    PrimaryStateUnit<AnswerVariant.AnswerVariant[]>
  >;
  langUnit: AbstractStateUnit<TS.Language>;
};

export function makeQuestionFromServerQuestion({
  question,
  variantSelectionQuestionIDToAnswerVariants,
  langUnit,
}: Args): Question {
  const id = question.uuid;

  // NOTE variants mapping comes from constructor,
  // in project view we should change questions' text according to selected lang
  const makeVariants = (variants: M.Variant[]) =>
    makeDerivedUnit(I18n.activeLangStateUnit).getUnit(lang =>
      variants.map(x =>
        AnswerVariant.makeVariantFromServerVariant({
          variant: x,
          getMultilingTranslation: I18n.makeGetMultilingTranslation(lang),
        }),
      ),
    );

  const name = makeDerivedUnit(langUnit).getUnit(lang =>
    I18n.getOptionalMultilingTranslation(lang, question.title),
  );

  switch (question.type) {
    case 'number':
      return {
        kind: 'with-number',
        id,
        name,
      };
    case 'single_choice':
      return {
        kind: 'with-single-variant',
        id,
        name,
        variants:
          variantSelectionQuestionIDToAnswerVariants?.[question.uuid] ??
          makeVariants(question.variants),
      };
    case 'multi_choice':
      return {
        kind: 'with-multiple-variants',
        id,
        name,
        variants:
          variantSelectionQuestionIDToAnswerVariants?.[question.uuid] ??
          makeVariants(question.variants),
      };
    default:
      throw Error(`bad question type ${question.type}`);
  }
}
