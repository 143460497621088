import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';

import { VariantSelectionQuestionForm } from '../../subfeatures';

const makeVariantProperties = (
  variant: M.Variant,
  lang: TS.Language,
): VariantSelectionQuestionForm.VariantProperties => {
  return {
    id: variant.uuid,
    text: variant.title
      ? I18n.getMultilingTranslation(lang, variant.title)
      : '',
    explanation: variant.description
      ? I18n.getMultilingTranslation(lang, variant.description)
      : '',
    imageURL: variant.image?.icon || null,
  };
};

export const makeElementModels = (
  variant: M.Variant,
  lang: TS.Language,
  initialText?: string,
): VariantSelectionQuestionForm.SingleChoiceElementModel => {
  return variant.custom
    ? {
        kind: 'own',
        textState: makeFormElementState(initialText || ''),
        properties: makeVariantProperties(variant, lang),
      }
    : {
        kind: 'regular',
        properties: makeVariantProperties(variant, lang),
      };
};
