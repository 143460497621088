import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: M.Project;
};

export const projectWrite = makeService<InputData, M.Project>(
  'project_write',
  'post-json',
);
