import { useMemo } from 'react';

import * as TS from 'types';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { PrimaryStateUnit } from 'utils/State';

type Args<T extends FormElementState<K>, K> = {
  uuid: string;
  stateUnit: PrimaryStateUnit<Record<string, T>>;
  validators?: TS.SingleUnitValidator[];
  initialValue: K;
};

export const useFormElementState = <T extends FormElementState<K>, K>({
  uuid,
  stateUnit,
  validators,
  initialValue,
}: Args<T, K>) => {
  const state = stateUnit.useState();
  return useMemo(() => {
    if (state[uuid]?.units.value.initialState === initialValue) {
      return state[uuid];
    }
    return makeFormElementState(initialValue, validators);
  }, [initialValue, state, uuid, validators]);
};
