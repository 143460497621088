import React, { useEffect } from 'react';

import { Chart, Preloader } from 'components';
import { API, I18n } from 'services';
import { block } from 'utils/classname';

import i18nData from '../../../i18n.json';
import './style.scss';

const b = block('project-view-question-sensor-data');

type Props = {
  initialValue: string;
};

function View({ initialValue }: Props) {
  const text = I18n.useText(i18nData);

  const callStateUnit = API.services.getExperiment.useCallStateUnit();
  const callState = callStateUnit.useState();

  const call = API.services.getExperiment.useCall(callStateUnit);

  useEffect(() => {
    const [experiment, position] = initialValue.split(';');

    call({
      experiment,
      position: Number(position),
    });
  }, [call, initialValue]);

  return (
    <div className={b()}>
      {callState.kind === 'pending' && <Preloader.Component />}
      {callState.kind === 'successful' && (
        <>
          {callState.data.data.length > 1 ? (
            <>
              <Chart.Component
                data={{
                  type: 'line',
                  series: [
                    {
                      data: callState.data.data.map((x, index) => ({
                        x: index,
                        y: x,
                        name: String(index),
                      })),
                    },
                  ],
                }}
              />
              <p className={b('range')}>
                <span className={b('min')}>
                  {text.minLabel}: {callState.data.agg.min.toFixed(2)}
                </span>
                <span className={b('avg')}>
                  {text.avgLabel}: {callState.data.agg.avg.toFixed(2)}
                </span>
                <span className={b('max')}>
                  {text.maxLabel}: {callState.data.agg.max.toFixed(2)}
                </span>
              </p>
              <p className={b('date')}>
                {text.dateLabel}:{' '}
                {new Date(callState.data.date * 1000).toLocaleDateString()}
              </p>
            </>
          ) : (
            <span className={b('text')}>{callState.data.data[0]}</span>
          )}
        </>
      )}
    </div>
  );
}
export const Component = React.memo(View) as typeof View;
