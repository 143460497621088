import * as R from 'ramda';
import { useEffect, useState } from 'react';

import { makeLogger } from 'utils/Logger';
import { useIsMountedRef } from 'utils/react';

import { isStateUnit } from '../isStateUnit';
import {
  AbstractStateUnit,
  MappedState,
  MappingUnit,
  MappedDeepState,
  StateSubscriber,
  UnitDebugData,
} from '../types';
import { initializeInput } from './initializeInput';

export type MappedStateContainer<
  T,
  V extends MappedState<T> | MappedDeepState<T>,
> = {
  value: V;
};

// NOTE we notify after callstack clearing to prevent multiple
// notifications within one state change
const makeNotifierCallback = (worker: () => void) => {
  let notificatorIsAddedToQueue: boolean = false;

  return () => {
    if (!notificatorIsAddedToQueue) {
      notificatorIsAddedToQueue = true;
      setTimeout(() => {
        worker();
        notificatorIsAddedToQueue = false;
      }, 0);
    }
  };
};

function getStateFromInput<
  T,
  D extends boolean,
  S = D extends true ? MappedDeepState<T> : MappedState<T>,
>(input: T, { deep }: { deep?: D } = {}): S {
  function loop(node: any, path: Array<number | string>, result: any): S {
    if (isStateUnit(node)) {
      const state = node.getState();

      if (deep && typeof state === 'object') {
        if (Object.keys(state).length === 0) {
          return R.set(R.lensPath(path), state, result);
        }

        if (Array.isArray(state)) {
          return state.reduce(
            (acc, x, index) => loop(x, [...path, index], acc),
            result,
          );
        }

        return Object.entries(state).reduce(
          (acc, [key, value]) => loop(value, [...path, key], acc),
          result,
        );
      }

      return R.set(R.lensPath(path), state, result);
    }

    if (
      node === null ||
      node === undefined ||
      typeof node === 'string' ||
      typeof node === 'number' ||
      typeof node === 'bigint' ||
      typeof node === 'function' ||
      typeof node === 'boolean' ||
      node instanceof Date
    ) {
      return R.set(R.lensPath(path), node, result);
    }

    if (typeof node === 'object') {
      if (Object.keys(node).length === 0) {
        return R.set(R.lensPath(path), node, result);
      }

      if (Array.isArray(node)) {
        return node.reduce(
          (acc, x, index) => loop(x, [...path, index], acc),
          result,
        );
      }

      return Object.entries(node).reduce(
        (acc, [key, value]) => loop(value, [...path, key], acc),
        result,
      );
    }

    console.warn('unexpected node', node);
    return result;
  }

  return loop(input, [], Array.isArray(input) ? [] : {});
}

export function makeMappingUnit<
  T,
  D extends boolean = false,
  S extends MappedDeepState<T> | MappedState<T> = D extends true
    ? MappedDeepState<T>
    : MappedState<T>,
>(
  input: T,
  { deep, debugData }: { deep?: D; debugData?: UnitDebugData } = {},
): MappingUnit<S> {
  const { name, debugMode = true } = debugData || {
    name: 'unnamed-mapping-unit',
    debugMode: false,
  };
  const { log } = makeLogger(name, debugMode);
  let subscribers: Array<StateSubscriber<S>> = [];

  const subscribe = (subscriber: StateSubscriber<S>) => {
    subscribers.push(subscriber);

    return () => {
      subscribers = subscribers.filter(x => x !== subscriber);
    };
  };

  const subscribeInUseState = (
    subscriber: StateSubscriber<S>,
    initializedState: S,
  ) => {
    if (initializedState !== container.value) {
      subscriber.callback(container.value, Symbol('not-implemented') as any);
    }

    return subscribe(subscriber);
  };

  const notifySubscribers = () => {
    log('notify', container.value, subscribers);
    subscribers.forEach(subscriber => {
      subscriber.callback(container.value, Symbol('not-implemented') as any);
    });
  };

  const container = initializeInput<T, D, S>(
    input,
    makeNotifierCallback(notifySubscribers),
    { debugName: name, deep },
  );
  log('initialized container', container);

  const initialState = R.clone(container.value);

  const getState = () => getStateFromInput<T, D, S>(input, { deep });

  return {
    kind: 'mapping',
    subscribe,
    getState,
    initialState,
    isStateUnit: true,
    useState: (debugName: string = 'not-specified') => {
      const [state, setState] = useState<S>(container.value!);

      useEffect(
        () =>
          subscribeInUseState({ callback: setState, name: debugName }, state),
        [debugName, subscribeInUseState],
      );

      return state;
    },
  };
}

export function makeMappingUnitFromUnit<
  T,
  D extends boolean = false,
  S extends MappedDeepState<T> | MappedState<T> = D extends true
    ? MappedDeepState<T>
    : MappedState<T>,
>(
  unit: AbstractStateUnit<T>,
  { deep, debugData }: { deep?: D; debugData?: UnitDebugData } = {},
): MappingUnit<S> {
  const { name, debugMode = true } = debugData || {
    name: 'not-specified',
    debugMode: false,
  };

  const { log } = makeLogger(name, debugMode);

  const input = unit.getState();

  let mappingUnit = makeMappingUnit<T, D, S>(input, {
    deep,
    debugData: {
      name: `${name}-inner`,
      debugMode,
    },
  });
  const initialState = mappingUnit.initialState;

  let subscribers: Array<StateSubscriber<S>> = [];

  const subscribe = (subscriber: StateSubscriber<S>) => {
    subscribers.push(subscriber);

    return () => {
      subscribers = subscribers.filter(x => x !== subscriber);
    };
  };

  const notify = (mappedState: S) => {
    log('notify', mappedState, subscribers);
    subscribers.forEach(subscriber => {
      subscriber.callback(mappedState, Symbol('not-implemented') as any);
    });
  };

  let unsubscribeFromPrev = mappingUnit.subscribe({
    name,
    callback: notify,
  });

  unit.subscribe({
    name,
    callback: state => {
      unsubscribeFromPrev();
      mappingUnit = makeMappingUnit(state, {
        deep,
        debugData: {
          name: `${name}-inner`,
          debugMode,
        },
      });
      unsubscribeFromPrev = mappingUnit.subscribe({
        name,
        callback: state => {
          log('about to notify on changed mappingUnit subscription', state);
          notify(state);
        },
      });

      log(
        'about to notify from on changed mapped unit state',
        state,
        mappingUnit.getState(),
      );
      notify(mappingUnit.getState());
    },
  });

  const getState = () => mappingUnit.getState();

  const subscribeInUseState = (
    subscriber: StateSubscriber<S>,
    initializedState: S,
  ) => {
    const currentState = getState();
    if (initializedState !== currentState) {
      log('notifying unsynced', currentState);
      subscriber.callback(currentState, Symbol('not-implemented') as any);
    }

    return subscribe(subscriber);
  };

  const useUnitState = (debugName: string = 'name-is-not-specified'): S => {
    const [state, setState] = useState<S>(getState);

    const isMountedRef = useIsMountedRef();

    useEffect(
      () =>
        subscribeInUseState(
          {
            name: debugName,
            callback: s => {
              if (isMountedRef) {
                setState(s);
              }
            },
          },
          state,
        ),
      [debugName, subscribeInUseState],
    );

    return state;
  };

  return {
    getState,
    initialState,
    isStateUnit: true,
    kind: 'mapping',
    subscribe,
    useState: useUnitState,
  };
}
