import React from 'react';

import * as TS from 'types';
import { block } from 'utils/classname';

import './style.scss';

const b = block('labeled-control');

export type LabelPosition = 'left' | 'top';

type Props = {
  label: string;
  labelPosition?: LabelPosition;
  labelSize?: TS.TextSize;
  labelColor?: 'fg-1' | 'fg-2' | 'fg-3';
  className?: string;
};

function LabeledControl({
  label,
  labelPosition = 'top',
  labelSize = 'text-16',
  labelColor = 'fg-2',
  className,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div className={b({ 'label-position': labelPosition }, [className])}>
      <div className={b('label', { size: labelSize, color: labelColor })}>
        {label}
      </div>
      {children}
    </div>
  );
}

export const Component = React.memo(LabeledControl);
