import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { makeQuestionIDToQuestionTextUnit } from 'utils/models/Project';

import { Props } from './types';

export const makePropsUnits = (
  project: M.ProjectReadData,
  widget: M.TextsListWidget | M.GalleryWidget | M.TextsCloudWidget,
  types: M.Question['type'][],
) => {
  const propsUnits: Props = {
    questionIDToQuestionTextUnit: makeQuestionIDToQuestionTextUnit(project),
    selectedQuestionIDState: makeFormElementState<string | null>(
      widget.descriptor.questionId,
    ),
    questionIDsUnit: makePrimaryUnit(
      project.questions.filter(x => types.includes(x.type)).map(x => x.uuid),
    ),
  };

  return propsUnits;
};
