import sharedI18nData from 'shared/i18n.json';
import * as TS from 'types';

import { EntryReference, TextOf } from './types';

type ReferenceProxy = {
  getReference(): string[];
};

export function makeReferenceProxy(ancestors: string[] = []): ReferenceProxy {
  return new Proxy(
    {},
    {
      get: (_, propKey) => {
        if (propKey === 'getReference') return () => ancestors;

        return makeReferenceProxy([...ancestors, propKey as string]);
      },
    },
  ) as ReferenceProxy;
}

export type SharedI18nData = typeof sharedI18nData[TS.Language];

export function makeEntryReference<T extends TS.I18nData>(
  data: T,
  callback: (data: TextOf<T[TS.Language]>) => string,
): EntryReference {
  const sourceProxy = makeReferenceProxy() as any;
  const referenceProxy = callback(sourceProxy) as any as ReferenceProxy;

  return {
    data,
    path: referenceProxy.getReference(),
  };
}

export function makeSharedEntryReference(
  callback: (data: TextOf<SharedI18nData>) => string,
): EntryReference {
  return makeEntryReference(sharedI18nData, callback);
}
