import { MathJax } from 'better-react-mathjax';
import React, { useMemo, useCallback, useEffect, useState } from 'react';

import { Modal } from 'components';
import { payWallStateUnit } from 'features/global/PayWall';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';

import {
  quizLabelReference,
  payWallWatchMaterialProjectReference,
} from '../i18nSharedReferences';
import { QuizForm, QuizView } from '../subfeatures';
import './style.scss';

const b = block('unit-action-modal');

export type Props<SearchParam extends string> = {
  searchParamsUnit: PrimaryStateUnit<
    | {
        [searchParam in SearchParam]?: M.Unit['uuid'];
      }
    | null
  >;
  searchParam: SearchParam;
  units: M.Unit[];
  charge?: M.ProjectCharge;
};

function ActionModal<SearchParam extends string>({
  searchParamsUnit,
  searchParam,
  units,
  charge,
}: Props<SearchParam>) {
  const t = I18n.useGetMultilingTranslation();
  const defaultPayWallText = I18n.useReference(
    payWallWatchMaterialProjectReference,
  );

  const isOpenUnit = Modal.useIsOpenUnit();
  const isOpenState = isOpenUnit.useState();
  const [quizUUID, setQuizUUID] = useState<string | null>(null);

  const quizLabel = I18n.useReference(quizLabelReference);

  const callState = QuizForm.callStateUnit.useState();

  const searchParams = searchParamsUnit.useState();

  const data = useMemo(
    () => units.find(x => x.uuid === searchParams?.[searchParam]),
    [units, searchParam, searchParams],
  );

  const Header = useMemo(
    () =>
      Modal.Header.makeFromTitle(() => {
        switch (data?.type) {
          case 'test': {
            return quizLabel;
          }
          default:
            return data?.name || '';
        }
      }),
    [data?.name, data?.type, quizLabel],
  );

  const handleClose = useCallback(() => {
    searchParamsUnit.setState(prevState => {
      if (prevState === null) {
        return null;
      }

      const { [searchParam]: unit, ...newState } = prevState;

      return newState as any;
    });
    setQuizUUID(null);
  }, [searchParamsUnit, searchParam]);

  useEffect(() => {
    isOpenUnit.setState(
      data !== undefined && searchParams?.[searchParam] === data.uuid,
    );
  }, [searchParam, searchParams, data, isOpenUnit]);

  useEffect(() => {
    if (callState.kind === 'successful') {
      setQuizUUID(callState.data.uuid);
    }
  }, [callState]);

  useEffect(() => {
    if (charge && charge.media && isOpenState) {
      const { media, paywall_text_signedin } = charge;
      const payWallFeature = media;
      const payWallText = paywall_text_signedin;

      payWallStateUnit.setState({
        featureCode: payWallFeature,
        blockType: 'watch',
        blockedText:
          payWallText && t(payWallText) ? t(payWallText) : defaultPayWallText,
        onClose: handleClose,
        onHaveNotFeature: handleClose,
      });

      return () => {
        if (!isOpenState) payWallStateUnit.resetState();
      };
    }
  }, [charge, defaultPayWallText, handleClose, isOpenState, t]);

  if (data === undefined) {
    return null;
  }

  return (
    <Modal.Component
      className={b()}
      Header={Header}
      isOpenUnit={isOpenUnit}
      onClose={handleClose}
      size="m"
    >
      <div className={b('content')}>
        {data.type === 'video' && (
          <video className={b('video')} controls preload="auto">
            {data.video &&
              Object.entries(data.video).map(([key, src]) => (
                <source key={key} src={src} type={`video/${key}`} />
              ))}
          </video>
        )}
        {data.type === 'web' && data.text && (
          <MathJax dynamic>
            <div className={b('web')}>{parseHTML(data.text)}</div>
          </MathJax>
        )}
        {data.type === 'test' && (
          <>
            {!quizUUID && (
              <QuizForm.Component
                objectUUID={data.quiz_uuid}
                kind="quiz"
                containerKind="modal"
              />
            )}
            {quizUUID && (
              <QuizView.Component objectUUID={quizUUID} kind="quiz" />
            )}
          </>
        )}
      </div>
    </Modal.Component>
  );
}

export const Component = React.memo(ActionModal) as typeof ActionModal;
