import React from 'react';

import { capitalize } from 'utils/string';

import { DeviceShort } from './types';
import { shortUnit } from './unit';

type DeviceComponents<T> = {
  Mobile: React.FC<T>;
  Desktop: React.FC<T>;
};

export function makeComponent<T>(deviceComponents: DeviceComponents<T>) {
  return (props: T) => {
    const device = shortUnit.useState();

    const Component =
      deviceComponents[capitalize(device) as Capitalize<DeviceShort>];

    return <Component {...props} />;
  };
}
