import { formatISODuration, intervalToDuration } from 'date-fns';
import { useCallback } from 'react';

import * as M from 'types/serverModels';

import { useGetFormattedDate } from './useGetFormattedDate';

type Options = {
  from: M.Datetime | Date | number | null;
  to: M.Datetime | Date | number | null;
};

export function useGetFormattedRangeDate() {
  const getFormattedDate = useGetFormattedDate();

  return useCallback(
    ({ from, to }: Options) => {
      const duration =
        from !== null && to !== null
          ? intervalToDuration({
              start: new Date(from),
              end: new Date(to),
            })
          : undefined;

      return {
        formattedText: `${getFormattedDate(
          from,
        )}\u00A0—\u00A0${getFormattedDate(to)}`,
        datetime: duration && formatISODuration(duration),
      };
    },
    [getFormattedDate],
  );
}
