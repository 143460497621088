import { format } from 'date-fns';
import { useCallback } from 'react';

import * as TS from 'types';

import { activeLangStateUnit } from './activeLangStateUnit';
import { datePlaceholders } from './constants';

// TODO such pattern is declared in multiple places, use one
const formats: Record<TS.Language, string> = {
  en: 'MM/dd/yyyy',
  es: 'MM/dd/yyyy',
  ru: 'dd.MM.yyyy',
};

export function getFormattedDate(
  lang: TS.Language,
  date: string | number | Date | null | undefined,
) {
  if (date === null || date === undefined || date === '') {
    return datePlaceholders[lang];
  }

  return format(new Date(date), formats[lang]);
}

export function useGetFormattedDate() {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (date: string | number | Date | null | undefined) => {
      return getFormattedDate(lang, date);
    },
    [lang],
  );
}
