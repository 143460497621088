import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  lang: TS.Language;
  bbox: string;
  resolution: number;
  filter?: {
    phrase?: string;
    types?: M.SchoolGroupsType[];
  };
};

function convertCommunityMapData(data: M.GroupSchoolMap): M.CommunityMapData[] {
  const clusters = data.clusters.map<M.CommunityMapGroupsClusterData>(x => ({
    ...x,
    kind: 'groups-cluster',
  }));

  const groups = data.groups.map<M.CommunityMapGroupData>(x => ({
    ...x,
    kind: 'group',
  }));

  return [...clusters, ...groups];
}

export const groupSchoolMap = makeService<
  InputData,
  M.GroupSchoolMap,
  M.CommunityMapData[]
>('group_school_map', 'get', true, convertCommunityMapData);
