import React, { useMemo, useCallback } from 'react';
import SwiperCore, { Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import { block } from 'utils/classname';

import './style.scss';

const b = block('carousel');

type Props = Omit<Swiper, 'children'> & {
  slides: React.ReactNode[];
};

SwiperCore.use([Pagination, Keyboard]);

const updateOnInit: Exclude<Swiper['onSwiper'], undefined> = swiper => {
  setTimeout(() => {
    // https://github.com/nolimits4web/swiper/issues/4032#issuecomment-874442837
    swiper.update();
  }, 2000);
};

function Carousel({ slides, className, ...swiperProps }: Props) {
  const { onSwiper } = swiperProps;

  const slideElements = useMemo(() => {
    return slides.map((slide, i) => {
      return <SwiperSlide key={i}>{slide}</SwiperSlide>;
    });
  }, [slides]);

  const handleSwiperInit: Swiper['onSwiper'] = useCallback(
    swiper => {
      updateOnInit(swiper);
      onSwiper?.(swiper);
    },
    [onSwiper],
  );

  return (
    <div className={b({}, [className])}>
      <Swiper
        className={b('swiper')}
        slidesPerView="auto"
        pagination={{
          clickable: true,
        }}
        keyboard={{
          enabled: true,
        }}
        {...swiperProps}
        onSwiper={handleSwiperInit}
      >
        {slideElements}
      </Swiper>
    </div>
  );
}

export const Component = React.memo(Carousel) as typeof Carousel;
