import React from 'react';

import { Page } from 'services';
import * as M from 'types/serverModels';

import { routeTree } from '../routeTree';
import * as features from './features';

type Props = {
  user: M.User;
};

function UserProfile({ user }: Props) {
  return <features.UserProfile.Component user={user} />;
}

export const Component = Page.makePage({
  path: routeTree.LANG.users.USER.profile.getPath(),
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(UserProfile),
  features: Object.values(features).map(x => x.feature),
});
