import React, { forwardRef, useCallback } from 'react';

import { block, classnames } from 'utils/classname';

import './style.scss';

const b = block('icon-container');

export type Size = 'xl' | 'l' | 'm' | 's' | 'm-half' | 's-half';
export type Variant = 'contained' | 'outlined';
export type BackgroundDarknessLevel = '0' | 1 | 2 | 3;

export type Props = {
  size: Size;
  className?: string;
  variant?: Variant;
  backgroundDarknessLevel?: BackgroundDarknessLevel;
} & Pick<
  JSX.IntrinsicElements['div'],
  | 'title'
  | 'tabIndex'
  | 'onClick'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'onMouseMove'
>;

const IconContainer = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function (
  {
    size,
    children,
    className,
    variant = 'contained',
    backgroundDarknessLevel = 1,
    ...restProps
  }: React.PropsWithChildren<Props>,
  ref,
) {
  const handleKeyDown: React.KeyboardEventHandler = useCallback(event => {
    const element = event.target;

    const shouldClick = !event.repeat && event.code === 'Enter';

    if (element instanceof HTMLElement && shouldClick) {
      event.preventDefault();
      element.click();
    }
  }, []);

  return (
    <div
      className={classnames(
        b({
          size,
          variant,
          'background-darkness-level': backgroundDarknessLevel,
        }),
        className,
      )}
      ref={ref}
      onKeyDown={handleKeyDown}
      {...restProps}
    >
      {children}
    </div>
  );
});

export const Component = React.memo(IconContainer) as typeof IconContainer;
