type ScrollableAncestors = HTMLElement | Window;

type ScrollableProperties = {
  [key: string]: boolean;
};

const scrollableProperties: ScrollableProperties = {
  auto: true,
  scroll: true,
};

function isScrollable(element: HTMLElement) {
  const { overflowY, overflowX } = window.getComputedStyle(element);

  const canScrollY = scrollableProperties[overflowY];
  const canScrollX = scrollableProperties[overflowX];

  return canScrollY || canScrollX;
}

export function getScrollableAncestors(
  element: HTMLElement,
): ScrollableAncestors[] {
  function loop(node: HTMLElement, acc: HTMLElement[]): ScrollableAncestors[] {
    if (node.parentElement === null) {
      return [...acc, window];
    }

    if (node.parentElement) {
      const newAcc = isScrollable(node.parentElement)
        ? [...acc, node.parentElement]
        : [...acc];

      return loop(node.parentElement, newAcc);
    }

    console.error('unexpectedly reached end');
    return acc;
  }

  return loop(element, []);
}
