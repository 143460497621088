import * as R from 'ramda';

import * as M from 'types/serverModels';

function minDateStart(a: M.ProjectBookStage, b: M.ProjectBookStage) {
  if (a.date_start === undefined) {
    return 1;
  }

  if (b.date_start === undefined) {
    return -1;
  }

  return Date.parse(a.date_start) - Date.parse(b.date_start);
}

export function getCurrentStage(stages: M.ProjectBook['stages']) {
  if (stages === undefined) {
    return null;
  }

  const onProgressStages = stages.filter(x => x.status === 'onprogress');

  return R.sort(minDateStart, onProgressStages)[0] || null;
}
