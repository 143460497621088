import { makeFeature } from 'services/Feature';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'FullscreenWidgetContainer',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "fullscreen-widget-container" */
      './Component'
    ),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export * as Content from './content';
export * as Title from './title';

export { Component } from './Component';
