import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData =
  | {
      ticket?: M.UUID;
      uuid: M.UUID;
    }
  | {
      ticket?: M.UUID;
      code: string;
    };

export const projectRead = makeService<InputData, M.ProjectReadData>(
  'project_read',
  'get',
);
