import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
} from 'react';
import { createPortal } from 'react-dom';

import { I18n } from 'services';
import * as TS from 'types';
import { getFixedPositionStyle, stickFixedPositionElement } from 'utils/DOM';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { AbstractStateUnit, makeDerivedUnit, getNewState } from 'utils/State';
import { block, classnames } from 'utils/classname';

import * as ErrorMessage from '../ErrorMessage';
import * as c from './components';
import './style.scss';

export { ActiveOption, Option } from './components';

const b = block('select');

export type Props<T> = {
  options: T[];
  activeOptionState: FormElementState<T>;
  ActiveOption?: React.FC<c.Option.Props<T>>;
  Option?: React.FC<c.ActiveOption.Props<T>>;
  disabledUnit?: AbstractStateUnit<boolean>;
  errorRows?: 0 | 1 | 2;
  manualError?: I18n.EntryReference | null;
  className?: string;
  kind?: 'framed' | 'unframed' | 'text';
  onChangePredicate?(option: T, change: (option: T) => void): void;
};

const optionsMargin = 5;
const optionsMaxHeight = 300;

function Select<T>(props: Props<T>) {
  const {
    options,
    activeOptionState,
    className,
    errorRows = 0,
    manualError = null,
    disabledUnit,
    kind = 'framed',
    Option = c.Option.DefaultComponent,
    ActiveOption = c.ActiveOption.DefaultComponent,
    onChangePredicate,
  } = props;

  const { units } = activeOptionState;

  const activeOption = units.value.useState();
  const error = units.error.useState() || manualError;

  const resultingDisabledUnit = useMemo(() => {
    return disabledUnit
      ? makeDerivedUnit(disabledUnit, units.disabled).getUnit(
          (x1, x2) => x1 || x2,
        )
      : units.disabled;
  }, [disabledUnit, units.disabled]);

  const disabled = resultingDisabledUnit.useState() || options.length === 0;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const [optionsStyle, setOptionsStyle] = useState<React.CSSProperties>();

  const updateOptionsStyle = useCallback(() => {
    setOptionsStyle(prev => {
      if (anchorRef.current === null) {
        return prev;
      }

      const anchorRect = anchorRef.current.getBoundingClientRect();

      return {
        ...prev,
        ...getFixedPositionStyle({
          anchorRect,
          margin: optionsMargin,
          defaultMaxHeight: optionsMaxHeight,
        }),
        minWidth: anchorRect.width,
      };
    });
  }, []);

  const handleActiveOptionClick = useCallback(() => {
    if (disabled) {
      return;
    }

    setIsExpanded(prev => !prev);
  }, [disabled]);

  const setActiveOption: c.Option.Container.Dependencies<T>['setActiveOption'] =
    useCallback(
      setOption => {
        const option = getNewState(setOption, units.value.getState());

        const change = (option: T) => {
          units.value.setState(option);
        };

        if (typeof onChangePredicate === 'function') {
          onChangePredicate(option, change);
        } else {
          change(option);
        }
      },
      [units.value, onChangePredicate],
    );

  const handleDocumentBodyClick = useCallback((e: MouseEvent) => {
    if (!(e.target instanceof Node)) {
      return;
    }

    if (anchorRef.current?.contains(e.target)) {
      return;
    }

    setIsExpanded(false);
  }, []);

  useLayoutEffect(() => {
    if (!isExpanded) {
      return;
    }

    updateOptionsStyle();

    document.body.addEventListener('click', handleDocumentBodyClick);

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updateOptionsStyle,
    });

    return () => {
      document.body.removeEventListener('click', handleDocumentBodyClick);

      unsubscribe();

      setOptionsStyle(undefined);

      units.visited.setState(true);
    };
  }, [isExpanded, units.visited, updateOptionsStyle, handleDocumentBodyClick]);

  return (
    <div
      className={classnames(
        b({
          expanded: isExpanded,
          'has-selected-value': activeOption !== null,
          disabled,
          kind,
          'has-error': error !== null,
        }),
        className,
      )}
    >
      <div className={b('anchor')} ref={anchorRef}>
        <c.ActiveOption.Container.DependenciesContext.Provider
          onClick={handleActiveOptionClick}
        >
          <ActiveOption<T> option={activeOption} />
        </c.ActiveOption.Container.DependenciesContext.Provider>
      </div>
      {errorRows !== 0 && (
        <ErrorMessage.Component messageReference={error} rows={errorRows} />
      )}
      {isExpanded &&
        createPortal(
          <div
            className={b('options', { displayed: isExpanded })}
            style={optionsStyle}
          >
            {options.map((x, index) => {
              return (
                <c.Option.Container.DependenciesContext.Provider
                  key={index}
                  option={x}
                  setActiveOption={setActiveOption}
                  setSelectIsExpanded={setIsExpanded}
                >
                  <Option<T> option={x} />
                </c.Option.Container.DependenciesContext.Provider>
              );
            })}
          </div>,
          document.body,
        )}
    </div>
  );
}

export function makeFieldState<T>(
  initialState: T | null = null,
  validators?: TS.Validator[],
) {
  return makeFormElementState(initialState, validators);
}

export function useFormElementState<T>(initialState: T | null = null) {
  return useMemo(() => makeFieldState<T>(initialState), [initialState]);
}

export const Component = React.memo(Select) as typeof Select;
