import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  parent?: string;
  object: string;
  collection: M.Collection;
  for_tutor?: boolean;
  lang: TS.Language;
  tree?: boolean;
};

export const commentGet = makeService<InputData, M.CommentData[]>(
  'comment_get',
  'get',
);
