import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';

import { Page } from 'services';
import crossIconSrc from 'shared/images/cross.svg';
import { emulateClick, stickFixedPositionElement } from 'utils/DOM';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import { defaults } from './constants';
import './style.scss';
import { Size } from './types';

const b = block('modal');
const shadow = block('modal-shadow');

export type Position =
  | PositionOnCenter
  | PositionWithTopRightAttachedToNode
  | PositionWithTopRightAttachedToNodeWithoutArrow;

type PositionOnCenter = {
  kind: 'on-center';
};

type PositionWithTopRightAttachedToNode = {
  kind: 'with-top-right-attached-to-node';
  nodeRef: React.RefObject<HTMLElement>;
};

type PositionWithTopRightAttachedToNodeWithoutArrow = {
  kind: 'with-top-right-attached-to-node-without-arrow';
  nodeRef: React.RefObject<HTMLElement>;
};

type ClosingMethod =
  | { kind: 'switch'; withoutShadow?: boolean }
  | { kind: 'fold'; nodeRef: React.RefObject<HTMLElement> };

export type Props = {
  isOpenUnit: PrimaryStateUnit<boolean>;
  size: Size;
  Header?: React.FC;
  className?: string;
  position?: Position;
  closingMethod?: ClosingMethod;
  alignCenter?: boolean;
  onClose?(): void;
  portalToElement?: string;
  infoText?: React.ReactNode;
  shadowOptions?: {
    backgroundDarkness?: '80' | '100';
  };
};

function getAttachedStyle(position: Position): React.CSSProperties | undefined {
  switch (position.kind) {
    case 'with-top-right-attached-to-node':
    case 'with-top-right-attached-to-node-without-arrow': {
      if (position.nodeRef.current === null) {
        console.warn('provided an empty ref, can not attach to it');

        return undefined;
      }

      const nodeRect = position.nodeRef.current.getBoundingClientRect();

      const style = (() => {
        switch (position.kind) {
          case 'with-top-right-attached-to-node': {
            const topMargin = 30;

            return { top: nodeRect.bottom + topMargin, left: nodeRect.right };
          }
          case 'with-top-right-attached-to-node-without-arrow': {
            const topMargin = 20;

            return { top: nodeRect.bottom + topMargin, left: nodeRect.right };
          }
        }
      })();

      return style;
    }
    default: {
      return {};
    }
  }
}

function Modal({
  isOpenUnit,
  children,
  size,
  onClose,
  Header,
  className,
  position = defaults.position,
  closingMethod = defaults.closingMethod,
  alignCenter = false,
  portalToElement,
  infoText,
  shadowOptions = defaults.shadowOptions,
}: React.PropsWithChildren<Props>) {
  const isOpen = isOpenUnit.useState();

  const [attachedStyle, setAttachedStyle] = useState<
    React.CSSProperties | undefined
  >();

  const modalRef = useRef<HTMLDivElement>(null);

  const withShadow =
    closingMethod.kind === 'switch' && !closingMethod.withoutShadow;
  const shouldSetPageScroll = withShadow;
  const isPositioned = isOpen && attachedStyle !== undefined;

  Page.useSetScroll(shouldSetPageScroll && isOpen);

  const updateAttachedStyle = useCallback(() => {
    setAttachedStyle(getAttachedStyle(position));
  }, [position]);

  const handleCloseIconClick = useCallback(() => {
    isOpenUnit.setState(false);
    onClose?.();
  }, [isOpenUnit, onClose]);

  const handleCrossIconKeyDown: React.KeyboardEventHandler = useCallback(
    event => {
      emulateClick(event);
    },
    [],
  );

  const handleDocumentClick = useCallback(
    event => {
      switch (closingMethod.kind) {
        case 'fold': {
          if (!(event.target instanceof Node)) {
            return;
          }

          if (
            modalRef.current?.contains(event.target) ||
            closingMethod.nodeRef.current?.contains(event.target)
          ) {
            return;
          }

          isOpenUnit.setState(false);
        }
      }
    },
    [closingMethod, isOpenUnit],
  );

  const handleDocumentKeyDown = useCallback(
    event => {
      switch (closingMethod.kind) {
        case 'fold': {
          const shouldClose = !event.repeat && event.code === 'Escape';

          if (shouldClose) {
            isOpenUnit.setState(false);
          }
        }
      }
    },
    [closingMethod, isOpenUnit],
  );

  useLayoutEffect(() => {
    if (!isOpen) {
      return;
    }

    updateAttachedStyle();

    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('keydown', handleDocumentKeyDown);

    const unsubscribe = !shouldSetPageScroll
      ? stickFixedPositionElement({
          updatePosition: updateAttachedStyle,
        })
      : undefined;

    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('keydown', handleDocumentKeyDown);

      unsubscribe?.();

      setAttachedStyle(undefined);
    };
  }, [
    shouldSetPageScroll,
    isOpen,
    updateAttachedStyle,
    handleDocumentClick,
    handleDocumentKeyDown,
  ]);

  useEffect(() => {
    if (isPositioned) {
      modalRef.current?.focus();
    }
  }, [isPositioned]);

  const portalElement = portalToElement
    ? document.body.querySelector(portalToElement) || document.body
    : document.body;
  return isOpen
    ? ReactDOM.createPortal(
        <>
          {withShadow && (
            <div
              className={shadow({
                'background-darkness': shadowOptions?.backgroundDarkness,
              })}
            />
          )}
          <div
            className={b(
              {
                open: isOpen,
                size,
                position: position.kind,
                'align-center': alignCenter,
              },
              [className],
            )}
            style={attachedStyle}
            tabIndex={-1}
            ref={modalRef}
          >
            {position.kind === 'with-top-right-attached-to-node' && (
              <div className={b('arrow')} />
            )}
            {closingMethod.kind === 'switch' && (
              <img
                src={crossIconSrc}
                className={b('close-icon')}
                alt="close-icon"
                tabIndex={0}
                onClick={handleCloseIconClick}
                onKeyDown={handleCrossIconKeyDown}
              />
            )}
            <div className={b('header')}>{Header && <Header />}</div>
            {/* NOTE: don't add elements inside modal's body: some components relaying on current structure: we should refac - add scrollable part and modal max-height */}
            {infoText && <div className={b('info-message')}>{infoText}</div>}
            {children}
          </div>
        </>,
        portalElement,
      )
    : null;
}

export const Component = React.memo(Modal) as typeof Modal;
