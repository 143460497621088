import * as M from 'types/serverModels';
import { PrimaryStateUnit } from 'utils/State';
import { AnswerVariant } from 'utils/business';

import { makeFilteringQuestionsUnit } from './makeFilteringQuestionsUnit';
import { makeInitialStateFromServerFilter } from './makeInitialStateFromServerFilter';
import { makeState } from './makeState';
import { State } from './types';

export function makeStateFromServerData(
  project: M.ProjectReadData,
  widget: M.Widget,
  variantSelectionQuestionIDToAnswerVariants?: Record<
    string,
    PrimaryStateUnit<AnswerVariant.AnswerVariant[]>
  >,
): State {
  return makeState(
    makeFilteringQuestionsUnit(
      project,
      widget,
      {},
      variantSelectionQuestionIDToAnswerVariants,
    ),
    makeInitialStateFromServerFilter(widget.descriptor.filter ?? {}),
  );
}
