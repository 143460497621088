import * as R from 'ramda';

import * as M from 'types/serverModels';

export function convertToProject(
  projectReadData: M.ProjectReadData,
): M.Project {
  return R.omit(['users_counter', 'conclusions_counter'], {
    ...projectReadData,
    questions: projectReadData.questions.map(x => {
      switch (x.type) {
        case 'probe':
          return R.omit(['_sensors'], x);
        case 'file':
          return R.omit(['extensions'], x);
        default:
          return x;
      }
    }),
  });
}
