import React, { useMemo, useRef } from 'react';

import { CallState } from 'services/API';
import { AbstractStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import * as Preloader from '../Preloader';
import './style.scss';

export type Size = 'xs' | 's' | 'm' | 'l';
export type Variant = 'contained' | 'outlined';
export type Color = 'accent-2' | 'secondary' | 'white';

const b = block('button');

export type Props = React.PropsWithChildren<
  {
    size?: Size;
    className?: string;
    color?: 'accent-2' | 'secondary' | 'white';
    variant?: Variant;
    LeftPart?: React.FC;
    Icon?: React.VFC;
    callStateUnit?: AbstractStateUnit<CallState<any>>;
    callStatePreloaderColor?: Preloader.Color;
    nowrap?: boolean;
  } & Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'onClick' | 'onMouseDown' | 'form' | 'disabled'
  > & { [K in 'type']-?: React.ButtonHTMLAttributes<HTMLButtonElement>[K] }
>;

function Button({
  color = 'accent-2',
  size = 'm',
  onMouseDown,
  children,
  className,
  variant = 'contained',
  LeftPart,
  Icon,
  callStateUnit,
  callStatePreloaderColor,
  disabled,
  nowrap,
  ...props
}: Props) {
  const ref = useRef<HTMLButtonElement>(null);

  const callState = callStateUnit?.useState();

  const shouldShowPreloader = useMemo(
    () => callState?.kind === 'pending',
    [callState],
  );

  return (
    <button
      className={b(
        {
          size,
          variant,
          color,
          nowrap,
        },
        [className],
      )}
      ref={ref}
      disabled={callState?.kind === 'pending' || disabled}
      {...props}
    >
      {Icon && (
        <div className={b('icon')}>
          <Icon />
        </div>
      )}
      {children}
      {shouldShowPreloader && (
        <Preloader.Component
          className={b('preloader')}
          size="xs"
          color={callStatePreloaderColor}
        />
      )}
    </button>
  );
}

export const Component = React.memo(Button);

export * from './icons';
