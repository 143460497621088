import * as R from 'ramda';

import * as TS from 'types';
import * as M from 'types/serverModels';
import { getPowerSet } from 'utils/math';
import { renameKeys } from 'utils/renameKeys';

import { makeService } from './utils';

type APIOutputData = {
  set1?: string[];
  set2?: string[];
  set3?: string[];
  set4?: string[];
};

function convertVennChartDataItem(data: APIOutputData): TS.VennChartDataItem[] {
  const sets = renameKeys(data, { set1: 'A', set2: 'B', set3: 'C', set4: 'D' });
  const powerSet = getPowerSet(Object.keys(sets));

  return powerSet
    .filter(keys => keys.length > 0 && keys.length < 4)
    .map(keys => ({
      sets: keys,
      value: keys.map(x => sets[x]).reduce(R.intersection),
    }));
}

type InputData = M.EmulateParamsHolder & {
  project: string;
  params: string; // JSON string of Omit<M.VennChartWidgetDescriptor, 'filter'>;
  filter: string; // JSON string of Filter;
};

export const dataVenn = makeService<
  InputData,
  APIOutputData,
  TS.VennChartDataItem[]
>('data_venn', 'post', true, convertVennChartDataItem);
