import { makeFeature } from 'services/Feature';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'MajorTabPanel',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "major-tab-panel" */
      './Component'
    ),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export * as TabTextLabel from './TabTextLabel';
export type { LabelProps, Tab } from './types';

export const Component = feature.Component;
