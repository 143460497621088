import React, { useMemo, useCallback } from 'react';
import { SetOptional } from 'type-fest';

import {
  QuestionnaireRouteTree,
  getQuestionnaireRootParams,
} from 'pages/routeTree';
import { I18n, Routing } from 'services';
import * as M from 'types/serverModels';

import { DefaultModal } from '../subfeatures';

export type Props = SetOptional<DefaultModal.Action.Props, 'onClick'> & {
  questionnaireRouteTree: QuestionnaireRouteTree;
  questionnaireUUID: M.QuestionnaireData['uuid'];
};

function QuestionnaireViewAction({
  questionnaireRouteTree,
  questionnaireUUID,
  onClick,
  ...defaultActionProps
}: Props) {
  const lang = I18n.activeLangStateUnit.useState();

  const questionnaireRoute = useMemo(() => {
    return questionnaireRouteTree.tree.form.QUESTIONNAIRE_UUID.getPath({
      routeParams: {
        ...getQuestionnaireRootParams(questionnaireRouteTree),
        LANG: lang,
        QUESTIONNAIRE_UUID: questionnaireUUID,
      },
    });
  }, [questionnaireRouteTree, questionnaireUUID, lang]);

  const handleClick = useCallback(() => {
    const history = Routing.getHistory();

    history?.push(questionnaireRoute);

    onClick?.();
  }, [questionnaireRoute, onClick]);

  return (
    <DefaultModal.Action.Component
      onClick={handleClick}
      {...defaultActionProps}
    />
  );
}

export const Component = React.memo(
  QuestionnaireViewAction,
) as typeof QuestionnaireViewAction;
