import React from 'react';
import { Redirect } from 'react-router';

import { Preloader } from 'components';
import { API } from 'services';
import { Page } from 'services';
import { userStateUnit } from 'shared/stateUnits';
import { substitutes } from 'utils/RouteTree';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as MyCourses from './MyCourses';
import * as MyGroups from './MyGroups';
import * as MyProjectBooks from './MyProjectBooks';
import * as MyProjects from './MyProjects';
import * as Package from './Package';
import * as Perks from './Perks';
import * as Cabinet from './PersonalAccount';
import * as Settings from './Settings';
import * as features from './features';
import './style.scss';

const b = block('personal-account-page');

function PersonalAccount() {
  const userState = userStateUnit.useState();
  const ticket = API.useTicket();

  if (ticket === null && process.env.BUILD_TARGET === 'client') {
    return (
      <Redirect
        to={routeTree.LANG.getPath({
          routeParams: {
            LANG: substitutes.fromLocation,
          },
        })}
      />
    );
  }

  return (
    <div className={b()}>
      <div className={b('side-menu')}>
        <features.PersonalAccountMenu.Component />
      </div>

      <div className={b('card')}>
        {userState.kind !== 'loaded' ? (
          <Preloader.Component size="m" className="preloader" />
        ) : (
          <>
            <Cabinet.Component user={userState.user} />
            <Perks.Component user={userState.user} />
            <MyProjectBooks.Component user={userState.user} />
            <MyProjects.Component user={userState.user} />
            <MyCourses.Component user={userState.user} />
            <MyGroups.Component user={userState.user} />
            <Package.Component user={userState.user} />
            <Settings.Component user={userState.user} />
          </>
        )}
      </div>
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['personal-account'].getPath(),
  routeProps: { exact: false },
  scrollTo: 'none',
  Component: React.memo(PersonalAccount),
  features: Object.values(features).map(x => x.feature),
});
