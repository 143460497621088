/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Routing } from 'services';
import { block, classnames } from 'utils/classname';

import { MobileLinkDependenciesContext } from '../../../../MobileLinkDependencies';
import { ChildEntry as ChildEntryType } from '../../../../types';
import './style.scss';

const b = block('m-main-menu-child-entry');

type Props = {
  childEntry: ChildEntryType;
  className: string;
  bold: boolean;
};

function ChildEntry({ childEntry, className, bold }: Props) {
  switch (childEntry.kind) {
    case 'link':
      const isActive = Routing.useCurrentLocationIsMatchingTo(childEntry.href);
      const { onLinkClick } = useContext(MobileLinkDependenciesContext);

      return (
        <Link
          className={classnames(b({ active: isActive, bold }), className)}
          to={childEntry.href}
          onClick={onLinkClick}
        >
          {childEntry.label}
        </Link>
      );
    case 'action':
      return (
        <div
          className={classnames(b({ bold }), className)}
          onClick={childEntry.onSelect}
        >
          {childEntry.label}
        </div>
      );
  }
}

export const Component = React.memo(ChildEntry) as typeof ChildEntry;
