import React from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import './style.scss';

const b = block('project-view-question-single-choice');

type Props = {
  variant: M.Variant;
  text?: string;
};

function View({ variant, text }: Props) {
  const t = I18n.useGetMultilingProjectTranslation();

  const title = variant.title ? t(variant.title) : '';
  const description = variant.description ? t(variant.description) : '';
  const icon = variant.image?.icon || null;

  return (
    <div className={b()}>
      {icon !== null && <img src={icon} alt={title} className={b('icon')} />}
      <p className={b('content')}>
        <span className={b('title')}>{title}</span>
        {description && <span className={b('description')}>{description}</span>}
        {text && <span className={b('text')}>{text}</span>}
      </p>
    </div>
  );
}
export const Component = React.memo(View) as typeof View;
