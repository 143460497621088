import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    parent?: M.UUID;
    object: M.UUID;
    collection: M.Collection;
    body: string;
    for_tutor?: boolean;
  };
  return_full?: boolean;
};

export const commentAdd = makeService<InputData, M.CommentData>(
  'comment_add',
  'post',
);
