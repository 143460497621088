import * as TS from 'types';

const regularKindsForCuttingQuestion: TS.QuestionKind[] = [
  'single-variant-selection',
  'multiple-variants-selection',
];

const regularKindsForXAxis: TS.QuestionKind[] = [
  'date',
  'single-variant-selection',
  'multiple-variants-selection',
  'number',
  'stream',
];

const regularKindsForYAxis: TS.QuestionKind[] = [
  'single-variant-selection',
  'multiple-variants-selection',
  'number',
  'stream',
];

export const widgetTypeToQuestionKindMapping: TS.WidgetTypeToQuestionKindMapping =
  {
    map: {
      scale: ['number', 'stream'],
      'colored-markers/icons': [
        'single-variant-selection',
        'multiple-variants-selection',
      ],
      'without-settings': [
        'single-line-string',
        'multiline-string',
        'date',
        'image',
        'sound',
        'figures',
        'map',
        'video',
      ],
    },
    chart: {
      line: {
        'x-axis': regularKindsForXAxis,
        'y-axis': regularKindsForYAxis,
        'cutting-question': regularKindsForCuttingQuestion,
      },
      area: {
        'x-axis': regularKindsForXAxis,
        'y-axis': regularKindsForYAxis,
        'cutting-question': regularKindsForCuttingQuestion,
      },
      scatter: {
        'x-axis': regularKindsForXAxis,
        'y-axis': ['date', ...regularKindsForYAxis],
        'cutting-question': regularKindsForCuttingQuestion,
      },
      column: {
        'x-axis': regularKindsForXAxis,
        'y-axis': regularKindsForYAxis,
        'cutting-question': regularKindsForCuttingQuestion,
      },
      'column-stack': {
        'x-axis': regularKindsForXAxis,
        'y-axis': [],
        'cutting-question': regularKindsForCuttingQuestion,
      },
      pie: {
        'x-axis': regularKindsForXAxis,
        'y-axis': [],
        'cutting-question': [],
      },
    },
    gallery: ['image', 'video'],
    textsCloud: ['single-line-string'],
    textsList: ['single-line-string', 'multiline-string'],
    vennChart: {
      'for-multiple-variants': ['multiple-variants-selection'],
      'for-single-variant': ['single-variant-selection'],
      'for-number-question': ['number'],
    },
  };
