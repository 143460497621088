import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as ExtraIcon } from 'shared/images/extra.svg';
import { getFixedPositionStyle, stickFixedPositionElement } from 'utils/DOM';
import { block } from 'utils/classname';

import type * as Button from '../Button';
import './style.scss';

type Variant = 'contained' | 'outlined';
type Size = 's' | 'm' | 'l';

const b = block('extra-menu');

export type Props = {
  Button?: React.VFC<Button.ProvidedProps>;
  className?: string;
  variant?: Variant;
  size?: Size;
  children: React.ReactNode;
};

function ExtraMenu({
  Button,
  className,
  variant = 'outlined',
  size = 'm',
  children,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [optionsStyle, setOptionsStyle] = useState<React.CSSProperties>();

  const ref = useRef<HTMLButtonElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);

  const isPositioned = isExpanded && optionsStyle !== undefined;

  const updateOptionsStyle = useCallback(() => {
    if (ref.current === null) {
      return null;
    }

    setOptionsStyle(
      getFixedPositionStyle({
        anchorRect: ref.current.getBoundingClientRect(),
        margin: 5,
        defaultMaxHeight: 300,
        defaultMinWidth: 150,
      }),
    );
  }, []);

  const handleClick = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  const handleDocumentBodyClick = useCallback((e: MouseEvent) => {
    if (!(e.target instanceof Node)) {
      return;
    }

    if (ref.current?.contains(e.target)) {
      return;
    }

    setIsExpanded(false);
  }, []);

  useLayoutEffect(() => {
    if (!isExpanded) {
      return;
    }

    updateOptionsStyle();

    document.body.addEventListener('click', handleDocumentBodyClick);

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updateOptionsStyle,
    });

    return () => {
      document.body.removeEventListener('click', handleDocumentBodyClick);

      unsubscribe();

      setOptionsStyle(undefined);
    };
  }, [isExpanded, updateOptionsStyle, handleDocumentBodyClick]);

  useEffect(() => {
    if (isPositioned) {
      optionsRef.current?.focus();
    }
  }, [isPositioned]);

  return (
    <>
      {Button ? (
        <Button
          forwardedRef={ref}
          counter={React.Children.toArray(children).length}
          expanded={isExpanded}
          onClick={handleClick}
        />
      ) : (
        <button
          className={b({ variant, size, expanded: isExpanded }, [className])}
          ref={ref}
          onClick={handleClick}
        >
          <ExtraIcon />
        </button>
      )}
      {isExpanded &&
        createPortal(
          <ul
            className={b('list')}
            ref={optionsRef}
            tabIndex={-1}
            style={optionsStyle}
          >
            {children}
          </ul>,
          document.body,
        )}
    </>
  );
}

export const Component = ExtraMenu;
