import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    filter?: {
      substring?: string;
      uuid?: M.Project['uuid'][];
      author?: M.AuthorProfile['login'][];
      supervisor?: M.User['login'][];
      subjects?: M.Project['subjects'];
      targetAges?: M.UUID[];
      keywords?: M.Project['keywords'];
      langs?: TS.Language[];
      cdate?: [lt: M.Datetime, gt: M.Datetime];
      private?: boolean;
      status?: M.Project['status'];
      group_access?: unknown[];
      idea?: unknown;
    };
    sort?: { cdate?: SortDirection; author?: SortDirection };
    limit?: number;
    offset?: number;
  };
};

/**
 * -1 - по убыванию
 * 1 - по возрастанию
 */
type SortDirection = -1 | 1;

type OutputData = OutputProject[] | undefined;

type OutputProject = Pick<
  M.Project,
  | 'about'
  | 'blog_messages_counter'
  | 'code'
  | 'comments_counter'
  | 'features'
  | 'keywords'
  | 'langs'
  | 'likes'
  | 'results_counter'
  | 'status'
  | 'thumb'
  | 'title'
  | 'uuid'
> & {
  author: Record<M.AuthorProfile['login'], M.AuthorProfile>;
  subjects?: Record<M.UUID, M.ProjectListProjectSubject>;
  classifications: M.UUID[];
  group_access: unknown[];
  private: boolean;
  publish_date: M.Datetime;
  stamp: number;
  user_stack: OutputUserStack[];
};

type OutputUserStack = Record<
  M.User['login'],
  Pick<
    M.User,
    | 'location'
    | 'login'
    | 'role'
    | 'score'
    | 'status'
    | 'avatar'
    | 'badge'
    | 'name'
  >
>;

export const projectList = makeService<InputData, OutputData>(
  'project_list',
  'post-json',
);
