import { makeFormElementState } from 'utils/FormState';
import { AbstractStateUnit, makePrimaryUnit } from 'utils/State';

import { makeFilterQuestionsMappingUnit } from './makeFilterQuestionsMappingUnit';
import {
  FilteringQuestion,
  State,
  GroupOption,
  UserOption,
  GroupsAndUsersTab,
  InitialState,
} from './types';

export function makeState(
  filteringQuestionsUnit: AbstractStateUnit<FilteringQuestion[]>,
  initialState: InitialState = {
    submitDateFrom: null,
    submitDateTo: null,
    location: null,
  },
): State {
  const filteringQuestionsMappingUnit = makeFilterQuestionsMappingUnit(
    filteringQuestionsUnit,
  );

  return {
    locationState: makeFormElementState(initialState.location),
    filteringQuestionsUnit,
    filteringQuestionsMappingUnit,
    submitDate: {
      from: makeFormElementState(initialState.submitDateFrom),
      to: makeFormElementState(initialState.submitDateTo),
    },
    groupsAndUsers: {
      group: makeFormElementState<GroupOption | null>(null),
      users: makeFormElementState<UserOption[]>([]),
    },
    switchesState: {
      filtertingQuestions: makeFormElementState(
        Object.keys(filteringQuestionsMappingUnit.getState()).length > 0,
      ),
      location: makeFormElementState(initialState.location !== null),
      sumbitDate: makeFormElementState(
        initialState?.submitDateFrom !== null ||
          initialState?.submitDateTo !== null,
      ),
      groupsAndUsers: {
        switch: makeFormElementState(
          initialState?.group !== undefined ||
            (initialState?.users !== undefined &&
              initialState.users.length > 0),
        ),
        activeTab: makePrimaryUnit<GroupsAndUsersTab>(
          initialState?.users !== undefined && initialState.users.length > 0
            ? 'users'
            : 'group',
        ),
      },
    },
  };
}
