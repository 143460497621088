import randomColor from 'randomcolor';

import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import {
  PrimaryStateUnit,
  makeDerivedUnit,
  AbstractStateUnit,
} from 'utils/State';

import * as AnswerVariant from '../AnswerVariant';
import * as ImageInfoUtility from '../ImageInfoUtility';
import {
  Layer,
  MarkerMode,
  QuestionKinds,
  VariantColor,
  VariantIcon,
} from './types';

const colorStateCache: Record<string, FormElementState<string>> = {};
const iconStateCache: Record<string, FormElementState<M.ImageInfo | null>> = {};

type ConstructorVariants = {
  kind: 'constructor';
  value: PrimaryStateUnit<AnswerVariant.AnswerVariant[]>;
};

type ProjectViewVariants = {
  kind: 'project-view';
  value: M.Variant[];
};

type QuestionForLayerVariants = ConstructorVariants | ProjectViewVariants;

type Args = {
  layer: M.MapLayer;
  questionIDToQuestionTextUnitMapping: Record<
    string,
    AbstractStateUnit<string>
  >;
  questionIDToQuestionKind: Record<string, TS.QuestionKind>;
  variants?: QuestionForLayerVariants;
};

export function makeLayerFromServerLayer({
  layer,
  questionIDToQuestionTextUnitMapping,
  variants,
  questionIDToQuestionKind,
}: Args): Layer {
  const questionID = layer.questionId;
  const questionTextUnit = questionIDToQuestionTextUnitMapping[questionID];
  const questionKind = questionIDToQuestionKind[questionID];

  switch (layer.type) {
    case 'quantitative':
      return {
        kind: 'scale',
        markerShape: makeFormElementState(layer.graphicName),
        colorRange: makeFormElementState<TS.ColorRange>({
          from: layer.scale[0].color,
          to: layer.scale[layer.scale.length - 1].color,
        }),
        // TODO ask uuid is in model doc
        id: layer.questionId,
        pointsNumber: makeFormElementState(layer.scale.length),
        question: {
          kind: questionKind as QuestionKinds.ForLayerWithScale,
          id: questionID,
          name: questionTextUnit,
        },
        scaleRange: {
          from: makeFormElementState<number | null>(layer.scale[0].start),
          to: makeFormElementState<number | null>(
            layer.scale[layer.scale.length - 1].end,
          ),
        },
      };

    case 'qualitative':
      if (variants === undefined) {
        throw Error('unexpected empty variants arg on qualitative layer type');
      }

      const variantsUnit =
        variants.kind === 'project-view'
          ? makeDerivedUnit(I18n.activeLangStateUnit).getUnit(lang =>
              variants.value.map(x =>
                AnswerVariant.makeVariantFromServerVariant({
                  variant: x,
                  getMultilingTranslation:
                    I18n.makeGetMultilingTranslation(lang),
                }),
              ),
            )
          : variants.value;

      const answerIDToAnswer = variantsUnit
        .getState()
        .reduce<Record<string, AnswerVariant.AnswerVariant>>(
          (acc, x) => ({
            [x.id]: x,
            ...acc,
          }),
          {},
        );

      switch (layer.mode) {
        case 'color':
          layer.scale.forEach(x => {
            if (colorStateCache[x.value] === undefined) {
              colorStateCache[x.value] = makeFormElementState(x.display);
            }
          });
          break;
        case 'icons':
          layer.scale.forEach(x => {
            if (iconStateCache[x.value] === undefined) {
              const answerIcon =
                answerIDToAnswer[x.value].image?.units.value.getState();
              if (x.display) {
                iconStateCache[x.value] =
                  makeFormElementState<M.ImageInfo | null>(
                    ImageInfoUtility.makeIcon(x.display),
                  );
              } else if (answerIcon) {
                iconStateCache[x.value] =
                  makeFormElementState<M.ImageInfo | null>(answerIcon);
              }
            }
          });
      }

      return {
        kind: 'colored-markers/icons',
        id: layer.questionId,
        question: {
          kind: questionKind as QuestionKinds.ForLayerWithVariants,
          id: questionID,
          name: questionTextUnit,
          variants: variantsUnit,
        },
        settings: {
          mode: makeFormElementState<MarkerMode>(
            layer.mode === 'color' ? 'colored-markers' : 'icons',
          ),
          color: {
            markerShape: makeFormElementState(
              layer.mode === 'color' ? layer.graphicName : 'circle',
            ),
            variantsColors: makeDerivedUnit(variantsUnit).getUnit(variants =>
              variants.map((x): VariantColor => {
                if (colorStateCache[x.id] === undefined) {
                  colorStateCache[x.id] = makeFormElementState(randomColor());
                }

                return {
                  color: colorStateCache[x.id],
                  variantID: x.id,
                };
              }),
            ),
          },
          icons: {
            variantsIcons: makeDerivedUnit(variantsUnit).getUnit(variants =>
              variants.map((x): VariantIcon => {
                if (iconStateCache[x.id] === undefined) {
                  iconStateCache[x.id] =
                    makeFormElementState<M.ImageInfo | null>(null);
                }

                return {
                  icon: iconStateCache[x.id],
                  variantID: x.id,
                };
              }),
            ),
          },
        },
      };

    default:
      return {
        kind: 'without-settings',
        id: layer.questionId,
        question: {
          kind: questionKind as QuestionKinds.ForLayerWithoutSettings,
          id: questionID,
          name: questionTextUnit,
        },
      };
  }
}
