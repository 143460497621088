import { I18n } from 'services';
import { Question } from 'types/serverModels';
import { makeDerivedUnit } from 'utils/State';
import { Layer, AnswerVariant } from 'utils/business';

// project view context
export function makeQuestionForLayerFromServerQuestion(
  question: Question,
): Layer.QuestionForLayer | null {
  const name = I18n.makeTextUnitFromOptionalMultiling(question.title);

  switch (question.type) {
    case 'map':
      return {
        kind: 'map',
        id: question.uuid,
        name,
      };
    case 'single_choice':
    case 'multi_choice':
      return {
        kind:
          question.type === 'single_choice'
            ? 'single-variant-selection'
            : 'multiple-variants-selection',
        id: question.uuid,
        name,
        variants: makeDerivedUnit(I18n.activeLangStateUnit).getUnit(lang =>
          question.variants.map(x =>
            AnswerVariant.makeVariantFromServerVariant({
              variant: x,
              getMultilingTranslation: I18n.makeGetMultilingTranslation(lang),
            }),
          ),
        ),
      };

    case 'number':
    case 'probe':
      return {
        kind: question.type === 'number' ? 'number' : 'stream',
        id: question.uuid,
        name,
      };

    case 'image':
    case 'sound':
    case 'draw':
    case 'string':
    case 'text':
    case 'date':
    case 'video': {
      const mapping: Record<
        typeof question.type,
        Layer.QuestionForLayerWithoutSettings['kind']
      > = {
        date: 'date',
        draw: 'figures',
        image: 'image',
        sound: 'sound',
        string: 'single-line-string',
        text: 'multiline-string',
        video: 'video',
      };
      return {
        kind: mapping[question.type],
        id: question.uuid,
        name,
      };
    }

    default:
      return null;
  }
}
