import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const mapSearchFieldLabelReference = makeSharedEntryReference(
  data => data.mapSearchFieldLabel,
);

export const layerLabelReference = makeSharedEntryReference(
  data => data.layerLabel,
);

export const buttonLabelReference = makeSharedEntryReference(
  data => data.showQuestionnaireDataButtonLabel,
);
