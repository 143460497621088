import { makeFeature } from 'services/Feature';

import type { Component as ComponentType } from './Component';

export const feature = makeFeature({
  name: 'Autocomplete',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "autocomplete" */
      './Component'
    ),
  subfeatures: [],
});

export * as ValueItem from './ValueItem';
export * as SortedItem from './SortedItem';
export * from './makeHeaderWithLabel';

export const Component = feature.Component as typeof ComponentType;
