import type { makeFieldsetState } from './makeFieldsetState';

type Options = {
  fieldsetState: ReturnType<typeof makeFieldsetState>;
};

export function getCallInputData({ fieldsetState }: Options) {
  const [longitude, latitude] = fieldsetState.location.units.value.getState();

  const callInputData = {
    name: fieldsetState.name.units.value.getState(),
    description: fieldsetState.description.units.value.getState(),
    motto: fieldsetState.motto.units.value.getState(),
    location: { longitude, latitude },
    avatar: fieldsetState.avatar.units.value.getState().thumb[0].url,
  };
  return callInputData;
}
