import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const projectBookLabelReference = makeSharedEntryReference(
  data => data.entity.projectBook.label,
);

export const projectBookAssignedStatusReference = makeSharedEntryReference(
  data => data.projectBook.status.assigned,
);

export const projectBookOnprogressStatusReference = makeSharedEntryReference(
  data => data.projectBook.status.onprogress,
);

export const projectBookAssessmentStatusReference = makeSharedEntryReference(
  data => data.projectBook.status.assessment,
);

export const projectBookFinishedStatusReference = makeSharedEntryReference(
  data => data.projectBook.status.finished,
);

export const projectBookNullStatusReference = makeSharedEntryReference(
  data => data.projectBook.status.null,
);
