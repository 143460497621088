import type * as ProjectCard from 'features/project/Card';
import * as M from 'types/serverModels';

export function convertProjectIdxItemToProjectCardFeatureProjectProp(
  projectIdxItem: M.User['projectIdx']['0'],
): ProjectCard.Props['project'] {
  const {
    uuid,
    code,
    title,
    thumb,
    author,
    about,
    likes,
    results_counter,
    features,
    subjects,
    status,
    langs,
    perks,
  } = projectIdxItem;

  const projectCardFeatureProjectProp = {
    uuid,
    code,
    title,
    thumb,
    author: author.reduce(
      (acc, author) => ({ ...acc, [author]: { login: author } }),
      {},
    ),
    about,
    likes,
    results_counter: results_counter || 0,
    subjects,
    features,
    perks,
    status,
    langs,
    private: projectIdxItem.private,
  };

  return projectCardFeatureProjectProp;
}
