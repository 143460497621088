import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('counter');

type Size = 'xs' | 'm';

type Props = {
  total: number;
  maxTotal?: number;
  size?: Size;
  withBorder?: boolean;
  className?: string;
};

function Counter({
  total,
  maxTotal,
  size = 'm',
  withBorder = false,
  className,
}: Props) {
  return (
    <span className={b({ size, 'with-border': withBorder }, [className])}>
      {maxTotal && total > maxTotal ? `${maxTotal}+` : total}
    </span>
  );
}

export const Component = React.memo(Counter) as typeof Counter;
