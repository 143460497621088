import { useMemo } from 'react';

import * as TS from 'types';
import { makeFormElementState } from 'utils/FormState';

export * as ButtonGroup from './ButtonGroup';
export * as Button from './Button';
export * from './types';

export function makeFieldState(
  initialState: string | null = null,
  validators?: TS.Validator[],
) {
  return makeFormElementState<string | null>(initialState, validators);
}

export function useFieldState(initialState: string | null = null) {
  return useMemo(() => makeFieldState(initialState), [initialState]);
}
