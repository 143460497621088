import React, { useEffect } from 'react';

import * as API from 'services/API';
import { getCookie } from 'utils/Cookie';

type Props = {};

function TicketInitializer({}: Props) {
  useEffect(() => {
    const ticket = getCookie('ticket');

    if (ticket === null) {
      return;
    }

    API.setTicket(ticket);
  }, []);

  return null;
}

export const Component = React.memo(
  TicketInitializer,
) as typeof TicketInitializer;
