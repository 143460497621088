import debounce from 'debounce';

import { I18n } from 'services';
import * as TS from 'types';
import { validatorOnVisitedSubscriberName } from 'utils/FormState/makeFormElementState';
import { makeLogger } from 'utils/Logger';
import {
  isPrimaryStateUnit,
  makeMappingUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
  UnitDebugData,
  UnitsState,
} from 'utils/State';

export function makeSingleUnitValidator<T>(
  getValidationResult: (value: T) => TS.ValidationResult,
  debounceInterval?: number,
  debugData?: UnitDebugData,
): TS.SingleUnitValidator {
  const validationIsAllowedUnit = makePrimaryUnit(true);

  let onInvalidResult: (() => void) | null = null;
  let onSwitchFromInvalidToValid: (() => void) | null = null;

  let formElementStateUnits: TS.StateUnitsDependencies<any> = null as any;
  let errorUnit: PrimaryStateUnit<I18n.EntryReference | null> = null as any;
  let prevArg: any = [null];
  let prevResult: boolean = true;

  let logger = makeLogger(debugData?.name || '', debugData?.name !== undefined);

  const subscriberName = debugData
    ? `${debugData.name}-validator`
    : 'validator';

  const validate = (arg: any): boolean => {
    logger.log('validating', arg);

    const argIsNew = arg !== prevArg;
    if (argIsNew) {
      logger.log('arg is new', arg);
      prevArg = arg;

      if (!formElementStateUnits.visited.getState()) {
        formElementStateUnits.visited.setState(true, [
          validatorOnVisitedSubscriberName,
        ]);
      }

      const validationResult = getValidationResult(arg);
      logger.log('validation result', validationResult);

      if (validationResult instanceof Promise) {
        formElementStateUnits.validationIsPending.setState(true);

        validationResult.then(asyncValidationResult => {
          formElementStateUnits.validationIsPending.setState(false);

          if (asyncValidationResult.kind === 'valid') {
            if (prevResult === false) {
              onSwitchFromInvalidToValid!();
            }

            prevResult = true;
            errorUnit.setState(null);
          } else if (asyncValidationResult.kind === 'invalid') {
            errorUnit.setState(asyncValidationResult.messageReference);
            onInvalidResult!();
            prevResult = false;
          } else {
            console.error(
              'unexpected asyncValidationResult',
              asyncValidationResult,
            );
          }
        });

        return prevResult;
      }

      if (validationResult.kind === 'bad-invariant') {
        console.error('unexpected validated value', arg);
        errorUnit.setState(I18n.constants.emptyReference);
        onInvalidResult!();
        prevResult = false;
        return false;
      }

      if (validationResult.kind === 'invalid') {
        errorUnit.setState(validationResult.messageReference);
        onInvalidResult!();
        prevResult = false;
        return false;
      }

      if (validationResult.kind === 'valid') {
        if (prevResult === false) {
          onSwitchFromInvalidToValid!();
        }

        errorUnit.setState(null);
      }

      prevResult = true;
      return true;
    } else {
      return prevResult;
    }
  };

  const validateWithDebounceMaybe = debounceInterval
    ? debounce(validate, debounceInterval)
    : validate;

  return {
    kind: 'single-unit',
    initDependencies: (
      formElementStateUnitsDependency,
      errorUnitDependency,
      onInvalidResultCallback,
      onSwitchFromInvalidToValidCallback,
    ) => {
      formElementStateUnits = formElementStateUnitsDependency;
      errorUnit = errorUnitDependency;
      onInvalidResult = onInvalidResultCallback;
      onSwitchFromInvalidToValid = onSwitchFromInvalidToValidCallback;

      // NOTE we use try to use subscribeStart to notify validators in correct order
      // (from first to last), so we can skip unnecessary validation
      const subscribeToValue = isPrimaryStateUnit(formElementStateUnits.value)
        ? formElementStateUnits.value.subscribeStart
        : formElementStateUnits.value.subscribe;

      subscribeToValue({
        name: subscriberName,
        callback: arg => {
          logger.log('received new value');

          if (
            validationIsAllowedUnit.getState() &&
            formElementStateUnits.visited.getState()
          ) {
            validateWithDebounceMaybe(arg);
          }
        },
      });
    },
    validationIsAllowedUnit,
    validate: () => validate(formElementStateUnits.value.getState()),
    isValid: () => {
      const validationResult = getValidationResult(
        formElementStateUnits.value.getState(),
      );

      return validationResult instanceof Promise
        ? true
        : validationResult.kind === 'valid';
    },
    setDebugData: ({ name }) => {
      logger = makeLogger(name, true);
    },
    reset: () => {
      prevArg = [null];
      prevResult = true;
      validationIsAllowedUnit.resetState();
    },
  };
}

export function makeMultipleUnitsValidator<T extends Array<unknown>>(
  getValidationResult: (...args: UnitsState<T>) => TS.ValidationResult,
  ...argsUnits: T
): TS.MultipleUnitsValidator {
  const argsUnit = makeMappingUnit(argsUnits);

  let onInvalidResult: (() => void) | null = null;
  let onSwitchFromInvalidToValid: (() => void) | null = null;

  let formElementStateUnits: TS.StateUnitsDependencies<any> = null as any;
  let errorUnit: PrimaryStateUnit<I18n.EntryReference | null> = null as any;
  let prevArgs: any = [];
  let prevResult: boolean = true;

  let logger = makeLogger('', false);

  const validate = (args: any): boolean => {
    logger.log('validating', args);
    const argsAreNew = (args as any[]).some(
      (x, index) => x !== prevArgs[index],
    );
    if (argsAreNew) {
      logger.log('args are new');
      prevArgs = args;
      formElementStateUnits.visited.setState(true);
      const validationResult = getValidationResult(...args);
      logger.log('validation result', validationResult);

      if (validationResult instanceof Promise) {
        console.error('validation result as promise is not implemented');
        return prevResult;
      }

      if (validationResult.kind === 'bad-invariant') {
        errorUnit.setState(I18n.constants.emptyReference);
        onInvalidResult!();
        prevResult = false;
        return false;
      }

      if (validationResult.kind === 'invalid') {
        errorUnit.setState(validationResult.messageReference);
        onInvalidResult!();
        prevResult = false;
        return false;
      }

      if (validationResult.kind === 'valid') {
        if (prevResult === false) {
          onSwitchFromInvalidToValid!();
        }

        errorUnit.setState(null);
      }

      prevResult = true;
      return true;
    } else {
      logger.log('args are old', prevArgs);
      return prevResult;
    }
  };

  const validationIsAllowedUnit = makePrimaryUnit(true);

  return {
    kind: 'multiple-units',
    initDependencies: (
      formElementStateUnitsDependency,
      errorUnitDependency,
      onInvalidResultCallback,
      onSwitchFromInvalidToValidCallback,
    ) => {
      formElementStateUnits = formElementStateUnitsDependency;
      errorUnit = errorUnitDependency;
      onInvalidResult = onInvalidResultCallback;
      onSwitchFromInvalidToValid = onSwitchFromInvalidToValidCallback;

      argsUnit.subscribe({
        name: 'validator',
        callback: args => {
          logger.log(
            'received new args',
            args,
            validationIsAllowedUnit.getState(),
          );
          if (
            validationIsAllowedUnit.getState() &&
            formElementStateUnits.visited.getState()
          ) {
            validate(args);
          }
        },
      });

      formElementStateUnits.visited.subscribe({
        name: 'validator',
        callback: visited => {
          if (visited) {
            logger.log('received new visited state');
            validate(argsUnit.getState());
          }
        },
      });
    },
    validationIsAllowedUnit,
    validate: () => validate(argsUnit.getState()),
    isValid: () => {
      const validationResult = getValidationResult(
        ...(argsUnit.getState() as any),
      );

      return validationResult instanceof Promise
        ? true
        : validationResult.kind === 'valid';
    },
    setDebugData: ({ name }) => {
      logger = makeLogger(name, true);
    },
    reset: () => {
      prevArgs = [];
      prevResult = true;
      validationIsAllowedUnit.resetState();
    },
  };
}
