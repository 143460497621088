import * as M from 'types/serverModels';

import { makeService } from './utils';

type ProjectUUID = string;
type Timestamp = number;

type Filter = {
  project: ProjectUUID;
  cdate?: Partial<{
    gt: Timestamp;
    gte: Timestamp;
    lt: Timestamp;
    lte: Timestamp;
  }>;
};

type Map = {
  location?: 'location';
  uuid?: 'uuid';
  user?: 'user';
  answers?: { [questionUUID: string]: string };
  cdate?: 'date';
  status?: 'status';
  group?: 'group';
};

type InputData = {
  filter?: Filter;
  resolveUser?: 1;
  resolveGroup?: 1;
  likes?: 1;
};

// TODO old api remove when unused;
export const dataList = makeService<InputData, M.QuestionnaireData[]>(
  'data_list',
  'get',
);
