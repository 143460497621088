import type React from 'react';

function getVerticalOptionsStyle(
  anchorRect: DOMRect,
  margin: number,
  defaultMaxHeight?: number,
): React.CSSProperties {
  const anchorCenter = anchorRect.top + anchorRect.height / 2;

  const isAboveClientCenter =
    document.documentElement.clientHeight / 2 - anchorCenter >= 0;

  const bottomAvailableHeight =
    document.documentElement.clientHeight - anchorRect.bottom - margin;
  const preferBottomPlacement =
    defaultMaxHeight !== undefined
      ? bottomAvailableHeight >= defaultMaxHeight
      : false;

  if (isAboveClientCenter || preferBottomPlacement) {
    return {
      top: anchorRect.bottom + margin,
      bottom: undefined,
      maxHeight:
        defaultMaxHeight !== undefined
          ? Math.min(defaultMaxHeight, bottomAvailableHeight)
          : bottomAvailableHeight,
    };
  }

  const topAvailableHeight = anchorRect.top - margin;

  return {
    bottom: window.innerHeight - anchorRect.top + margin,
    top: undefined,
    maxHeight:
      defaultMaxHeight !== undefined
        ? Math.min(defaultMaxHeight, topAvailableHeight)
        : topAvailableHeight,
  };
}

function getHorizontalOptionsStyle(
  anchorRect: DOMRect,
  defaultMinWidth?: number,
): React.CSSProperties {
  const availableWidth =
    document.documentElement.clientWidth - anchorRect.right;

  if (defaultMinWidth !== undefined && availableWidth < defaultMinWidth) {
    return {
      right: document.documentElement.clientWidth - anchorRect.right,
      left: undefined,
    };
  }

  return {
    left: anchorRect.left,
    right: undefined,
  };
}

export function getFixedPositionStyle({
  anchorRect,
  margin,
  defaultMaxHeight,
  defaultMinWidth,
}: {
  anchorRect: DOMRect;
  margin: number;
  defaultMaxHeight?: number;
  defaultMinWidth?: number;
}): React.CSSProperties {
  return {
    ...getHorizontalOptionsStyle(anchorRect, defaultMinWidth),
    ...getVerticalOptionsStyle(anchorRect, margin, defaultMaxHeight),
  };
}
