import { makeSharedEntryReference } from 'services/I18n';

export const i18nStatusSharedReferences = {
  assigned: makeSharedEntryReference(data => data.projectBook.status.assigned),
  onprogress: makeSharedEntryReference(
    data => data.projectBook.status.onprogress,
  ),
  assessment: makeSharedEntryReference(
    data => data.projectBook.status.assessment,
  ),
  finished: makeSharedEntryReference(data => data.projectBook.status.finished),
  null: makeSharedEntryReference(data => data.projectBook.status.null),
};
