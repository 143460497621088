import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'ProjectViewResultsSidebar',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-view-results-sidebar" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
});

export type { Props } from './Component';

export const Component = feature.Component;
