import React, { useEffect } from 'react';

import { isClientScriptLoaded } from './units';

type Props = {};

function ClientScriptInitializer({}: Props) {
  useEffect(() => {
    const clientScript = document.createElement('script');
    clientScript.src = `${process.env.RAZZLE_SOCKET_BACKEND_URL}/socket.io/socket.io.js`;
    clientScript.async = true;

    const handleLoad = () => {
      clientScript.removeEventListener('load', handleLoad);

      isClientScriptLoaded.setState(true);
    };

    clientScript.addEventListener('load', handleLoad);

    document.body.appendChild(clientScript);

    return () => {
      clientScript.removeEventListener('load', handleLoad);

      document.body.removeChild(clientScript);

      isClientScriptLoaded.resetState();
    };
  }, []);

  return null;
}

export const Component = React.memo(
  ClientScriptInitializer,
) as typeof ClientScriptInitializer;
