import { pathToRegexp } from 'path-to-regexp';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Preloader } from 'components';
import { getCounters } from 'features/user/utils';
import { Routing, API, I18n } from 'services';
import { Page } from 'services';
import { USER_ROLE, USER_STATUS } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';
import { substitutes } from 'utils/RouteTree';
import { block } from 'utils/classname';

import * as UserProfile from '../UserProfile';
import * as Courses from '../UserProfile/Courses';
import * as Groups from '../UserProfile/Groups';
import * as Perks from '../UserProfile/Perks';
import * as PersonalInformation from '../UserProfile/PersonalInformation';
import * as Projects from '../UserProfile/Projects';
import { routeTree } from '../routeTree';
import * as features from './features';
import './style.scss';

const b = block('user-page');

const callStateUnit = API.services.userProfile.makeCallStateUnit();

function User() {
  const history = useHistory();
  const location = useLocation();

  const lang = I18n.activeLangStateUnit.useState();
  const ticket = API.useTicket();
  const userState = userStateUnit.useState();

  const params =
    typeof window !== 'undefined' && routeTree.LANG.users.USER.getRouteParams();
  const urlLogin = params && params.user;
  const userProfilePath = routeTree.LANG.users.getPath();

  const callState = callStateUnit.useState();
  const user = callState.kind === 'successful' ? callState.data : undefined;

  const [isOutdated, setIsOutdated] = useState(false);

  const { call } = API.services.userProfile.useUnitService(callStateUnit);

  const counters = useMemo(() => {
    if (user === undefined) {
      return null;
    }
    const currUser = userState.kind === 'loaded' ? userState.user : undefined;

    return getCounters(
      user,
      {
        withPrivateDataDisplay:
          userState.kind === 'loaded' &&
          userState.user.role === USER_ROLE.moderator,
      },
      currUser,
    );
  }, [user, userState]);

  const [pathWithNotEmptyCounter] = useMemo(() => {
    if (counters === null) {
      return [];
    }

    return Object.entries(counters).find(([key, counter]) => counter > 0) || [];
  }, [counters]);

  const isUserPending = user?.status === USER_STATUS.pending;
  const isUserProfileEmpty = useMemo(() => {
    if (user === undefined || counters === null) {
      return true;
    }

    const profileItemsCounter = Object.values(counters).reduce(
      (acc, counter) => acc + counter,
      0,
    );

    return profileItemsCounter === 0;
  }, [user, counters]);
  const shouldShowPendingUserNotification = isUserPending || isUserProfileEmpty;

  const renderContent = useCallback(() => {
    if (user !== undefined) {
      return shouldShowPendingUserNotification ? (
        <features.PendingUserNotification.Component
          className={b('pending-user-notification')}
        />
      ) : (
        <>
          <UserProfile.Component user={user} />
          <PersonalInformation.Component user={user} />
          <Perks.Component user={user} />
          <Projects.Component user={user} />
          <Groups.Component user={user} />
          {/* // TODO: return it when the layout and services are ready(after MVP) */}
          <Courses.Component user={user} />
        </>
      );
    }
  }, [shouldShowPendingUserNotification, user]);

  const rootPath = routeTree.LANG.users.USER.getPath({
    routeParams: {
      LANG: lang,
      USER: substitutes.fromLocation,
    },
  });
  const currentEndPath = location.pathname.slice(
    location.pathname.lastIndexOf('/') + 1,
  ) as keyof NonNullable<typeof counters>;

  const isUserLoaded = user !== undefined && urlLogin === user.login;
  const isCurrentFeatureEmpty =
    counters !== null && counters[currentEndPath] === 0;
  const noFeaturePath =
    counters !== null && counters[currentEndPath] === undefined;

  useEffect(() => {
    const previousLocation = Routing.getPreviousLocation();

    setIsOutdated(
      previousLocation?.pathname.match(
        pathToRegexp(userProfilePath, [], { end: false }),
      ) === null,
    );
  }, [location.pathname, userProfilePath]);

  useEffect(() => {
    if (ticket !== null) {
      setIsOutdated(true);
    }
  }, [ticket]);

  useEffect(() => {
    if (urlLogin === false) {
      return;
    }

    const isInvalid =
      callState.kind === 'successful' && urlLogin !== callState.data.login;

    const shouldUpdate =
      callState.kind === 'initial' || isOutdated || isInvalid;

    if (!shouldUpdate) {
      return;
    }

    if (ticket !== null) {
      if (userState.kind !== 'loaded') {
        return;
      }

      if (urlLogin === userState.user.login) {
        callStateUnit.setState({ kind: 'successful', data: userState.user });
      } else {
        call({
          ticket,
          login: urlLogin,
        });
      }
    } else {
      call({ login: urlLogin });
    }

    setIsOutdated(false);
  }, [
    isOutdated,
    ticket,
    userState,
    callState,
    urlLogin,
    userProfilePath,
    call,
  ]);

  useEffect(() => {
    if (
      isUserLoaded &&
      (isCurrentFeatureEmpty || noFeaturePath) &&
      pathWithNotEmptyCounter !== undefined
    ) {
      history.replace(`${rootPath}/${pathWithNotEmptyCounter}`);
    }
  }, [
    history,
    isCurrentFeatureEmpty,
    isUserLoaded,
    noFeaturePath,
    pathWithNotEmptyCounter,
    rootPath,
  ]);

  return (
    <div className={b({})}>
      {isOutdated ||
      userState.kind === 'pending' ||
      callState.kind === 'pending' ? (
        <Preloader.Component size="xs" className="preloader" />
      ) : (
        <>
          {user && (
            <features.ProfileHeader.Component
              user={user}
              className={b('profile-header')}
            />
          )}
          <div className={b('content')}>
            {user && !shouldShowPendingUserNotification && (
              <features.Tabs.Component
                className={b('profile-tabs')}
                user={user}
              />
            )}
            {renderContent()}
          </div>
        </>
      )}
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.users.USER.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(User),
  features: Object.values(features).map(x => x.feature),
});
