import { useMemo } from 'react';

import { makeFeature } from 'services/Feature';
import { makeFormElementState } from 'utils/FormState';

import i18nData from './i18n.json';
import { State } from './types';

export const feature = makeFeature({
  name: 'RangeDatePicker',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "range-date-picker" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
});

export type { Props } from './Component';

export function makeFieldState(initialState: State = { from: null, to: null }) {
  return makeFormElementState(initialState);
}

export function useFormElementState(
  initialState: State = { from: null, to: null },
) {
  return useMemo(() => makeFieldState(initialState), [initialState]);
}

export const Component = feature.Component;
