import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = M.EmulateParamsHolder & {
  question: string;
  limit: 100;
  filter?: string; //M.Filter;
};

export const getWordCloudData = makeService<InputData, TS.WordCloudDataItem[]>(
  'get_wcloud_data',
  'get',
);
