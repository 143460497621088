import React from 'react';

import { Page } from 'services';
import * as M from 'types/serverModels';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  group: M.ServerGroup;
};

function Rating({ group }: Props) {
  return <features.Rating.Component group={group} />;
}

export const path = routeTree.LANG.group.GROUP.rating.getPath();

export const Component = Page.makePage({
  path: routeTree.LANG.group.GROUP.rating.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(Rating) as typeof Rating,
  features: Object.values(features).map(x => x.feature),
});
