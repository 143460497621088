import { match } from 'path-to-regexp';

type Prefetcher = (path: string, ticket: string | undefined) => void;

const prefetchers: Record<string, Prefetcher> = {};

export type PrefetchedData = {
  data: any;
  id: string;
};

let prefetchedDataArray: Array<Promise<PrefetchedData>> = [];

export async function getInitializationSource(
  route: string,
  { ticket }: { ticket: string | undefined },
): Promise<string> {
  prefetchedDataArray = [];

  Object.values(prefetchers).forEach(f => {
    f(route, ticket);
  });

  const dataArray = await Promise.all(prefetchedDataArray);

  return (
    'window.fetchedData = {};\n' +
    dataArray
      .map(x => `window.fetchedData['${x.id}'] = ${JSON.stringify(x.data)};`)
      .join('\n')
  );
}

export type DataPrefetcher<T> = {
  getData(): T | NotFetched;
  deleteData(): void;
};

export const notFetched = Symbol('not-fetched');

export type NotFetched = typeof notFetched;

export function makeDataPrefetcher<T>(
  id: string,
  pathPattern: string,
  fetcher: (
    pathParams: Record<string, string>,
    ticket: string | undefined,
  ) => Promise<T>,
): DataPrefetcher<T> {
  const getMatchData = match(pathPattern);

  switch (process.env.BUILD_TARGET) {
    case 'server': {
      let data: T | NotFetched = notFetched;

      prefetchers[id] = (path: string, ticket: string | undefined) => {
        const matchData = getMatchData(path);

        if (matchData) {
          prefetchedDataArray.push(
            fetcher(matchData.params as Record<string, string>, ticket).then(
              fetchedData => {
                data = fetchedData;
                return { data, id };
              },
            ),
          );
        }
      };

      return {
        getData: () => {
          return data;
        },
        deleteData: () => {
          console.warn('not supposed to delete data on server');
        },
      };
    }

    case 'client': {
      return {
        getData: () => (window as any).fetchedData?.[id] || notFetched,
        deleteData: () => {
          if ((window as any).fetchedData?.[id] === undefined) {
            return;
          }

          (window as any).fetchedData[id] = undefined;
        },
      };
    }

    default: {
      console.error('unknown build target', process.env.BUILD_TARGET);

      return {
        getData: () => notFetched,
        deleteData: () => {},
      };
    }
  }
}
