import React, { useCallback } from 'react';

import { Page } from 'services';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import './style.scss';
import { WidgetFullscreenControl } from './subfeatures';

const b = block('fullscreen-widget-container');

type Props = {
  Content: React.VFC;
  Header?: React.VFC;
  Controls?: React.VFC;
  fullScreenUnit: PrimaryStateUnit<boolean>;
};

function FullScreenWidgetContainer({
  Content,
  Header,
  Controls,
  fullScreenUnit,
}: Props) {
  const fullScreen = fullScreenUnit.useState();

  const handleFullscreenOffClick = useCallback(() => {
    fullScreenUnit.setState(false);
  }, [fullScreenUnit]);

  Page.useSetScroll(fullScreen);

  return (
    <div className={b()}>
      <header className={b('header')}>
        <div className={b('header-left-part')}>{Header && <Header />}</div>
        <div className={b('header-right-part')}>
          {Controls && <Controls />}
          <WidgetFullscreenControl.Component
            variant="off"
            onClick={handleFullscreenOffClick}
          />
        </div>
      </header>
      <main className={b('main')}>
        <Content />
      </main>
    </div>
  );
}

export const Component = React.memo(
  FullScreenWidgetContainer,
) as typeof FullScreenWidgetContainer;
