import React, { useEffect, useMemo, useRef } from 'react';

import { ErrorMessage } from 'components';
import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { VariantSelectionQuestionForm } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import * as View from './View';
import { makeElementModels } from './makeElementModels';
import { useModel } from './useModel';

type Props = {
  data: M.SingleChoiceQuestion | M.SingleChoiceQuizQuestion;
  num: number;
  kind: Kind;
  type: Type;
  initialValue?: string[];
  initialText?: Record<string, string>;
  score: { max: number; result: number } | null;
};

export const stateUnit = makePrimaryUnit<
  Record<
    string,
    FormElementState<{
      valueState: FormElementState<VariantSelectionQuestionForm.SingleChoiceElementModel | null>;
      textState: FormElementState<string>;
    }>
  >
>({});

function SingleChoiceQuestion({
  data,
  num,
  initialValue,
  initialText,
  kind,
  type,
  score,
}: Props) {
  const variant = useMemo(
    () => data.variants.find(x => x.uuid === initialValue?.[0]),
    [data.variants, initialValue],
  );

  const lang = I18n.activeLangStateUnit.useState();

  const isOptional = 'optional' in data && data.optional;
  const visibility = 'visibility' in data && data.visibility;

  const initValue = useRef({
    valueState:
      makeFormElementState<VariantSelectionQuestionForm.SingleChoiceElementModel | null>(
        variant
          ? makeElementModels(variant, lang, initialText?.[variant.uuid])
          : null,
        !isOptional ? [nonNull(questionIsRequired)] : [],
      ),
    textState: makeFormElementState(
      (variant && initialText?.[variant.uuid]) || '',
    ),
  });

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    initialValue: initValue.current,
  });

  const state = formElementState.units.value.useState();
  const value = state.valueState.units.value.useState();
  const error = state.valueState.units.error.useState();

  const t = I18n.useGetMultilingProjectTranslation();

  const title = t(data.title);
  const description = t(data.description);
  const answer = t(data.answer);

  const model = useModel(data, state, initialText);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  return (
    <QuestionLayout.Component
      title={title}
      description={description}
      image={data.image?.large}
      type={type}
      num={num}
      variant={
        (type === 'quiz' && kind === 'form') || value === null
          ? 'outlined'
          : 'contained'
      }
      isOptional={isOptional}
      isEmpty={kind === 'view' && value === null}
      isAutocomplete={visibility === QUESTION_VISIBILITY.disabled}
      score={score}
      answer={kind === 'view' ? answer : undefined}
    >
      {kind === 'view' && variant && (
        <View.Component variant={variant} text={initialText?.[variant.uuid]} />
      )}
      {kind === 'form' && (
        <>
          <VariantSelectionQuestionForm.Component model={model} />
          <ErrorMessage.Component rows={1} messageReference={error} />
        </>
      )}
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(SingleChoiceQuestion);
