import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'MapWidgetDatalessView',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "map-widget-dataless-view" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
});

export const Component = feature.Component;
