import { makeFeature } from 'services/Feature';

import * as i18nSharedReferences from './i18nSharedReferences';

export const feature = makeFeature({
  name: 'UserPersonalAccountMenu',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "user-personal-account-menu" */
      './Component'
    ),
  i18nSharedReferences: Object.values(
    (() => {
      const { userTabReferences } = i18nSharedReferences;

      return [...Object.values(userTabReferences)];
    })(),
  ),
  subfeatures: [],
});

export const Component = feature.Component;
