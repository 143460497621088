import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'Header',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "header" */
      './Component'
    ),
  i18nSharedReferences: Object.values(
    (() => {
      const { userTabReferences } = i18nSharedReferences;

      return [...Object.values(userTabReferences)];
    })(),
  ),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
  i18nData,
});

export * as LogoLink from './LogoLink';

export { hamburgerMenuIsDisplayedUnit } from './Mobile/units';
export {
  headerUnit,
  headerContentUnit,
  mobileHeaderContentUnit,
} from './units';

export const Component = feature.Component;
