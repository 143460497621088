import {
  activeLangStateUnit,
  getOptionalMultilingTranslation,
} from 'services/I18n';
import * as M from 'types/serverModels';
import { makeDerivedUnit } from 'utils/State';

export function makeQuestionIDToQuestionTextUnit(project: M.ProjectReadData) {
  return makeDerivedUnit(activeLangStateUnit).getUnit(lang => {
    return project.questions.reduce<Record<string, string>>(
      (acc, x) => ({
        ...acc,
        [x.uuid]: getOptionalMultilingTranslation(lang, x.title),
      }),
      {},
    );
  });
}
