import * as M from 'types/serverModels';

import { makeService } from './utils';

type PathInputData = {
  code: M.LandingPage['code'];
  sectionUUID: M.LandingPageSection['uuid'];
};

type QueryInputData = {};

type BodyInputData = {
  form: {
    code: M.LandingPageSectionCommonFormElements['form'][number]['code'];
    value: string;
  }[];
};

type InputData = PathInputData & QueryInputData & BodyInputData;

type APIOutputData = void;

type OutputData = void;

function extractResponse(data: APIOutputData): OutputData {
  return data;
}

export const landingPageSectionFormPost = makeService<
  InputData,
  APIOutputData,
  OutputData
>(
  ({ ticket, code, sectionUUID }, convertToSearchParams) =>
    `landing_page/${code}/sections/${sectionUUID}/form/?${convertToSearchParams(
      { ticket },
    )}`,
  'api-v2-post',
  false,
  extractResponse,
  {
    extractBodyInput: ({ form }) => ({
      form,
    }),
  },
);
