import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';

import { ImagesQuestionForm } from '../../subfeatures';

export const makeAreasState = (
  data: M.ImageArea,
): ImagesQuestionForm.AreaData => {
  return {
    uuid: data.uuid,
    top: data.top,
    left: data.left,
    width: data.width,
    height: data.height,
    isSaved: true,
    captionState: makeFormElementState(data.text),
  };
};
