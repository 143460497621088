import {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import React from 'react';
import { createPortal } from 'react-dom';

import { getFixedPositionStyle, stickFixedPositionElement } from 'utils/DOM';
import { block } from 'utils/classname';

import './style.scss';

export * as Children from './children';

const b = block('dropdown');

type Props = React.PropsWithChildren<{
  useLabel(): string;
  useIsSelected(): boolean;
  onExpand?(): void;
}>;

const containerMargin = 10;
const containerMaxHeight = 300;

function Dropdown({ children, useLabel, useIsSelected, onExpand }: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [containerStyles, setContainerStyles] = useState<React.CSSProperties>();

  const isSelected = useIsSelected();

  const rootRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const withChildren = children !== undefined;
  const isPositioned = isExpanded && containerStyles !== undefined;

  const updateContainerStyles = useCallback(() => {
    setContainerStyles(prev => {
      if (rootRef.current === null) {
        return prev;
      }

      return {
        ...prev,
        width: rootRef.current.getBoundingClientRect().width,
        ...getFixedPositionStyle({
          anchorRect: rootRef.current.getBoundingClientRect(),
          margin: containerMargin,
          defaultMaxHeight: containerMaxHeight,
        }),
      };
    });
  }, []);

  const handleButtonClick = useCallback(() => {
    setIsExpanded(prev => !prev);

    onExpand?.();
  }, [onExpand]);

  const handleDocumentBodyClick = useCallback((e: MouseEvent) => {
    if (!(e.target instanceof Node)) {
      return;
    }

    if (
      rootRef.current?.contains(e.target) ||
      containerRef.current?.contains(e.target)
    ) {
      return;
    }

    setIsExpanded(false);
  }, []);

  useLayoutEffect(() => {
    if (!isExpanded) {
      return;
    }

    document.body.addEventListener('click', handleDocumentBodyClick);

    return () => {
      document.body.removeEventListener('click', handleDocumentBodyClick);
    };
  }, [isExpanded, handleDocumentBodyClick]);

  useLayoutEffect(() => {
    if (!withChildren || !isExpanded) {
      return;
    }

    updateContainerStyles();

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updateContainerStyles,
    });

    return () => {
      unsubscribe();

      setContainerStyles(undefined);
    };
  }, [withChildren, isExpanded, updateContainerStyles]);

  useEffect(() => {
    if (isPositioned) {
      containerRef.current?.focus();
    }
  }, [isPositioned]);

  return (
    <div className={b()} ref={rootRef}>
      <button
        className={b('button', {
          selected: isSelected,
          expanded: isExpanded,
        })}
        type="button"
        onClick={handleButtonClick}
      >
        {useLabel()}
      </button>
      {isExpanded &&
        children &&
        createPortal(
          <div
            ref={containerRef}
            className={b('container')}
            style={containerStyles}
          >
            {children}
          </div>,
          document.body,
        )}
    </div>
  );
}

export const Component = Dropdown;
