import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'ShopMenu',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "shop-menu" */
      './Component'
    ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
