import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'EditProfileGroup',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "edit-profile-group" */
      './Component'
    ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export {
  makeFieldsetState,
  setIsFieldsetDisabled,
  getCallInputData,
} from './utils';

export const Component = feature.Component;
