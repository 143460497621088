import React from 'react';

import { Device } from 'services';
import { block } from 'utils/classname';

type Props = {
  className?: string;
  children: React.ReactNode;
  onClick?(): void;
};

function Item({ children, className, onClick }: Props) {
  const device = Device.unit.useState();

  const b = block(device === 'mobile' ? 'm-extra-menu' : 'extra-menu');

  return (
    <li
      className={b('item', { interactive: onClick !== undefined }, [className])}
      onClick={onClick}
    >
      {children}
    </li>
  );
}

export const Component = React.memo(Item) as typeof Item;
