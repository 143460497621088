import React, { useCallback, useMemo, useEffect } from 'react';

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
  chartRef: React.MutableRefObject<Highcharts.Chart | undefined>;
};

function ResizeObserver({ containerRef, chartRef }: Props) {
  const handleResize = useCallback(() => {
    if (chartRef.current !== undefined) {
      chartRef.current.reflow();
    }
  }, [chartRef]);

  const resizeObserver = useMemo(
    () => new globalThis.ResizeObserver(handleResize),
    [handleResize],
  );

  useEffect(() => {
    if (containerRef.current !== null) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, resizeObserver]);

  return null;
}

export const Component = React.memo(ResizeObserver) as typeof ResizeObserver;
