import * as I18n from 'services/I18n';
import * as M from 'types/serverModels';
import {
  AbstractStateUnit,
  DerivedStateUnit,
  makeDerivedUnit,
  makePrimaryUnit,
} from 'utils/State';

import { getMultilingTranslation } from './useGetMultilingTranslation';

export function makeTextUnitFromMultiling(
  str: M.MultilingString,
): DerivedStateUnit<string> {
  return makeDerivedUnit(I18n.activeLangStateUnit).getUnit(lang =>
    getMultilingTranslation(lang, str),
  );
}

export function makeTextUnitFromOptionalMultiling(
  str?: M.MultilingString,
): AbstractStateUnit<string> {
  return str
    ? makeDerivedUnit(I18n.activeLangStateUnit).getUnit(lang =>
        getMultilingTranslation(lang, str),
      )
    : makePrimaryUnit('');
}
