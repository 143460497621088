import { useMemo } from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';

function getGroupedPerks(perkStack: NonNullable<M.User['perks_stack']>['0']) {
  return perkStack.perks.reduce<{
    [perkGroupCode: number]: typeof perkStack.perks;
  }>((acc, perk) => {
    const perks = acc[perk.type] || [];

    return { ...acc, [perk.type]: [...perks, perk] };
  }, {});
}

export function usePerkGroups({
  perks_stack,
  perkIdx,
}: Pick<M.User, 'perks_stack' | 'perkIdx'>): {
  [code: string]: {
    title: string;
    perks: NonNullable<typeof perks_stack>['0']['perks'];
  };
} {
  const t = I18n.useGetMultilingTranslation();

  return useMemo(() => {
    return (
      perks_stack?.reduce((acc, perkStack) => {
        const hasGroup = perkStack.code !== '';

        if (!hasGroup) {
          const groups = Object.entries(getGroupedPerks(perkStack)).reduce(
            (acc, [code, perks]) => {
              const perkId = perkIdx && perkIdx[Number(code)];

              if (perkId === undefined) {
                return acc;
              }

              return {
                ...acc,
                [`i-${code}`]: {
                  title: t(perkId.title),
                  perks,
                },
              };
            },
            {},
          );

          return { ...acc, ...groups };
        }

        return {
          ...acc,
          [perkStack.code]: {
            title: t(perkStack.title),
            perks: perkStack.perks,
          },
        };
      }, {}) || {}
    );
  }, [perkIdx, perks_stack, t]);
}
