import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export { makeIsOpenUnit, useIsOpenUnit } from 'components/Modal';

export const feature = makeFeature({
  name: 'ErrorModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "error-modal" */
      './Component'
    ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export const Component = feature.Component;
