import { Device } from 'services';

import * as Desktop from './Desktop';
import * as Mobile from './Mobile';

export * as LabelWithArrow from './LabelWithArrow';

type Props = Desktop.Props & {
  alwaysExpandedOnMobile?: boolean;
  boldChildrenEntries?: boolean;
  expansionPanelFullHeaderWidth?: boolean;
};

export type RenderProps = {
  className?: string;
};

export type { LabelProps } from './LabelProps';

export function makeComponent({
  alwaysExpandedOnMobile = false,
  boldChildrenEntries,
  expansionPanelFullHeaderWidth,
  ...desktopProps
}: Props) {
  return (renderProps: RenderProps) => {
    const device = Device.unit.useState();

    switch (device) {
      case 'mobile':
        return (
          <Mobile.Component
            {...renderProps}
            Label={desktopProps.Label}
            alwaysExpanded={alwaysExpandedOnMobile}
            childrenSections={desktopProps.childrenSections}
            boldChildrenEntries={boldChildrenEntries}
            expansionPanelFullHeaderWidth={expansionPanelFullHeaderWidth}
          />
        );
      default:
        return <Desktop.Component {...desktopProps} {...renderProps} />;
    }
  };
}
