import * as M from 'types/serverModels';
import { makeUUID } from 'utils/UUID';

type SectionTrackObject = { type: 'section'; children?: TrackObject[] };
type SubsectionTrackObject = { type: 'subsection'; children?: TrackObject[] };
type ProjectTrackObject = { type: 'project' };
type UnitTrackObject = { type: 'unit' };
type QuizTrackObject = { type: 'quiz' };
type TrackObject =
  | SectionTrackObject
  | SubsectionTrackObject
  | ProjectTrackObject
  | UnitTrackObject
  | QuizTrackObject;

type WithKey<T extends TrackObject> = T extends { type: 'section' }
  ? Omit<T, 'children'> & {
      key: M.UUID;
      children?: WithKey<Exclude<T['children'], undefined>[number]>[];
    }
  : T extends { type: 'subsection' }
  ? Omit<T, 'children'> & {
      key: M.UUID;
      children?: WithKey<Exclude<T['children'], undefined>[number]>[];
    }
  : T extends { type: 'project' }
  ? T & {
      key: M.UUID;
    }
  : T extends { type: 'unit' }
  ? T & {
      key: M.UUID;
    }
  : T extends { type: 'quiz' }
  ? T & {
      key: M.UUID;
    }
  : T;

export function addKeyToTrackObjects<T extends TrackObject>(
  trackObjects: T[],
): WithKey<T>[] {
  return trackObjects.map(x => {
    switch (x.type) {
      case 'section':
      case 'subsection': {
        return {
          ...x,
          key: makeUUID(),
          children: x.children && addKeyToTrackObjects(x.children),
        } as WithKey<T>;
      }
      case 'project':
      case 'unit':
      case 'quiz': {
        return { ...x, key: makeUUID() } as WithKey<T>;
      }
    }

    return x as WithKey<T>;
  });
}
