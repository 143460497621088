export function makeMapping<T, E>(
  array: T[],
  getKey: (element: T) => string,
  getValue: (element: T) => E,
): Record<string, E> {
  const result: Record<string, E> = {};

  for (let i = 0; i < array.length; i++) {
    const x = array[i];
    result[getKey(x)] = getValue(x);
  }

  return result;
}
