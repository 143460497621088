import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.UUID;
};

export const dataDraftDelete = makeService<InputData, unknown>(
  'data_draft_delete',
  'post',
);
