import { makeFeature } from 'services/Feature';

import type { ComponentType } from './Component';

export const feature = makeFeature({
  name: 'ConstructorStepsPanel',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "constructor-steps-panel" */
      './Component'
    ),
  subfeatures: [],
});

export const Component = feature.Component as ComponentType;
