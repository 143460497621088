import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { Device, I18n, IDProvider } from 'services';
import { FormElementState, FormElementStateUnits } from 'utils/FormState/types';
import { AbstractStateUnit } from 'utils/State';
import * as predicates from 'utils/predicates';
import { parseHTML } from 'utils/react';

import * as ErrorMessage from '../ErrorMessage';
import { RightPartPlugin } from './RightPartPlugin';
import { b } from './b';
import './style.scss';

export type BorderColor = 'red' | 'yellow' | 'regular';
type OnClickData = { event: React.MouseEvent<HTMLDivElement> };

export type AsyncValidationData = {
  successMessage: I18n.EntryReference;
  pendingMessage: I18n.EntryReference;
};

export type Props = {
  Header?: React.FC;
  LeftPart?: React.FC;
  RightPartPlugin?: RightPartPlugin;
  RightPartWithinContour?: React.FC;
  formElementState: FormElementState<string>;
  errorRows?: 0 | 1 | 2;
  className?: string;
  widthByCharacters?: number;
  inputRef?: React.RefObject<HTMLInputElement>;
  borderColorUnit?: AbstractStateUnit<BorderColor>;
  isClearValueOnUnmount?: boolean;
  asyncValidationData?: AsyncValidationData;
  manualError?: I18n.EntryReference | null;
  decimalPlace?: number;
  isOnlyPositiveNumbers?: boolean;
  isOnlyNaturalNumbers?: boolean;
  isOnlyWholeNumbers?: boolean;
  onClick?({ event }: OnClickData): void;
  onChangePredicate?(value: string): string;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'placeholder' | 'title' | 'onBlur' | 'onKeyDown' | 'form'
>;

type Options = {
  natural?: boolean;
  positive?: boolean;
  whole?: boolean;
  decimalPlace?: number;
};

const isNumber = (value: string, options: Options) => {
  if (options.natural) {
    return predicates.naturalNumberPredicate(value);
  }
  if (options.whole) {
    return predicates.wholeNumberPredicate(value);
  }
  if (
    typeof options.decimalPlace === 'number' &&
    value.includes('.') &&
    value.split('.')[1].length > options.decimalPlace
  ) {
    return false;
  }
  if (options.positive) {
    return predicates.positiveNumberPredicate(value);
  }
  return predicates.numberPredicate(value);
};

function TextInput({
  Header,
  LeftPart,
  RightPartPlugin,
  RightPartWithinContour,
  formElementState,
  errorRows = 1,
  type,
  placeholder,
  title,
  widthByCharacters,
  className,
  onClick,
  onBlur,
  onKeyDown,
  onChangePredicate,
  inputRef,
  form,
  borderColorUnit,
  asyncValidationData,
  isClearValueOnUnmount = false,
  manualError = null,
  decimalPlace,
  isOnlyPositiveNumbers = false,
  isOnlyNaturalNumbers = false,
  isOnlyWholeNumbers = false,
}: Props) {
  const device = Device.unit.useState();

  const { units } = formElementState;
  const value = units.value.useState();

  const error = units.error.useState() || manualError;

  const isValid = units.isValid.useState();
  const disabled = units.disabled.useState();

  const [focused, setFocused] = useState<boolean>(false);

  const id = IDProvider.useID('text-input');

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentValue = e.target.value;
      const value = onChangePredicate?.(currentValue) ?? currentValue;

      switch (type) {
        case 'number': {
          const isNeedChange = isNumber(currentValue.replace(',', '.'), {
            positive: isOnlyPositiveNumbers,
            natural: isOnlyNaturalNumbers,
            whole: isOnlyWholeNumbers,
            decimalPlace,
          });
          if (value === '' || isNeedChange) {
            units.value.setState(value.replace(',', '.'));
          }
          return;
        }
        default: {
          units.value.setState(value);
        }
      }
    },
    [
      decimalPlace,
      isOnlyNaturalNumbers,
      isOnlyPositiveNumbers,
      isOnlyWholeNumbers,
      onChangePredicate,
      type,
      units.value,
    ],
  );

  const borderColor = borderColorUnit?.useState();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      onClick?.({ event });
    },
    [onClick],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      units.visited.setState(true);
      onBlur?.(e);
      setFocused(false);

      if (type === 'number') {
        units.value.setState(prevState => prevState.replace(/[-.,]$/, ''));
      }
    },
    [onBlur, type, units.value, units.visited],
  );

  const handleFocus = useCallback(() => setFocused(true), []);

  const handleInvalid: React.FormEventHandler<HTMLInputElement> = useCallback(
    event => {
      event.preventDefault();
    },
    [],
  );

  const midlineDirection = useMemo(() => {
    if (RightPartPlugin?.shouldBeSlidOnMobile && device === 'mobile') {
      return 'column';
    }
  }, [RightPartPlugin?.shouldBeSlidOnMobile, device]);

  const inputContourModifiersFromRightPartPlugin =
    RightPartPlugin?.useInputContourClassName?.(formElementState);

  useEffect(() => {
    return () => {
      isClearValueOnUnmount && units.value.setState('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorMessageAsyncData = useMemo(
    (): ErrorMessage.AsyncValidationData | undefined =>
      asyncValidationData && {
        ...asyncValidationData,
        pendingUnit: (formElementState.units as FormElementStateUnits<any>)
          .validationIsPending,
      },
    [asyncValidationData, formElementState.units],
  );

  return (
    <div
      className={b(
        {
          invalid: !isValid || Boolean(manualError),
          disabled,
          'border-color': borderColor,
          'with-width-by-characters': widthByCharacters !== undefined,
        },
        [className],
      )}
      style={{
        ['--width-by-characters' as any]:
          widthByCharacters && `${widthByCharacters}ch`,
      }}
      onClick={handleClick}
    >
      {Header && <Header />}
      <div className={b('midline', { direction: midlineDirection })}>
        {LeftPart && (
          <div className={b('left-part')}>
            <LeftPart />
          </div>
        )}
        <div
          className={b(
            'input-contour',
            {
              focused,
              'with-right-part': RightPartPlugin !== undefined,
            },
            [inputContourModifiersFromRightPartPlugin],
          )}
        >
          <input
            id={id}
            value={value}
            placeholder={placeholder}
            title={title}
            disabled={disabled}
            className={b('input')}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyDown={onKeyDown}
            onInvalid={handleInvalid}
            type={type === 'number' ? 'text' : type}
            form={form}
            ref={inputRef}
          />
          {RightPartWithinContour && (
            <div className={b('right-part-within-contour-container')}>
              <RightPartWithinContour />
            </div>
          )}
        </div>
        {RightPartPlugin && (
          <RightPartPlugin.Component formElementState={formElementState} />
        )}
      </div>
      {errorRows !== 0 && (
        <ErrorMessage.Component
          messageReference={error}
          rows={errorRows}
          asyncValidationData={errorMessageAsyncData}
        />
      )}
    </div>
  );
}

export const Component = React.memo(TextInput) as typeof TextInput;
