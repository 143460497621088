import * as M from 'types/serverModels';

const urlRS1 = 'https://data.cdn.globallab.org';
const urlRS2 = 'https://users.cdn.globallab.org';

export function getCommunityMapData(
  data: M.UserMapListData,
): M.CommunityMapData[] {
  const clusters = data.clusters.map<M.CommunityMapUsersClusterData>(x => ({
    title: x[0],
    subtitle: x[1],
    location: [x[2], x[3]],
    kind: 'users-cluster',
    teacherCount: x[4],
    studentCount: x[6],
    teachers: x[5],
    students: x[7],
  }));

  const users = data.users.map<M.CommunityMapUserData>(x => ({
    login: x[0],
    name: x[1],
    avatar:
      x[2].replace(/(RS1:)|(RS2:)/, match => {
        if (match === 'RS1:') {
          return urlRS1;
        }
        return urlRS2;
      }) || 'https://avatars.cdn.globallab.org/default.png',
    location: [x[3], x[4]],
    kind: 'user',
  }));

  return [...clusters, ...users];
}
