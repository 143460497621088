import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';

import * as Modal from 'components/Modal';
import {
  emulateClick,
  getFixedPositionStyle,
  getScrollableAncestors,
  stickFixedPositionElement,
} from 'utils/DOM';
import { block } from 'utils/classname';

import './style.scss';

const b = block('info-popup');

type Props = {
  Body: React.FC;
  icon?: '?' | 'i';
  Icon?: React.FC;
  type?: 'popup' | 'modal';
  header?: string;
  iconColor?: 'fg-3' | 'inherit';
  className?: string;
};

const popupMargin = 10;
const popupMinWidth = 150;

const isOpenUnit = Modal.makeIsOpenUnit(false);

function InfoPopup({
  Body,
  icon = '?',
  Icon,
  type = 'popup',
  header = '',
  iconColor = 'fg-3',
  className,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const [isPopupActive, setIsPopupActive] = useState<boolean>(false);

  const [popupPositionStyle, setPopupPositionStyle] =
    useState<React.CSSProperties>();

  const updatePopupPositionStyle = useCallback(() => {
    if (ref.current !== null) {
      setPopupPositionStyle(
        getFixedPositionStyle({
          anchorRect: ref.current.getBoundingClientRect(),
          margin: popupMargin,
          defaultMinWidth: popupMinWidth,
        }),
      );
    }
  }, []);

  const handlePointerEnter = () => {
    setIsPopupActive(true);
  };
  const handlePointerLeave = () => {
    if (document.activeElement !== ref.current) {
      setIsPopupActive(false);
    }
  };
  const handleFocus = () => {
    setIsPopupActive(true);
  };
  const handleBlur = () => {
    setIsPopupActive(false);
  };

  const handleClick = () => {
    isOpenUnit.setState(true);
  };

  const props = {
    popup: {
      onPointerEnter: handlePointerEnter,
      onPointerLeave: handlePointerLeave,
      onFocus: handleFocus,
      onBlur: handleBlur,
    },
    modal: {
      onClick: handleClick,
    },
  };

  const handleKeyDown: React.KeyboardEventHandler = event => {
    emulateClick(event);
  };

  const handleAncestorScroll = useCallback(() => {
    setIsPopupActive(false);
  }, []);

  useLayoutEffect(() => {
    if (!isPopupActive || ref.current === null) {
      return;
    }

    updatePopupPositionStyle();

    const scrollableAncestors = getScrollableAncestors(ref.current);

    scrollableAncestors.forEach(x => {
      x.addEventListener('scroll', handleAncestorScroll);
    });

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updatePopupPositionStyle,
    });

    return () => {
      scrollableAncestors.forEach(x => {
        x.removeEventListener('scroll', handleAncestorScroll);
      });

      unsubscribe();

      setPopupPositionStyle(undefined);
    };
  }, [isPopupActive, updatePopupPositionStyle, handleAncestorScroll]);

  useEffect(() => {
    if (!isPopupActive && ref.current === document.activeElement) {
      ref.current?.blur();
    }
  }, [isPopupActive]);

  return (
    <div
      className={b({}, [className])}
      ref={ref}
      tabIndex={0}
      {...props[type]}
      onKeyDown={handleKeyDown}
    >
      {Icon ? (
        <Icon />
      ) : (
        <div className={b('icon', { color: iconColor })}>{icon}</div>
      )}
      {type === 'popup' ? (
        isPopupActive &&
        createPortal(
          <div className={b('popup')} style={popupPositionStyle} ref={popupRef}>
            <Body />
          </div>,
          document.body,
        )
      ) : (
        <Modal.Component
          isOpenUnit={isOpenUnit}
          Header={Modal.Header.makeFromTitle(() => header)}
          size="s"
        >
          <Body />
        </Modal.Component>
      )}
    </div>
  );
}

export const Component = React.memo(InfoPopup);
