import * as R from 'ramda';

import { isStateUnit } from '../isStateUnit';
import { MappedState, MappedDeepState } from '../types';
import { MappedStateContainer } from './makeMappingUnit';

type Options<D extends boolean> = { deep?: D; debugName: string };

export function initializeInput<
  T,
  D extends boolean = false,
  S extends MappedDeepState<T> | MappedState<T> = D extends true
    ? MappedDeepState<T>
    : MappedState<T>,
>(
  input: T,
  onUpdate: () => void,
  { deep, debugName }: Options<D>,
): MappedStateContainer<T, S> {
  const container: MappedStateContainer<T, S> = {
    value: loop(input, []),
  };
  function loop<Y>(value: Y, path: Array<number | string>): S {
    if (isStateUnit(value)) {
      value.subscribe({
        name: debugName,
        callback: state => {
          if (!deep) {
            container.value = R.set(R.lensPath(path), state, container.value);
          } else {
            container.value = R.set(
              R.lensPath(path),
              loop(state, path),
              container.value,
            );
          }

          onUpdate();
        },
      });

      const state = value.getState();

      if (deep && typeof state === 'object') {
        if (Array.isArray(state)) {
          return state.map((x, index) => loop(x, [...path, index])) as S;
        }

        return R.mapObjIndexed((x, key) => loop(x, [...path, key]), state) as S;
      }

      return state;
    }

    if (
      value === null ||
      value === undefined ||
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      typeof value === 'bigint' ||
      typeof value === 'function' ||
      value instanceof Date
    ) {
      return value as unknown as S;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return value.map((x, index) => loop(x, [...path, index])) as S;
      }

      return R.mapObjIndexed((x, key) => loop(x, [...path, key]), value) as S;
    }

    return value as S;
  }

  return container;
}
