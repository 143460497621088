import { makeFeature } from 'services/Feature';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'WebinarSpeaker',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "webinar-speaker" */
      './Component'
    ),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
  prefetched: true,
});

export const Component = feature.Component;
