import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from './utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type QueryInputData = { user?: M.User['login'] };

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_book: SetRequired<
    M.ProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >;
  users?: M.ProjectBookUser[];
  projects?: M.ProjectBookProject[];
  comments_stat?: {
    uuid: M.UUID;
    count: number;
  }[];
};

type OutputData = {
  projectBook: SetRequired<
    M.ProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >;
  users: M.ProjectBookUsers;
  projects: M.ProjectBookProjects;
  commentStats: M.ProjectBookCommentStats;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBook: data.project_book,
    users:
      data?.users?.reduce<M.ProjectBookUsers>(
        (acc, x) =>
          x.login !== undefined ? { ...acc, [x.login]: x } : { ...acc },
        {},
      ) || {},
    projects:
      data?.projects?.reduce<M.ProjectBookProjects>(
        (acc, x) =>
          x.uuid !== undefined ? { ...acc, [x.uuid]: x } : { ...acc },
        {},
      ) || {},
    commentStats:
      data?.comments_stat?.reduce(
        (acc, x) => ({ ...acc, [x.uuid]: x.count }),
        {},
      ) || {},
  };
}

export const projectBookGet = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, uuid, user }, convertToSearchParams) =>
    `project_book/${uuid}/?${convertToSearchParams({ ticket, user })}`,
  'api-v2-get',
  false,
  extractResponse,
);
