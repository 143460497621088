import React from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import './style.scss';

const b = block('project-view-question-multi-choice');

type Props = {
  variant: M.Variant[];
  text?: Record<string, string>;
};

function View({ variant, text }: Props) {
  const t = I18n.useGetMultilingProjectTranslation();

  return (
    <ul className={b()}>
      {variant.map(x => {
        const title = x.title ? t(x.title) : '';

        return (
          <div className={b('item')} key={x.uuid}>
            {x.image?.icon && (
              <img src={x.image.icon} alt={title} className={b('icon')} />
            )}
            <div className={b('content')}>
              {title}
              {x.description && (
                <span className={b('description')}>{t(x.description)}</span>
              )}
              {text?.[x.uuid] && (
                <span className={b('text')}>{text[x.uuid]}</span>
              )}
            </div>
          </div>
        );
      })}
    </ul>
  );
}
export const Component = React.memo(View) as typeof View;
