import { makeEntryReference } from 'services/I18n';

import i18nData from './i18n.json';

export const i18nLinkSharedReferences = {
  passport: makeEntryReference(i18nData, data => data.links.passport),
  members: makeEntryReference(i18nData, data => data.links.members),
  stages: makeEntryReference(i18nData, data => data.links.stages),
  results: makeEntryReference(i18nData, data => data.links.results),
  reflexion: makeEntryReference(i18nData, data => data.links.reflexion),
  history: makeEntryReference(i18nData, data => data.links.history),
};
