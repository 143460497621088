import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'StaticTooltipTestimonial',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "static-tooltip-testimonial" */
      './Component'
    ),
  i18nData,
  subfeatures: [],
});

export * as Slide from './Slide';

export const Component = feature.Component;
