import { useEffect, useState } from 'react';

import { makeLogger } from 'utils/Logger';
import { useIsMountedRef } from 'utils/react';

import { PrimaryStateUnit } from '../PrimaryUnit/types';
import { AbstractStateUnit, StateSubscriber, UnitDebugData } from '../types';
import { DerivedStateUnit, DerivedUnitGetter, UnitsState } from './types';

export function makeDerivedUnit<T extends Array<unknown>>(
  ...stateUnits: T
): DerivedUnitGetter<T> {
  return {
    getUnit<R>(
      deriver: (...unitsState: UnitsState<T>) => R,
      debugData?: UnitDebugData,
    ): DerivedStateUnit<R> {
      const { name, debugMode = true } = debugData || {
        name: 'not-specified',
        debugMode: false,
      };

      const { log } = makeLogger(name, debugMode);

      const initialDerivableStates: unknown[] = (
        stateUnits as Array<AbstractStateUnit<unknown>>
      ).map(x => x.initialState);

      let lastKnownDerivableStates = initialDerivableStates.slice();

      const initialState = deriver(...(initialDerivableStates as any));

      let derivedState: R = initialState;

      log('initial derivable states', initialDerivableStates);
      log('initial state', initialState);

      let subscribers: Array<StateSubscriber<R>> = [];
      let unsubscribers: Array<() => void> = [];

      const updateDerivedStateIfRequired = () => {
        const currentDerivableStates: unknown[] = (
          stateUnits as Array<AbstractStateUnit<unknown>>
        ).map(x => x.getState());

        if (
          !currentDerivableStates.every(
            (x, index) => x === lastKnownDerivableStates[index],
          )
        ) {
          derivedState = deriver(...(currentDerivableStates as any));
          log(
            'updating derivedState with',
            derivedState,
            currentDerivableStates,
            lastKnownDerivableStates,
          );
          lastKnownDerivableStates = currentDerivableStates;
        }
      };

      const onUnitSubscribe = () => {
        if (subscribers.length === 0) {
          log('subscrbing to dependency units');
          updateDerivedStateIfRequired();

          unsubscribers = (stateUnits as Array<PrimaryStateUnit<unknown>>).map(
            (unit, index) => {
              return unit.subscribe({
                name,
                callback: state => {
                  log('new dependency state', index, state);
                  lastKnownDerivableStates[index] = state;
                  derivedState = deriver(...(lastKnownDerivableStates as any));
                  log('-> new derived state', derivedState);
                  subscribers.forEach(subscriber =>
                    // TODO implement prev state
                    subscriber.callback(
                      derivedState,
                      Symbol('not-implemented') as any,
                    ),
                  );
                },
              });
            },
          );
        }
      };

      const onUnitUnsubscribe = () => {
        if (subscribers.length === 0) {
          log('unsubscribing from dependency units');
          unsubscribers.forEach(f => f());
          unsubscribers = [];
        }
      };

      const subscribe = (subscriber: StateSubscriber<R>) => {
        log('subscribe', subscriber);
        onUnitSubscribe();
        subscribers.push(subscriber);

        return () => {
          subscribers = subscribers.filter(x => x !== subscriber);
          onUnitUnsubscribe();
        };
      };

      const subscribeInUseState = (
        subscriber: StateSubscriber<R>,
        initializedState: R,
      ) => {
        log('subscribe', subscriber);

        onUnitSubscribe();

        if (initializedState !== derivedState) {
          // TODO implement prev state
          log('forcing state update to', derivedState);
          subscriber.callback(derivedState!, Symbol('not-implemented') as any);
        }

        subscribers.push(subscriber);

        return () => {
          subscribers = subscribers.filter(x => x !== subscriber);
          onUnitUnsubscribe();
        };
      };

      return {
        kind: 'derived',
        subscribe,
        getState: () => {
          updateDerivedStateIfRequired();
          return derivedState!;
        },
        initialState,
        isStateUnit: true,
        useState: (debugName: string = 'not-specified') => {
          const [state, setState] = useState<R>(() => {
            updateDerivedStateIfRequired();
            return derivedState;
          });

          const isMountedRef = useIsMountedRef();

          useEffect(() => {
            return subscribeInUseState(
              {
                callback: value => {
                  if (isMountedRef.current) {
                    setState(value);
                  }
                },
                name: debugName,
              },
              state,
            );
          }, [debugName, subscribeInUseState]);

          // NOTE we return derivedState instead of state to prevent
          // cached state returning on unit switching for the same place
          // of the hook call
          return derivedState;
        },
      };
    },
  };
}
