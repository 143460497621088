import { useMemo } from 'react';

import { makeFeature } from 'services/Feature';
import * as TS from 'types';
import { makeFormElementState } from 'utils/FormState';

export const feature = makeFeature({
  name: 'TextEditor',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "text-editor" */
      './Component'
    ),
  subfeatures: [],
});

export function makeFieldState(
  initialState: string = '',
  validators?: TS.Validator[],
) {
  return makeFormElementState<string>(initialState, validators);
}

export function useFieldState(initialState: string = '') {
  return useMemo(() => makeFieldState(initialState), [initialState]);
}

export const Component = feature.Component;
